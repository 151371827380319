import React, {useContext, useEffect, useState} from "react";
import dayjs from "dayjs";
import duration from 'dayjs/plugin/duration'
import IngredientePill from "./IngredientePill";


import { ComandaContext } from './ComandaContext';


dayjs.extend(duration)

const VentaDetalle = ({ venta, openCarrilModal }) => {

    const [segundosPasados, setSegundosPasados] = useState(0)
    const { selectedVenta, changeCursorById } = useContext(ComandaContext);

    
    useEffect(() => {
        const intervalo = setInterval(() => {
          const segundosPasados = Math.floor(
            (new Date() - new Date(venta.createdAt)) / 1000
          );
          setSegundosPasados(segundosPasados);
        }, 1000);
        return () => clearInterval(intervalo);
      }, [venta.createdAt]);
    

      function convertirSegundosATiempo(segundos) {
        const minutos = Math.floor(segundos / 60);
        const segundosRestantes = segundos % 60;
        const tiempo = dayjs()
          .set("minute", minutos)
          .set("second", segundosRestantes)
          .format("mm:ss");
        return tiempo;
      }

    
    const nivelesSemaforo=[
      'bg-white text-black',
      'bg-green-300 text-black',
      'bg-yellow-100 text-black',
      'bg-red-400 text-black',
    ]

    let colorSemaforo = nivelesSemaforo[0]

    if(segundosPasados>30)colorSemaforo = nivelesSemaforo[1];  //30
    if(segundosPasados>180)colorSemaforo = nivelesSemaforo[2];  // 3
    if(segundosPasados>300)colorSemaforo = nivelesSemaforo[3]; // 5


    let borderClass
    if(selectedVenta === venta.id)borderClass=`bg-slate-500 p-2`
    else borderClass=`bg-slate-200`


    console.log("comanda venta", venta)


  return (
    <div  onClick={ ()=>{
      changeCursorById(venta.id)
      openCarrilModal();
      } } className={`  flex rounded-lg  ${borderClass} overflow-hidden`}   >
      <div
      
        className={` `}
      >
        <div className={`flex flex-col p-1 ${colorSemaforo} h-full justify-between`} style={{minWidth:'75px'}}>
          <div className="flex flex-col">
            <p className="text-xs ">ORDEN</p>
            <p className="text-2xl ">{venta.id} </p>
          </div>
          <div className=" p-1 justify-end">
               { convertirSegundosATiempo(segundosPasados) }
          </div>
          {/* <p>{segundosPasados}</p> */}
        </div>
      </div>

      <div className="flex flex-col w-full gap-1">
        {venta.ventaDetalles
          .filter((f) => f.producto.productoDetalle.length > 0)
          .map((vd, index) => (
            <div className="flex gap-3" key={index}>
              <div className="flex flex-1 bg-blue-200">
                {vd.producto.productoDetalle.map((item, index) => (
                  <div className="flex bg-neutral-50 flex-1 p-1" key={index}>
                    <div className="flex-1">
                      <div className=" font-bold flex">
                        <div style={{minWidth:'2em'}} > {vd.cantidad} </div> 
                        <div className="uppercase">{item.receta.nombre}</div>                       
                      </div>
                      <div className="space-x-1">


                    {/* <pre>
                      {JSON.stringify( item, null, 2 )}
                    </pre> */}

                        {item.receta.recetaDetalle.map((item2, index) => (

                          <IngredientePill
                            key={index}
                            titulo={`${item2.sku.shortName  || item2.sku.nombre }`}
                            avoid={ vd?.avoidIngredients?.items.includes(`${item.receta.id}-${item2.sku.sku}`) }
                          />
        

                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default VentaDetalle;
