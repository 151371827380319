import React, {useState, useEffect} from 'react'
import NumPad from 'react-numpad';




import { gql, useMutation, useQuery, useLazyQuery} from "@apollo/client";

import { useCajaContext } from "../../providers/CajaProvider";
import TecladoMonto from "../TecladoMonto";
// import { useToast } from "react-toastify";

// import { toast } from 'react-toastify';


const RUC_QUERY = gql`
 query($cedula: String!){
    rucByCedula(cedula: $cedula){
        ruc
        dv
        razon_social
    }
 }
`;



const RucButtonMode = ({children, selected, clickHandler})=>{


    return <button
    type="button" 
    onClick={ clickHandler }  
    className={` ${selected ?'border-green-500 bg-green-200 border-2':'border-neutral-500 bg-neutral-200'}    p-6 w-full rounded-2xl   border-2`}  >
        {children}
    </button>
}



export default function Ruc( {isOpen,onRequestClose, style }) {


    // const toast = useToast()


    const [rucMethodSelected, setRucMethodSelected] = useState("anonymous")

    const [ruc, setRuc] = useState("")
    const { setCliente, setNombreLlamador } = useCajaContext();

    const [input, setInput] = useState('');

    const [ searchRucByCedula,  { loading, error, data }, ] = useLazyQuery(RUC_QUERY );
    const [rucByCedula, setRucByCedula] = useState(null)
    
    // const   rucByCedula    = data?.rucByCedula;


    const handleSubmit = (event)=>{

        try{
            searchRucByCedula({variables:{ cedula: ruc }}).then( res=>{
                console.log(res);
            });
    
        }catch(ex){
            console.log("error on search ruc by cedula", ex)
        }

        event.preventDefault();
      }

      const confirmRuc = ()=>{

        console.log("confirm ruc", rucByCedula)
        // setNombreLlamador( rucByCedula.razon_social )
        setCliente(rucByCedula)
        onRequestClose();
        
      }

      useEffect(() => {
        if(rucMethodSelected === "anonymous"){
            setCliente(null)
        }else{
            setCliente(rucByCedula)
        }

      }, [rucMethodSelected, setCliente, rucByCedula])
      





      useEffect(() => {
        console.log("ruc effect", ruc)
        if(ruc){

            try{
                searchRucByCedula({variables:{ cedula: ruc }}).then( res=>{
                    const {data} = res;
                    const {rucByCedula} = data;
                    if(rucByCedula){
                        setRucByCedula(rucByCedula)
                        setRucMethodSelected("nominated")
                    }else{
                        // toast({
                        //     position:'top-right',
                        //     title: 'Error',
                        //     description: "No se encontró RUC para la cédula ingresada",
                        //     status: 'error',
                        //     duration: 2000,
                        //     isClosable: true,
                        // })
                    }
                    console.log(res);
                });
        
            }catch(ex){
                console.log("error on search ruc by cedula", ex)
                // toast({
                //     position:'top-right',
                //     title: 'Error',
                //     description: ex.toString(),
                //     status: 'error',
                //     duration: 2000,
                //     isClosable: true,
                //   }
                // )
            }


        }
      }, [ruc, searchRucByCedula,setRucByCedula])
      


  return ( <div className="w-full h-full flex gap-2 ">


           <div className=" flex flex-col justify-between p-3 gap-5 w-2/5 ">
                    <p className="text-center text-2xl">Ingrese Nº de cédula:</p>
                     <TecladoMonto  defaultAmount={ ""   }  onChangeHandler={ ()=>{} }  onConfirm={ (value)=>setRuc(value) } />   
           </div>


            <div className="relative    w-3/5 flex items-center gap-5 flex-col  justify-center p-5">
                    <div className="flex-1  w-full flex">
                    {
                            rucByCedula && 
                            <RucButtonMode selected={rucMethodSelected === "nominated"} clickHandler={ ()=>setRucMethodSelected("nominated") } >
                                <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">{rucByCedula.razon_social}</h5>
                                <p className="mb-3 text-3xl font-normal text-gray-700 dark:text-gray-400">{`${rucByCedula.ruc}-${rucByCedula.dv}`}</p>
                        </RucButtonMode>
                            
                           
                         }

{/* <button
                        type="button" 
                        onClick={ confirmRuc }  
                        className="p-6 w-full  rounded-2xl bg-cyan-300 border-cyan-500 border-2">
                            <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">{rucByCedula.razon_social}</h5>
                            <p className="mb-3 text-3xl font-normal text-gray-700 dark:text-gray-400">{`${rucByCedula.ruc}-${rucByCedula.dv}`}</p>
                            <p>(presione para continuar)</p>
                       
                        </button> */}

                    </div>

                    <div className="flex-1  w-full flex">

                        <RucButtonMode selected={rucMethodSelected === "anonymous"} clickHandler={ ()=>setRucMethodSelected("anonymous") } >
                            <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white"> CONTINUAR SIN RUC</h5>
                        </RucButtonMode>

                        {/* <button
                        type="button" 
                        onClick={ confirmRuc }  
                        className={` ${rucMethodSelected ==="anonymous"?'border-green-500 bg-green-200 border-2':'border-neutral-500 bg-neutral-200'}    p-6 w-full rounded-2xl   border-2`}  >
                            <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white"> CONTINUAR SIN RUC</h5>
                        </button> */}
                    </div>
            </div>

            </div>



  )
}
