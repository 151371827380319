import React, {useEffect, useState} from 'react'

import {useCajaContext} from "../providers/CajaProvider"
import { useAuthContext  } from "../providers/AuthProvider"
import { Link } from "react-router-dom";

import { IconButton } from "@chakra-ui/react"
import { IoArrowBack, IoHomeOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

import dayjs from 'dayjs'

export default function CajaHeader({ onClickCliente, onClickTipoFactura}) {

    const navigate = useNavigate();
    const { employee  } = useAuthContext();
    const { cliente, tipoFactura, caja, consumoInterno, setConsumoInterno, clearCaja } = useCajaContext();
    const [fechero, setFechero] = useState( dayjs().format("DD/MM/YYYY HH:mm")  )

    useEffect(() => {
      const interval = setInterval(() => {
        setFechero(dayjs().format("DD/MM/YYYY HH:mm")  )
      },10000);

      return () => {
        clearInterval(interval)
      }

    }, [])
    

    const cancelarConsumoInterno = ()=>{
          clearCaja()
          setConsumoInterno(null)
    }



  return (
    <div className="flex  justify-between  h-full "> 

        <div className="flex gap-5 justify-start items-center  ">

            <div className="font-extralight text-2xl">
               {fechero}
            </div>

            <div className="border p-1 px-4 flex flex-col  bg-slate-100">
              <p className="text-xs uppercase"> Cajero actual:</p>
              <p className="font-extralight text-lg "> {employee?.firstName} {employee?.lastName}</p>
            </div>


            { consumoInterno &&  <button 
              onClick={ cancelarConsumoInterno  }
            className=" border p-1 px-4 flex flex-col  bg-slate-100 rounded-lg">
              <p className="text-xs uppercase"> Consumo interno para:</p>
              <p className="font-extralight text-lg "> {consumoInterno?.firstName} {consumoInterno?.lastName}</p>
            </button>}

            {/* <button 
            type="button"
            onClick={onClickCliente}
            className="flex flex-col text-left justify-center place-content-center bg-slate-100 p-2 flex-1 ">

                { cliente  ?<div>
                    <div className="">{`${cliente.razon_social}`}</div>
                    <div className="text-xl uppercase">{`${cliente.ruc}-${cliente.dv}`}</div>
                </div>:
                <div>SIN RUC</div>    }

              
            </button>  */}
        </div> 


        <div className="flex gap-3">





        <div className="flex flex-col justify-center place-content-center bg-slate-100 p-3">
            <div className="text-xs uppercase">#</div>
            <div className="text-xl uppercase font-bold text-center">  {  caja?.caja?.id  }  </div>    
        </div>         

        <div className="flex flex-col justify-center place-content-center bg-slate-100 p-3">
            <div className="text-xs uppercase">caja</div>
            <div className="text-xl uppercase font-bold text-center">3</div>    
        </div> 
      
          <div  className="flex justify-center items-center gap-5">

          <Link to="/home">
              <img src="/svg/fastHorizontal.svg" alt=""    className="h-16"  />
        </Link>

        <IconButton
                            aria-label="Cerrar sesión"
                            icon={<IoArrowBack />}
                            size="lg"
                            colorScheme="green" // Aquí se establece el color rojo
                            onClick={ ()=> navigate("/home")   }
                            />
            
                 

                    {/* <div className="relative bg-blue-800">
                        <div className="absolute top-0 left-0 right-0 bottom-0 ">
                        <img src="/svg/fastHorizontal.svg" alt="" width="100%" height="100%"  />
                        </div>
                    </div> */}
          </div>

        </div>

       
    </div>
  )
}
