import React,{useEffect, useRef, useState} from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    CircularProgress,
    Stack,
    Box,
    Stat,
    StatLabel,
    StatNumber,
    StatGroup
  } from '@chakra-ui/react'
  import numeral from 'numeral';

  import { NumericFormat } from 'react-number-format';
  import TecladoMonto from "../TecladoMonto"




export default function InputCantidadModal({ isOpen, defaultAmount, valor, onClose, message, onConfirm, img }) {

    const [preValue, setPreValue] = useState(defaultAmount)

    useEffect(() => {
      setPreValue(defaultAmount || 0)
    
      // return () => {
      //   setPreValue(defaultAmount || 0)
      // }
    }, [isOpen, defaultAmount])
    

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'4xl'}    >
          <ModalOverlay />
          <ModalContent    >
            <ModalHeader>Ingreso de cantidad</ModalHeader>
            <ModalCloseButton />



            <ModalBody  >
             <div className="flex gap-5 w-full "  >
                  <div className="bg-slate-200 flex-1 flex flex-col justify-between p-3 gap-5">
                    <p className="text-center text-2xl">{message}</p>
                     <TecladoMonto  defaultAmount={ defaultAmount   }  onChangeHandler={setPreValue}  onConfirm={ onConfirm } />   
                 </div>

                 <div className="flex justify-center items-center">
                    <div className=" w-[220px]">
                        <img src={img}  width={'100%'}  />
                        <p className="text-center text-xl font-medium py-3 ">{ numeral(valor*preValue).format('0,0')  }</p>
                    </div>
                 </div>
             </div>
            </ModalBody>
  

            {/* <ModalFooter>
              <Button variant='ghost'>Cancelar</Button>
              <Button colorScheme='blue' mr={3} onClick={onClose}>
                Aceptar
              </Button>
            </ModalFooter> */}
          </ModalContent>
        </Modal>
    )
  }
