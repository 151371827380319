import React,{useEffect, useRef, useState} from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    CircularProgress,
    Progress,
    Stack,
    Box,
    Stat,
    StatLabel,
    StatNumber,
    StatGroup,
    useToast
  } from '@chakra-ui/react'
import { NumericFormat } from 'react-number-format';
import numeral from 'numeral';
import { gql, useMutation, useQuery, useLazyQuery} from "@apollo/client";


import TecladoNumerico from "../TecladoNumerico";




const EMPLOYEES_QUERY = gql`
 query{
    employees{
        id
        cedula
        firstName
        lastName
        avatar
    }
 }
`;


const CHECK_EMPLOYEE_MUTATION = gql`
    mutation($username:String, $password:String) {
        checkAuthEmployee( username:$username, password:$password )
    }
`





export default function CheckAuthModal({ isOpen,   onClose, message, handleAuth, initialUsername, initialStep, bgColor}) {


    const toast = useToast()
    const  { loading, error, data }  = useQuery(EMPLOYEES_QUERY );

    const [ checkEmployeeMutation, {loading:loadingLogin, error:errorLoading} ]  = useMutation(CHECK_EMPLOYEE_MUTATION)

    const [username, setUsername] = useState( initialUsername )
    const [step, setStep] = useState( initialStep)


    // const handleLogin = (employee)=>{
    //     fastLogin(employee)
    //     navigate("/home")
    // }

    useEffect(() => {
      setStep(initialStep)
    }, [initialStep])
    
    useEffect(() => {
        setUsername(initialUsername)
      }, [initialUsername])


    const handleLogin = (password)=>{
        console.log("attemp login", username, password)
        //        fastLogin(employee)
        // navigate("/home")

        // setStep(2)

        checkEmployeeMutation({variables:{username, password}}).then((response)=>{
            console.log("login employee data", response)
            const {employee, status, message} = response.data.checkAuthEmployee
            if(status===200)handleAuth(employee)
            else{
                setStep(initialStep)
                toast({
                    position:'top-right',
                    title: 'Error',
                    description: message,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                  })
            }

        }).catch((error)=>{
            console.log("error on login", error)
            setStep(initialStep)
            toast({
                position:'top-right',
                title: 'Error',
                description: error.toString(),
                status: 'error',
                duration: 3000,
                isClosable: true,
              })

        })

    }

    const handleUsername = (username)=>{
        setUsername(username)
        setStep(1)
    }



    if(loading)return <p>loading...</p>;
    if(error)return  <pre>{JSON.stringify(error, null, 2)}</pre>;

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'4xl'}    >
          <ModalOverlay />
          <ModalContent  maxH="800px"  >
            <ModalHeader>Autenticación</ModalHeader>
            <ModalCloseButton />



            <ModalBody  >
                <div className={`flex  flex-col justify-center items-center p-3 flex-1 ${bgColor?bgColor:'bg-cyan-300'}  gap-10  font-extralight`}>
                    <div className=" flex flex-col gap-5">
                    <p className="text-center text-4xl">{message}</p> 

                            {  step===0 && <TecladoNumerico message={"Ingrese su identificador:"} onConfirm={handleUsername} />  }
                            {   step===1 && <TecladoNumerico message={"Ingrese su password:"} onConfirm={handleLogin} mask={true} />  }
                            {   loadingLogin && <Progress size="xs" isIndeterminate />  }
                    </div>
                </div>
            </ModalBody>
  

            {/* <ModalFooter>
              <Button variant='ghost'>Cancelar</Button>
              <Button colorScheme='blue' mr={3} onClick={onClose}>
                Aceptar
              </Button>
            </ModalFooter> */}
          </ModalContent>
        </Modal>
    )
  }
