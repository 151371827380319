import React from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    CircularProgress
  } from '@chakra-ui/react'
import TecladoMonto from "../TecladoMonto"




export default function AperturaModal({ isOpen, onOpen, onClose, handleAbrirCaja }) {

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Apertura de caja</ModalHeader>
            <ModalCloseButton />



            <ModalBody>
              <div className="flex justify-center items-center" style={{height:'500px'}}>
                <TecladoMonto defaultAmount={500000} message={"Ingrese monto de fondo caja"}  onConfirm={ (v)=>handleAbrirCaja(v) } />
              </div>
            </ModalBody>

            {/* <ModalBody>
              <div className="flex justify-center items-center" style={{height:'500px'}}>
              {
                !loading ?
                <TecladoMonto defaultAmount={500000} message={"Ingrese monto de fondo caja"}  onConfirm={ (v)=>handleAbrirCaja(v) } />
                :
                <CircularProgress isIndeterminate color='green.300' />
                }
              </div>
            </ModalBody> */}
  

            {/* <ModalFooter>
              <Button variant='ghost'>Cancelar</Button>
              <Button colorScheme='blue' mr={3} onClick={onClose}>
                Aceptar
              </Button>
            </ModalFooter> */}
          </ModalContent>
        </Modal>
    )
  }
