import React, { useEffect } from 'react'
import { useAuthContext } from "../providers/AuthProvider";

import { ChakraProvider } from '@chakra-ui/react'

import Home from '../pages/home'
import Caja from '../pages/caja'
import Comanda from '../pages/comanda'
import Llamador from '../pages/llamador'
import Zona from '../pages/zona'
import Proyeccion from '../pages/proyeccion'
import Reloj from '../pages/reloj'
import Login from '../pages/login'
import Landing from '../pages/landing'
import FacturaDetalle from '../pages/facturaDetalle'
import CajaDetalle from '../pages/cajaDetalle'
import Launcher from "../pages/launcher";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate
  } from 'react-router-dom'


  import CajaProvider from "../providers/CajaProvider"
import { useWebSocketContext } from "../providers/WebSocketProvider";


export default function AppMain() {

    const { authenticated } = useAuthContext();
    const { socket } = useWebSocketContext();

    console.log("socket on AppMain", socket)

    const notify = () => toast("SAT ECHO",
      { position: 'top-right',
        
         autoClose: 1000 });


    useEffect(() => {

      console.log("useEffect socket on AppMain")
      if(socket)
        {   
            socket.on("echoDevice", (data)=>{
              console.log("sio echoDevice", data)

              if( data.identificador === localStorage.getItem('deviceId') ){
                  console.log("BINGO!") 
                  notify();

                  
                }

          })}

          return () => {
            console.log("socket off")
            socket.off("echoDevice")
          }

    }, [socket])
    



  return (
<div className="flex w-full h-full justify-center">

<div className="absolute top-0 right-0 p-1 text-xs bg-slate-700 text-slate-200">
  {  localStorage.getItem('hostname') }
</div>

<div className="absolute left-0 bottom-0 p-1 text-xs bg-slate-700 text-slate-200">
{process.env.REACT_APP_COMMIT_ID} - {  localStorage.getItem('deviceId') }
</div>

{/* <Login /> */}
{/* <Caja /> */}
{/* <Comanda /> */}

<ChakraProvider>
  <CajaProvider>
      <Router>
        <Routes>
        <Route path="llamador" element={<Llamador />} />

          <Route path="reloj" element={<Reloj />} />
          <Route path="proyeccion" element={<Proyeccion />} />

          {  !authenticated ? <>
              <Route path="/*"
                element={<Navigate to="/login" />}
              />   


              <Route path="login" element={<Login />} />
          </>
          
          :
          <>
          <Route path="/" element={<Home />} />
          <Route path="home" element={<Home />} />

          <Route path="comanda" element={<Comanda />} />
          <Route path="zona" element={<Zona />} />

          <Route path="login" element={<Login />} />
          <Route path="launcher" element={<Launcher />} />
          <Route path="caja" element={<Caja />} />
          <Route path="facturaDetalle/:id" element={<FacturaDetalle />} />
          <Route path="cajaDetalle/:id" element={<CajaDetalle />} />
          <Route path="landing" element={<Landing />} />
          </>
        } 
          </Routes>
      </Router>
    </CajaProvider>
</ChakraProvider>

</div>
  )
}
