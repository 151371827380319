import React, {useState} from 'react'
import { NumericFormat } from 'react-number-format';
import { nanoid } from 'nanoid'
import {useCajaContext} from "../providers/CajaProvider"
import ProductoModalidadModal from "./modales/ProductoModalidadModal";


export default function Producto(props) {
    const {titulo, precio, img, item, onClick} = props;




    const { setProductVariator, setAvoidIngredients, setVariatorValues } = useCajaContext();



    const handleClick = ()=>{

        setProductVariator(null)
        setAvoidIngredients([])
        setVariatorValues([])

        const producto = {                  
            id: nanoid(),
            cantidad:1,
            descripcion:titulo,
            preferencias:"",
            precio:precio
        }




        onClick(producto)

    }






  return (
 
    <button type="button"
    onClick={ handleClick }
    className="border rounded-md p-1 flex justify-between place-items-center flex-col bg-slate-100 w-full " 
    >

        {/* <div className="box-content h-12 w-12 bg-white ">
                <img src={img} alt="" width="100%" height="100%" />
        </div> */}

        <div className=" h-full w-full flex flex-col  justify-between  gap-1  "   >
            <div className="   uppercase  "  style={{height:'3em'}}  >{titulo}</div>
         
            <div
            className="bg-center bg-contain bg-no-repeat flex-1" 
            style={{backgroundImage:`url('${img}')`  }}>            
            </div>

            <p className="text-xl  uppercase">
            <NumericFormat displayType="text" value={  precio  } thousandSeparator="," />  
            </p>
        </div>
    </button>

    
 
)
}
