import React from 'react'
import BotoneraPago from "./BotoneraPago"
import CajaBigTotal from "./CajaBigTotal"

export default function CajaPago({onTicket}) {
  return (
    <div className="flex flex-col h-full gap-3">
        <CajaBigTotal />

        <BotoneraPago onTicket={onTicket}   />

    </div>
  )
}
