import React,{useEffect, useRef, useState} from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    CircularProgress,
    Stack,
    Box,
    Stat,
    StatLabel,
    StatNumber,
    StatGroup
  } from '@chakra-ui/react'
  import { NumericFormat } from 'react-number-format';
  import numeral from 'numeral';

import InputCantidadModal from "./InputCantidadModal";
import { set } from "date-fns";



const ItemRepresentation = ({item, handler})=><button type='button'
  onClick={()=>handler(item)}
className={ `${item.cantidad>0?'bg-green-400':'bg-slate-400'} rounded-md px-4 py-3 flex gap-1 flex-col items-center` }>
  <img src={item.img}  style={{maxHeight:'64px'}}  /> 
    <p className="font-bold text-xl text-center">  <NumericFormat displayType="text" value= {  item.valor } thousandSeparator="," /> </p>
    <p>Cant: <NumericFormat displayType="text" value= {  item.cantidad } thousandSeparator="," /></p>
</button>




export default function ArqueoModal({ isOpen,   onClose, handleArqueo, initialItems, readOnly, title }) {

    const [modalInputCantidad, setModalInputCantidad] = useState({
      open:false
    })
    const [selectedItem, setSelectedItem] = useState(0)

    const [items, setItems] = useState([
        {valor: 100000, cantidad: 0, tipo:'billetes', img:"/images/billetes_monedas/billetes100000.jpg"},
        {valor: 50000, cantidad: 0, tipo:'billetes', img:"/images/billetes_monedas/billetes50000.jpg"},
        {valor: 20000, cantidad: 0, tipo:'billetes', img:"/images/billetes_monedas/billetes20000.jpg"},
        {valor: 10000, cantidad: 0, tipo:'billetes', img:"/images/billetes_monedas/billetes10000.jpg"},
        {valor: 5000, cantidad: 0, tipo:'billetes', img:"/images/billetes_monedas/billetes5000.jpg"},
        {valor: 2000, cantidad: 0, tipo:'billetes', img:"/images/billetes_monedas/billetes2000.jpg"},

        {valor: 1000, cantidad: 0, tipo:'monedas', img:"/images/billetes_monedas/moneda1000.png"},
        {valor: 500, cantidad: 0, tipo:'monedas', img:"/images/billetes_monedas/moneda500.png"},
        {valor: 100, cantidad: 0, tipo:'monedas', img:"/images/billetes_monedas/moneda100.png"},
        {valor: 50, cantidad: 0, tipo:'monedas', img:"/images/billetes_monedas/moneda50.png"},
    ])



    useEffect(() => {
      if(initialItems)setItems(initialItems)
    }, [initialItems])
    


    useEffect(() => {
      if(initialItems)setItems(initialItems)
      else{
        setItems( p=>p.map( i=>({...i, cantidad:0}) ) ) 
      }
 
    }, [isOpen,setItems,initialItems])
    





    // tickets: 21,
    // total: 845000,
    // totalEfectivo: 506000,
    // totalOtrosMedios: 339000,
    // contadorAgrandado: 15,
    // contadorNoAgrandado: 2



    const confirmInput = (v)=>{
      if(!readOnly){
        console.log("confirm input ", v)
        const valor = parseInt(v) || 0
        setItems( items.map( (item,i)=> i===selectedItem ? {...item, cantidad:valor} : item ) )
        setModalInputCantidad({open:false})
      }
    }

    const handleItemClick = (item)=>{
      if(!readOnly){
        console.log("handleItemClick ", JSON.stringify(item))
        setSelectedItem(  items.findIndex( i=> i.valor===item.valor && i.tipo===item.tipo ) )
        setModalInputCantidad({open:true})  
      }
    }



    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'4xl'}  closeOnEsc={false} closeOnOverlayClick={false}  >
          <ModalOverlay />
          <ModalContent  maxH="800px"  >
            <ModalHeader>{title || "Arqueo de caja"}</ModalHeader>
            <ModalCloseButton />



            <ModalBody  >

          {

             !readOnly && <InputCantidadModal   isOpen={modalInputCantidad.open} onClose={()=>setModalInputCantidad({open:false})} 
              defaultAmount = { items[selectedItem].cantidad || '' }
              img  = { items[selectedItem].img }
              valor = {items[selectedItem].valor}
                 message={`Ingrese cantidad de ${items[selectedItem].tipo} de ${ numeral(items[selectedItem].valor).format('0,0') }`}  onConfirm={confirmInput}  />

          }


             <div className="flex gap-5 w-full my-4"  >
                  <div className=" flex-1 flex flex-col justify-between">

                    
                      <Stack spacing={3}>
                       <div className="flex flex-col gap-1">
                        <p className="text-sm uppercase">Billetes:</p>
                          <div className="grid grid-cols-6 gap-3">
                                  {
                                      items.filter(i=>i.tipo==='billetes').map( (billete)=><ItemRepresentation handler={handleItemClick} key={billete.valor} item={billete}  /> )
                                  }
                          </div>
                       </div>
                    
                       <div className="flex flex-col gap-1">
                         <p className="text-sm uppercase">Monedas:</p>
                         <div className="grid grid-cols-4 gap-3">   
                                {
                                    items.filter(i=>i.tipo==='monedas').map( (billete,i)=><ItemRepresentation  handler={handleItemClick}  key={billete.valor} item={billete}  /> )
                                }
                         </div>
                         </div>
                    


                        <div className="bg-green-300 flex flex-col gap-1 p-3 rounded-lg items-center">
                            <p className="text-sm uppercase">Total</p>
                            <p className="font-bold text-4xl text-center">  <NumericFormat displayType="text" value= {  items.reduce( (a,b)=>a+b.valor*b.cantidad , 0 ) } thousandSeparator="," /> </p>
                        </div>

                    
                          {/* <Button py={8} colorScheme="blue" >Abrir gaveta</Button> */}
                      </Stack>
                 </div>
             </div>
            </ModalBody>
  

            { !readOnly && <ModalFooter>
                <Stack direction="row" spacing={4}>
                <Button variant='ghost'onClick={onClose}>Cancelar</Button>
                <Button colorScheme='blue' onClick={()=>handleArqueo(items)}   >
                  Aceptar
                </Button>
                </Stack>
            </ModalFooter> }
          </ModalContent>
        </Modal>
    )
  }
