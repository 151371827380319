import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faCog, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../providers/AuthProvider";
import { IoHome } from "react-icons/io5";

const Botones = (props) => {

  const {  fastLogout  } = useAuthContext();

  const navigate = useNavigate();


    const {openProyeccionModal} = props;

  return (
    <div className="p-1 w-full  flex  justify-end items-center h-full ">









       
        <div className="flex flex-row gap-5 items-center justify-center ">
        <button className="w-12 h-12 rounded-full bg-green-500  flex justify-center items-center     "   onClick={()=>navigate('/launcher') }  >
             <IoHome icon={faTimes} size="28" />
            </button>

            <button className="w-12 h-12 rounded-full bg-red-500    "   onClick={()=>fastLogout() }  >
             <FontAwesomeIcon icon={faTimes} size="2x" />
            </button>
            {/* <p className="text-sm font-medium">Salir</p> */}
         </div>






    </div>
  );
};

export default Botones;
