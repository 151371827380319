import React from 'react'

import { cloneDeep } from "lodash"


import { useCajaContext } from "../providers/CajaProvider";


export default function BotoneraProductVariator({data, producto}) {

    const { setProductVariator, productVariator } = useCajaContext();
    const {name, type, target, enabled, receta, selectedValue}  = data;


    let botones = []

    if(type==="simple"){
       const detalle = producto.productoDetalle.find( item => item.recetaId===receta);
       const variatorReceta = detalle.receta.variator.items.find( i=>i.name===target)
        botones = [...variatorReceta.options]
    }


    if(type==="connected"){
        const detalle = producto.productoDetalle.find( item => item.recetaId===target[0].receta);
        const variatorReceta = detalle.receta.variator.items.find( i=>i.name===target[0].name)

        if( enabled.includes("*") )botones = [...variatorReceta.options]
        else botones = [ ...variatorReceta.options.filter( item => enabled.includes(item.name) ) ]
     }



     const handleClick = (value)=>{
            setProductVariator( p=>{

                const mod = cloneDeep(p.botoneras);

                const index = mod.findIndex( item => item.name===name);

                mod[index].selected = value;

                const payload= {
                    ...p,
                    botoneras: mod
                 }

                 console.log("handle click botonera product variator", payload)

                return payload
            })
     }



    return (
    <div className="p-3 border rounded-lg bg-slate-100">     
        <div className="flex">
        <p className="w-1/12 text-xs uppercase">{name}</p>
            {botones.map( (item, index)=>(
                <button key={index} 
                onClick={()=>handleClick(index)}  className={`${index===data.selected?'bg-blue-400':'bg-slate-200'} border rounded-md px-4 py-3 m-1`}>
                    {item.name}  
                </button>
            ))}
        </div>
    </div>
  )
}
