import React, {useState, useEffect} from 'react'
import Producto from "./Producto"
import { FiArrowUp, FiArrowDown } from "react-icons/fi";
import ProductoModalidadModal from "./modales/ProductoModalidadModal";
import {useCajaContext} from "../providers/CajaProvider"
import AgregaAgrandaModal from "./modales/AgregaAgrandaModal";
import dayjs from 'dayjs'



export default function Productos(props) {
    const { list, productosAgrega } = props;
    const items = list;
    const totalItems = items.length;
    const itemsPerPage = 12;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const { productoSeleccionado, setProductoSeleccionado, pedido, setPedido, setVentaInicio, avoidIngredients} = useCajaContext();


    const [addProductModal, setAddProductModal] = useState({
      isOpen: false,
      data: null
    })

    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);


    useEffect(() => {
        // Fetch items from another resources.
        const endOffset = itemOffset + itemsPerPage;
        // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        setCurrentItems(items.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(items.length / itemsPerPage));
      }, [itemOffset, itemsPerPage, items]);
    

      const nextPage = ()=>{
        if( (itemOffset+itemsPerPage)<=itemsPerPage ) setItemOffset(itemOffset + itemsPerPage);
      }


      const prevPage = ()=>{
        if( itemOffset>=itemsPerPage ) setItemOffset(itemOffset - itemsPerPage);
        else setItemOffset(0);
      }      




      const [modalidadOpen, setModalidadOpen] = useState(false)
      const [agrandaAgregaIsOpen, setAgrandaAgregaIsOpen] = useState(false)
      

      const productoClick = (producto)=>{


        console.log("productoClick ", producto)

        if( pedido.length===0 ){
          console.log("reset venta inicio")
          setVentaInicio( dayjs() );
        }


         setProductoSeleccionado( producto );
        // setModalidadOpen(true);


        
          setAddProductModal({
            isOpen: true,
            data: producto            
          })



      }


      const requestCloseModalidad = (modalidad)=>{

        if(modalidad==='solo'){
            setPedido(
                (p)=>([...p, productoSeleccionado ])
            )            
        }

        if(modalidad==='menu'){
          setAgrandaAgregaIsOpen(true);     
        }        

        setModalidadOpen(false);
        setProductoSeleccionado(null);

    }


    const confirmarMenu = ()=>{
        setAddProductModal({
          isOpen: false,
          data: null            
        })
        //  setAgrandaAgregaIsOpen(false);
    }

    const onCancel = ()=>{

      setAddProductModal({
        isOpen: false,
        data: null            
      })

    }


  return (
      <>


        <div className="  w-full    h-full  grid     grid-cols-[1fr_48px]  gap-2">
                <div className="grid grid-cols-2 grid-rows-4 gap-3 flex-1">       
                    {
                        currentItems?.map( i=> <Producto  key={i.producto.id} precio={i.producto.precio}
                          img={i.producto.imagen?.url}
                          titulo={i.producto.nombre}   onClick={()=>productoClick(i)  }  />)
                    }
                </div>

                <div className="flex flex-col text-xs     justify-between">           
                    <div className="flex flex-col space-y-3 ">
                        <button type="button"  onClick={prevPage} className="h-12  bg-green-100 flex  place-items-center justify-center rounded-full">
                        <FiArrowUp size={24} />
                        </button>

                        <button type="button" onClick={nextPage} className="h-12 bg-green-100 flex  place-items-center justify-center rounded-full">
                        <FiArrowDown size={24} />
                        </button>
                    </div>

                    <p className="bg-white text-center">
                    {  `${ Math.ceil(itemOffset/(itemsPerPage)+1) }/${totalPages}` }
                    </p>

                </div>


        </div>


          <ProductoModalidadModal isOpen={modalidadOpen} onRequestClose={ requestCloseModalidad }  />
          <AgregaAgrandaModal  product={ addProductModal.data} productosAgrega={ productosAgrega }   isOpen={addProductModal.isOpen} onRequestClose={ onCancel }  onConfirm={ confirmarMenu }   />

        </>



  )
}
