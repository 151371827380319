import React,{useEffect, useRef, useState} from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    CircularProgress,
    Stack,
    Box,
    Stat,
    StatLabel,
    StatNumber,
    StatGroup,
    IconButton,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Tfoot
  } from '@chakra-ui/react'
  import { NumericFormat } from 'react-number-format';
  import numeral from 'numeral';
  import dayjs from "dayjs";

  import ArqueoModal from "../components/caja/ArqueoModal"


  import { IoArrowBack,  IoGlasses } from "react-icons/io5";
  import { BiShow, BiRightArrowCircle, BiLeftArrowCircle } from "react-icons/bi";



  import {
    useParams, useNavigate
  } from "react-router-dom";


  import ProductoCard  from "../components/zona/ProductoCard";



import { gql, useMutation, useQuery, useLazyQuery} from "@apollo/client";


const CAJA_DETALLE_QUERY = gql`
query($id:Int!) {
    cajaDetalleById( id:$id)
}
`;  







const calculateDifference = (startDate, endDate) => {
  const dEnd = endDate ? dayjs(endDate) : dayjs()
  const diff = dEnd.diff(dayjs(startDate), 'minute'); // Calcula la diferencia en minutos
  const hours = Math.floor(diff / 60); // Calcula las horas
  const minutes = diff % 60; // Calcula los minutos restantes

  return `${hours} horas, ${minutes} minutos`;
};

const StatBox = ({bg, etiqueta, valor})=>          <Box
w="100%"
p={3}
borderWidth="1px"
borderRadius="lg"
bg={bg || 'green.200' }
>
<Stat>
<StatLabel fontSize="sm">{etiqueta}</StatLabel>
<StatNumber fontSize="2xl">{valor}</StatNumber>
</Stat>
</Box>








export default function CajaDetalle() {



  const [modalArqueo, setModalArqueo] = useState({
    open:false,
    initialItems:[]
  })

    const navigate = useNavigate();
    let { id:cajaId } = useParams();


    const {data, loading, error} = useQuery(CAJA_DETALLE_QUERY, { fetchPolicy:'network-only',  variables:{id:parseInt(cajaId) }})
    const cajaDetalleById = data?.cajaDetalleById



    if(!cajaId)return <></>
    if(error)return <div> {JSON.stringify(error)} </div>


    // tickets: 21,
    // total: 845000,
    // totalEfectivo: 506000,
    // totalOtrosMedios: 339000,
    // contadorAgrandado: 15,
    // contadorNoAgrandado: 2

    return (
        !loading ? <div className="flex flex-col gap-5 w-full   absolute bottom-0 top-0 left-0 right-0 p-5"  >




<ArqueoModal readOnly={true} 
  initialItems={modalArqueo?.initialItems || [] }
isOpen={modalArqueo.open} onClose={()=>setModalArqueo({open:false})}   />


<div className="p-3 flex justify-between">
                <IconButton
                            aria-label="Cerrar sesión"
                            icon={<IoArrowBack />}
                            size="lg"
                            colorScheme="blue" // Aquí se establece el color rojo
                            onClick={ ()=> navigate(-1)   }
                            />
                            <p>Detalle de caja #{cajaId}</p>
            </div>

             <div  className="flex gap-3">
                <StatBox bg={'green.200'}  etiqueta="TOTAL"  valor =   {numeral( cajaDetalleById.total ).format('0,0')}    />
                <StatBox bg={'green.300'}  etiqueta="EFECTIVO"   valor = {numeral( cajaDetalleById.totalEfectivo ).format('0,0')}  />
                <StatBox bg={'blue.200'}  etiqueta="OTROS MEDIOS"  valor = {numeral( cajaDetalleById.totalOtrosMedios ).format('0,0')}   />
              </div>

              <div  className="flex gap-3">
              <StatBox bg={'green.200'}  etiqueta="Arqueos"  valor =  {numeral( cajaDetalleById.caja.arqueos.reduce( (acc,arqueo)=>acc+arqueo.monto, 0)   ).format('0,0')}    />
              <StatBox bg={'green.200'}  etiqueta="Tickets"  valor =  {numeral( cajaDetalleById.tickets ).format('0,0')}    />
                <StatBox bg={'green.300'}  etiqueta="Tiempo"   valor = { calculateDifference(cajaDetalleById.caja.createdAt,cajaDetalleById.caja.fechaCierre)  } />
                <StatBox bg={'blue.200'}  etiqueta="Estado"  valor = {cajaDetalleById.caja.estado }  />
              </div>




        <div className="flex  w-full flex-1 bg-slate-800 p-3 gap-3">


            <div className="relative  overflow-auto flex-1  bg-slate-200">
                    <div className="absolute  w-full">
                      <Table variant="simple" size="sm" >
                        <Thead>
                        <Tr>
                              <Th>Ventas</Th>
                            </Tr>
                            <Tr>
                            <Th>ID</Th>
                            <Th>HORA</Th>
                            <Th>FACTURA</Th>
                            <Th>CLIENTE</Th>
                            <Th>TOTAL</Th>
                            <Th>ACTIONS</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {cajaDetalleById.caja.ventas.map((item) => (
                            <Tr key={item.id}>
                                <Td>{item.id}</Td>
                                <Td>
                                    <p> { dayjs(item.createdAt).format("DD/MM/YYYY") } </p>
                                    <p> { dayjs(item.createdAt).format("HH:mm:ss") } </p>
                                </Td>
                                <Td> { `${item.facturaVenta.timbradoPuntoExpedicion}-${item.facturaVenta.timbradoNumero}` }</Td>
                                <Td>
                                    <p> { `${item.facturaVenta.nombre}` }</p>
                                    <p> { `${item.facturaVenta.ruc}` } </p>
                                </Td>
                                <Td>  {numeral( item.total ).format('0,0')}    </Td>
                                <Td>

                                <IconButton
                                aria-label="Cerrar sesión"
                                icon={<BiShow size={20} />}
                                size="md"
                                colorScheme="blue" // Aquí se establece el color rojo
                                onClick={()=>navigate(`/facturaDetalle/${item.id}`)}
                                />
                                    {/* <Button size="sm" colorScheme="blue" onClick={()=>navigate(`/facturaDetalle/${item.id}`)}>
                                        Ver
                                    </Button> */}
                                </Td>
                            </Tr>
                            ))}
                        </Tbody>
                        </Table>
                      </div>
              </div>


               <div className="relative  overflow-auto flex-1  bg-slate-200">
                  <div className="absolute  w-full">
                  <Table variant="simple" size="sm" >
                    <Thead>
                        <Tr>
                          <Th>Movimientos</Th>
                        </Tr>

                        <Tr>
                        <Th>HORA</Th>
                        <Th>Motivo</Th>
                        <Th>MONTO</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {cajaDetalleById.caja.movimientos.map((item) => (
                        <Tr key={item.id}>
                            <Td>
                              <p> { dayjs(item.createdAt).format("DD/MM/YYYY") } </p>
                                <p> { dayjs(item.createdAt).format("HH:mm:ss") } </p>
                            </Td>

                            <Td>  { item.motivo }    </Td>

                            <Td sx={{textAlign:'right'}}  > <p className="flex gap-2 justify-between items-center">
                            { item.tipo==='INGRESO'?<BiRightArrowCircle color="green" size={18} />:<BiLeftArrowCircle color="red" size={18} />  }   {numeral( item.monto ).format('0,0')} 
                              </p>  </Td>

                        </Tr>
                        ))}
                    </Tbody>

                          <Tfoot>
                            <Tr>
                              <Th></Th>
                              <Th></Th>
                              <Th sx={{textAlign:'right'}}>  {numeral( cajaDetalleById.caja.movimientos.reduce( (acc,movimiento)=>acc+movimiento.monto, 0)   ).format('0,0')}    </Th>
                            </Tr>
                          </Tfoot>


                    </Table>
                  </div>
               </div>

               <div className="relative  overflow-auto flex-1  bg-slate-200">
                  <div className="absolute  w-full">
                  <Table variant="simple" size="sm" >
                    <Thead>
                        <Tr>
                          <Th>Arqueos</Th>
                        </Tr>

                        <Tr  >
                        <Th>ID</Th>
                        <Th>HORA</Th>
                        <Th>MONTO</Th>
                        <Th>En Caja</Th>
                        <Th>Tipo</Th>
                        <Th>Autorizado</Th>
                        <Th>ACTIONS</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {cajaDetalleById.caja.arqueos.map((item) => (
                        <Tr   key={item.id}>
                            <Td>{item.id}</Td>
                            <Td>
                                <p> { dayjs(item.createdAt).format("DD/MM/YYYY") } </p>
                                <p> { dayjs(item.createdAt).format("HH:mm:ss") } </p>
                            </Td>
                             
                            <Td isNumeric sx={{whiteSpace: 'nowrap'}}>  <p>{ (item.tipo==='CONTROL' && item.monto!==item.montoEnCaja) ? '❗️':''   }  {numeral( item.monto ).format('0,0')}</p>       </Td>

                            <Td isNumeric>  {numeral( item.montoEnCaja ).format('0,0')}    </Td>

                            <Td>
                             <span className="bg-slate-500 text-slate-100 px-2 rounded-full mx-1">{item.tipo}</span>
                        </Td>

                            <Td>
                                <p> { item.autorizadoPor.firstName } { item.autorizadoPor.lastName } </p>
                            </Td>




                            <Td>
                            <IconButton
                            aria-label="Cerrar sesión"
                            icon={<BiShow size={20} />}
                            size="md"
                            colorScheme="blue" // Aquí se establece el color rojo
                            onClick={ ()=>setModalArqueo({open:true, initialItems:item.metadata.arqueo}) }
                            />
{/* 
                                <Button size="sm" colorScheme="blue"
                                onClick={ ()=>setModalArqueo({open:true, initialItems:item.metadata.arqueo}) }
                                >
                                    ver
                                </Button> */}
                            </Td>
                        </Tr>
                        ))}
                    </Tbody>
                    </Table>
                  </div>
               </div>


            </div>





             </div>:
             <div className="w-full h-full flex justify-center items-center py-12">
               <CircularProgress isIndeterminate color='green.300' />
             </div>
             
    )
  }
