import React from "react";

const IngredientePill = ({ titulo, avoid }) => {
  // const backgroundColor = avoid ? "bg-red-500" : "bg-green-500";
  // const textColor = avoid ? "text-gray-800" : "text-white";

  const backgroundColor = 'border border-4 border-red-200 bg-red-100';
  const textColor = "text-red";

  const textDecoration = avoid ? "line-through" : "none";


  if(!avoid)return <></>


  return (
    <div

      className={`p-1   text-sm uppercase ${backgroundColor} ${textColor} rounded-md float-left`}
    >
      SIN {titulo}  
    </div>
  );
};

export default IngredientePill;
