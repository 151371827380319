import React, {useState, useCallback, useEffect} from 'react'
import CajaHeader from "../components/CajaHeader"
import CajaPago from "../components/CajaPago"
import MenuCategorias from "../components/MenuCategorias"
import PedidoCaja from "../components/PedidoCaja"
import Productos from "../components/Productos"
import axios from 'axios';

import { useNavigate } from "react-router-dom";

import { gql, useMutation, useQuery, useLazyQuery} from "@apollo/client";

import Modal from 'react-modal';
import RucModal from "../components/modales/Ruc"
import MensajeTicketModal from "../components/modales/MensajeTicketModal"
import TicketModal from "../components/modales/Ticket"

import FinishOrderModal from "../components/modales/FinishOrderModal"
import NombreLlamadorModal from "../components/modales/NombreLlamador"

import dayjs from 'dayjs'



import { useCajaContext } from "../providers/CajaProvider";



import TipoFacturaModal from "../components/modales/TipoFacturaModal"
import SugestivaModal from "../components/modales/SugestivaModal"
import AgregaModal from "../components/modales/AgregaModal"
import ConsumoInternoModal from "../components/modales/ConsumoInternoModal"

const customStyles = {
  content: {
    padding: '0px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};



const PRODUCTOS_CAT_QUERY = gql`
 query{
    productosPorCategorias
    formasDePago{
      id
      nombre
    }

    employees{
        id
        cedula
        firstName
        lastName
        avatar
    }

 }
`;

const GENERATE_VENTA_MUTATION = gql`

    mutation($fullData:JSON!) {
        generateVenta( fullData:$fullData){
            id
        }  
    }

`;

const PRINT_TICKET_MUTATION = gql`

    mutation($ticketId:Int!) {
        printTicket( ticketId:$ticketId)
    }

`;



  
  
  
  export default function Caja() {

    const navigate = useNavigate();

    const {clearCaja, ventaPayload, setFormaPago, setCliente,  formaPago, caja, efectivo, cliente, 
      getTotal, pedido, increible, setIncreible, setFormasDePago,
      ventaInicio, setEmployees, employees, consumoInternoModal, setConsumoInternoModal,
      consumoInterno
    } = useCajaContext();

    const [stepTicket, setStepTicket] = useState(0)
    const [sugestivaModal, setSugestivaModal] = useState({
      open:false
    })


    

    const [waitingPos, setWaitingPos] = useState(false)



    const [ generateVentaMutation, {loading:loadingMutation} ] = useMutation(GENERATE_VENTA_MUTATION);
    const [ printTicketMutation  ] = useMutation( PRINT_TICKET_MUTATION );


    const  { loading, error, data:productosCatResponse }  = useQuery(PRODUCTOS_CAT_QUERY );

    const productosCat = productosCatResponse?.productosPorCategorias || [];
    // const productosCat = productosCatResponse?.productosPorCategorias?.filter(i=>i.channel==='mostrador') || [];
    const productosAgrega = productosCatResponse?.productosPorCategorias?.filter(i=>i.channel==='agrega') || [];


console.log("productosCat", productosCat)
console.log("productosAgrega", productosAgrega)

    const [mensajeTicketModalData, setMensajeTicketModalData] = useState({
      open:false,
      data:null
    })


    useEffect(() => {
      if(!caja){
        navigate("/home")
      }
    }, [caja, navigate])
    


    useEffect(() => {
      if(productosCatResponse?.employees){
        setEmployees(productosCatResponse.employees) 
      }
    }, [productosCatResponse])
    


    useEffect(() => {
      if(productosCatResponse?.formasDePago)setFormasDePago(productosCatResponse?.formasDePago)
    }, [productosCatResponse, setFormasDePago])
    



    let subtitle;
    const [currentCategoryList, setCurrentCategoryList] = useState([])
    // const [modalIsOpen, setIsOpen] = useState(false);
    const [rucModalIsOpen, setRucModalIsOpen] = useState(false);
    const [tipoFacturaModalIsOpen, setTipoFacturaModalIsOpen] = useState(false)
    const [ticketModalIsOpen, setTicketModalIsOpen] = useState(false)


console.log("rerender bf handleGenerateVenta", formaPago)
    
    // const handleGenerateVenta = ( cb)=>{
    //     console.log("contextoCaja",  formaPago, increible)
    //     generateVentaMutation({
    //         variables:{
    //             fullData: ventaPayload()
    //         }
    //     })
    //     .then(res=>{

    //       const {data} = res;
    //       console.log("venta generadaaa", formaPago, res)
    //       if(res.generateVenta.id){
    //           cb();
    //           const now = dayjs();
    //           closeTicketModal( {
    //               id: data.generateVenta.id,
    //               vuelto: 0, //efectivo-getTotal(),
    //               tiempo: 0, //now.diff( ventaInicio , 's')
    //           });
    //           closeRucModal();
    //           clearCaja();  
    //       }else{
    //         alert("venta no generada, no id")
    //       } 
    //     })
    //     .catch(e=>console.log("error en venta", e))
    // } 



    const handleGenerateVenta = async ()=>{


      console.log("ventaPayload()", ventaPayload())



      let timeout = 0;

      if(formaPago===4){
        console.log("handleGenerateVenta forma de pago hibrido")
        timeout=3000;
        setWaitingPos(true)
      }


      setTimeout(() => {

              generateVentaMutation({
                variables:{
                    fullData: ventaPayload()
                }
            })
            .then(res=>{
                
                console.log("Ticket.jsx  venta generada", res)

                if(res.data.generateVenta.id){
                    printTicketMutation({
                        variables:{
                            ticketId: res.data.generateVenta.id
                        }
                    })
                    const now = dayjs();
                    closeTicketModal( {
                        id: res.data.generateVenta.id,
                        vuelto: formaPago===1 ?  efectivo-getTotal() : -1,
                        tiempo: now.diff( ventaInicio , 's')
                    });
                    clearCaja();
                }else alert("error, no ventaId")
            })
            .catch(e=>console.log("error en venta", e))
            .finally(()=>{
              setWaitingPos(false)
            })

      }, timeout);







  }





  const completeOrder = async ()=>{


      console.log("completOrder", formaPago)

      if(formaPago===1){
        closeRucModal()
        setTicketModalIsOpen(true) 
      }else if(formaPago===4){

          console.log("forma de pago hibrido")
          closeRucModal()
          setTicketModalIsOpen(true) 

      }else{
          closeRucModal()
          setTicketModalIsOpen(true) 


            const min = 500000;
            const max = 999999;
            const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    

            const order = ventaPayload()

            console.log("order", order)

            const ventaBancardPayload={
                "facturaNro": randomNumber,
                "monto":  order.total
            }
            
            console.log("ventaBancardPayload", ventaBancardPayload)
    
            axios.post(`https://gql.fastview.cloud/ventaBancard`,  {ventaPayload:ventaBancardPayload} ).then((result)=>{
                const {data}=result;
    
                console.log("data bancard venta",data);
    
                if(data.res){
                  handleGenerateVenta()
                }else{
                    alert("Error bancard")
                }
    
            }).catch(error=>{
                alert(`Errror bancard ${error.toString()}`)
            }).finally(() => {
              setTicketModalIsOpen(false) 
            })



          // setTimeout(()=>{
          //   handleGenerateVenta()
          // }, 4000)

        }



    }





    const closeMensajeTicketModal = ()=>{
      setMensajeTicketModalData({
        open:false,
        data:null
      })
    }

    const openMensajeTicketModal = (data)=>{
      setMensajeTicketModalData({
        open:true,
        data
      })
    }





    const openRucModal = () => {
          setStepTicket(2)
//        setRucModalIsOpen(true);
    }
 
    function closeRucModal() {
        setRucModalIsOpen(false);
        setStepTicket(0)
      }    


      function closeTicketModal(data) {
        setTicketModalIsOpen(false);
        console.log("closeTicketModal data", data);
        if(data)openMensajeTicketModal(data)
      }    



      const openTipoFacturaModal = () => {
        setTipoFacturaModalIsOpen(true);
    }      
      function closeTipoFacturaModal() {
        setTipoFacturaModalIsOpen(false);
      }    




      const closeSugestivaModal = ()=>{
        setSugestivaModal({
          open:false
        })
      }


      const continueSugestiva = ()=>{
        closeSugestivaModal()
        setStepTicket(2)
      }




     const ticketHandler = ()=>{


          setSugestivaModal({open:true})

     } 






     const closeConsumoInternoModal = ()=>{
      setConsumoInternoModal({
        open:false
      })
    }


    const continueConsumoInterno = ()=>{
      closeConsumoInternoModal()
    }





  return (


    <>

     <div 
     
     className={`${consumoInterno?'bg-red-500':'bg-slate-500'} h-full gap-4  w-full grid  p-4    grid-cols-[1fr_1fr_1fr]  grid-rows-[1fr_6fr_6fr]`}
     
     
     >
        
        <div className="col-span-3  ">
       
            <CajaHeader   onClickCliente={openRucModal}  onClickTipoFactura={openTipoFacturaModal}   />
        </div>


        <div className="col-span-2 bg-slate-500">
            <PedidoCaja />
        </div>


        <div className="   row-span-2 ">
            <Productos  list = {currentCategoryList}  productosAgrega={productosAgrega}  />
        </div> 


        <div className="  p-3">
                <CajaPago onTicket ={ ticketHandler } />
                {/* <CajaPago onTicket ={ ()=>setStepTicket(2)  } /> */}
        </div> 

        <div className="flex-1   ">
                <MenuCategorias categorias = { productosCat } setCurrentCategoryList={setCurrentCategoryList} />
        </div> 
    </div>




        <RucModal
                isOpen={ stepTicket === 1 }
                onRequestClose={closeRucModal}
                style={customStyles}
        />



      <SugestivaModal
                isOpen={ sugestivaModal.open }
                onRequestClose={  closeSugestivaModal  }
                style={customStyles}
                // handleGenerateVenta={handleGenerateVenta}
                onContinue ={  continueSugestiva    } 
                productosCat = {productosCat}

        />

      <ConsumoInternoModal
                isOpen={ consumoInternoModal.open }
                onRequestClose={  closeConsumoInternoModal  }
                style={customStyles}
                onContinue ={  continueConsumoInterno    } 
                // productosCat = {productosCat}

        />






{
    stepTicket===2 &&
<FinishOrderModal
                isOpen={ stepTicket === 2 }
                onRequestClose={closeRucModal}
                style={customStyles}
                // handleGenerateVenta={handleGenerateVenta}
                onTicket ={ completeOrder
              
              } 
        />}






      <MensajeTicketModal
                isOpen={mensajeTicketModalData.open}
                // isOpen={true}
                onRequestClose={closeMensajeTicketModal}
                style={customStyles}
                data = {mensajeTicketModalData.data}
        />

        <TipoFacturaModal
                isOpen={tipoFacturaModalIsOpen}
                onRequestClose={closeTipoFacturaModal}
                style={customStyles}
        />

      <TicketModal
                isOpen={ticketModalIsOpen}
                onRequestClose={closeTicketModal}
                style={customStyles}
                handleGenerate={handleGenerateVenta}
                waiting={waitingPos}
        />


      </>


  )
}
