import React,{useEffect, useRef, useState} from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    CircularProgress,
    Stack,
    Box,
    Stat,
    StatLabel,
    StatNumber,
    StatGroup
  } from '@chakra-ui/react'
  import { NumericFormat } from 'react-number-format';

  import { useNavigate } from "react-router-dom";

import Resumen from './Resumen'
import QRCode from "react-qr-code";



export default function DetalleModal({ isOpen,   onClose, handleCerrarCaja, handleAbrirGaveta, handlePrintResumenCaja, loading, dataCajaDetalle, verDetalleDeVentas }) {

  const navigate = useNavigate();
  const qrCodeCanvasRef = useRef(null);
  const [qrCode, setQrCode] = useState(null);




    useEffect(() => {
      const qrPayload = {
        c:'authCaja',
        v:42
      }
     setQrCode( JSON.stringify(qrPayload))
    }, [])
    






    if(!dataCajaDetalle)return <></>


    // tickets: 21,
    // total: 845000,
    // totalEfectivo: 506000,
    // totalOtrosMedios: 339000,
    // contadorAgrandado: 15,
    // contadorNoAgrandado: 2

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'4xl'} >
          <ModalOverlay />
          <ModalContent  maxH="900px"  >
            <ModalHeader>Detalle de caja</ModalHeader>
            <ModalCloseButton />



            <ModalBody  >



             { !loading ? <div className="flex gap-5 w-full py-4"  >
                  <div className=" flex-1 flex flex-col justify-between ">
                      <Stack spacing={3}>
                          <Resumen data={dataCajaDetalle} />

                          <div className="flex gap-5">
                            <Stack className="flex-1">
                              <Button py={8} colorScheme="blue" 
                              onClick={()=>navigate(`/cajaDetalle/${dataCajaDetalle.caja?.id}`)}  >Detalle de ventas</Button>
                              <Button py={8} colorScheme="blue" onClick={ ()=>handlePrintResumenCaja(dataCajaDetalle.caja.id) }>Imprimir resumen de caja (Z)</Button>
                            </Stack>

                            { qrCode && <div className="p-5  flex justify-center items-center">
                         {/* <QRCode  size={100} value={qrCode} />             */}
                         <QRCode  size={100} value={ JSON.stringify({c:"verCaja", v:dataCajaDetalle.caja?.id}) } />                 
                        </div>}
                          </div>

                      </Stack>

                  </div>
             </div>:
             <div className="w-full h-full flex justify-center items-center py-12">
               <CircularProgress isIndeterminate color='green.300' />
             </div>
             
             }
            </ModalBody>
  

            {/* <ModalFooter>
              <Button variant='ghost'>Cancelar</Button>
              <Button colorScheme='blue' mr={3} onClick={onClose}>
                Aceptar
              </Button>
            </ModalFooter> */}
          </ModalContent>
        </Modal>
    )
  }
