import {useEffect, useState, createContext} from "react"
import Modal from 'react-modal';
import { ChakraProvider } from '@chakra-ui/react'

import { useSearchParams } from 'react-router-dom';

import './App.css';
// import './styles/main.css';
import Home from './pages/home'
import Caja from './pages/caja'
import Comanda from './pages/comanda'
import Llamador from './pages/llamador'
import Zona from './pages/zona'
import Proyeccion from './pages/proyeccion'
import Reloj from './pages/reloj'
import Login from './pages/login'
import Landing from './pages/landing'
import FacturaDetalle from './pages/facturaDetalle'
import CajaDetalle from './pages/cajaDetalle'



import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from 'react-router-dom'

import ApolloProvider from "./utils/apolloClient";

import WebSocketProvider from "./providers/WebSocketProvider";
import  {useAuthContext}  from './providers/AuthProvider'

import CajaProvider from "./providers/CajaProvider"
import { ComandaProvider } from './components/comanda/ComandaContext';

import dayjs from 'dayjs';
import 'dayjs/locale/es'; // Importa el idioma español
import Launcher from "./pages/launcher";
import { ToastContainer } from "react-toastify";
import AppMain from "./components/AppMain";

// Configura Day.js para utilizar el idioma español de forma predeterminada
dayjs.locale('es');


// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');


function App() {

  const params = new URLSearchParams(window.location.search);
  // Obtener el valor de 'hostname'
  const hostnameParam = params.get('hostname');

  if(hostnameParam){
    localStorage.setItem('hostname', hostnameParam);
  }






  return (
    <ApolloProvider  >
      <ToastContainer />
      <ComandaProvider>
      <WebSocketProvider>
      
        <AppMain />

    </WebSocketProvider>
    </ComandaProvider>
   </ApolloProvider>
  );
}

export default App;




