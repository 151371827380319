import React from 'react'
import dayjs from 'dayjs'

import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    IconButton,
    TableContainer,
  } from '@chakra-ui/react'

  import { BsInfo } from "react-icons/bs";

export default function TablaCajas({data, viewCajaHandler}) {
  return (
          <div className="">
            {
                    data.map(i=>
                    <div key={i.id} className=" border-b border-b-slate-400 p-2 pb-5 flex gap-3 justify-between" >
                        <div>
                          <div className="text-lg"> #{i.id} - { dayjs(i.createdAt).format('DD/MM/YYYY HH:mm:ss') } </div>
                          <div > Autorizado por { `${i.autorizadoPor?.firstName} ${i.autorizadoPor?.lastName}` } </div>
                        </div>
                        <IconButton onClick={()=>viewCajaHandler(i.id)} aria-label='details' icon={<BsInfo />} size={'xs'} /> 
                        {/* <div >{i.estado}</div> */}
                    </div>
                    )
               }
           </div>
  )
}
