import React, {useState, useEffect} from 'react'
import Modal from 'react-modal';
import { FiSearch, FiArrowRight, FiArrowLeft } from "react-icons/fi";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { Stack, Button, ButtonGroup } from '@chakra-ui/react'
import { v4 as uuidv4 } from 'uuid';

import {
    useDisclosure,
    CircularProgress,
    Modal as ChakraModal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
  } from '@chakra-ui/react'


import Ruc from "../../components/finishOrder/Ruc"
import NombreLlamador from "../../components/finishOrder/NombreLlamador"
import FormaPago from "../../components/finishOrder/FormaPago"
import Sugerencias from "../../components/finishOrder/Sugerencias"

import NumPad from 'react-numpad';

import PedidoCaja from "../../components/PedidoCaja"
import CajaBigTotal from "../../components/CajaBigTotal"



import { gql, useMutation, useQuery, useLazyQuery} from "@apollo/client";

import { useCajaContext } from "../../providers/CajaProvider";
import Productos from "../Productos";
import ProductosScroll from "../ProductosScroll";
import PedidoGrid from "../tables/PedidoGrid";
import PedidoGridMinimal from "../tables/PedidoGridMinimal";

const RUC_QUERY = gql`
 query($cedula: String!){
    rucByCedula(cedula: $cedula){
        ruc
        dv
        razon_social
    }
 }
`;





export default function AgregaModal( {isOpen,onRequestClose, style, onContinue, productosAgrega }) {


    const {pedido} = useCajaContext();
    const [agregados, setAgregados] = useState([])



    
    
    const productos = productosAgrega?.find(i=>i.name==='Agrega')?.sellCategoryProducto   || []
    console.log("agrega modal", productosAgrega, productos)

//    const { isOpen:isOpenPos, onOpen:onOpenPos, onClose:onClosePos } = useDisclosure()


    const agregaHandler = ({producto})=>{
        console.log("agregaHandler", producto)
        const item = agregados.find(i=>i.producto.id===producto.id);
        console.log("item", agregados, item)
        if( !item ){

            const payload={
                // producto:{
                //   id:producto.id,
                //   nombre: producto.nombre
                // },
                avoidIngredients:{
                    items:[]
                },
                // variatorValues:  productVariator?.botoneras?.map( i=>({selected:i.selected, name:i.name}))  ,
                observaciones:"",
                producto: producto,
                cantidad:1,
                id: uuidv4()
            //    total: cantidad*precio,
              }
              setAgregados( (prev)=>[...prev, payload]  )
        }else{
            console.log("ya esta agregado", item)
            setAgregados(  agregados.map( i=>{
                if(i.id===item.id) return {...i, cantidad:i.cantidad+1}
                return i
            })   )
        }

    }


    const popItem = (item)=>{
        // find item, if cantidad > 1  reduce, else remove
        console.log("popItem", item)
        const itemFound = agregados.find(i=>i.producto.id===item.producto.id);
        console.log("itemFound", itemFound)
        if(itemFound.cantidad>1){
            setAgregados(  agregados.map( i=>{
                if(i.producto.id===item.producto.id) return {...i, cantidad:i.cantidad-1}
                return i
            })   )
        }else{
            setAgregados(  agregados.filter( i=>i.producto.id!==item.producto.id)   )
        }
    }



  return (

    <>

    <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    style={style}
    contentLabel="Ruc"
  >
    {/* <!-- Main modal --> */}
    <div className="relative   w-full  h-full md:h-auto  ">
            {/* <!-- Modal content --> */}
            <div className="relative bg-white shadow dark:bg-gray-700">
                {/* <!-- Modal header --> */}
                <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                        Agregas algo?
                    </h3>
                    {/* <button type="button" 
                    onClick={onRequestClose}
                    className="text-gray-400  bg-gray-200
                      rounded-full text-sm p-1.5 ml-auto inline-flex items-center"
                      >
                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                        <span className="sr-only">Close modal</span>
                    </button> */}
                </div>



                {/* <!-- Modal body --> */}
                <div className="  flex flex-col gap-5 justify-between "  style={{width:'800px', height:'650px'}}>
                        <div className="flex gap-5 flex-1  ">
                            <div className=" p-4 w-3/5">
                                <ProductosScroll  list = {productos}  agregaHandler={agregaHandler} />
                            </div>

                            <div className="bg-neutral-200 p-4 w-2/5">
                            <PedidoGridMinimal  popItem={popItem} list = {agregados} offset={0} />  
                            </div>
                        </div>

                        <Stack direction='row' spacing={4} justify={'end'}>
                            {/* <Button leftIcon={<FiArrowLeft />} colorScheme='teal'  px={14} py={8} variant='outline' onClick={onRequestClose} >
                                Volver
                            </Button> */}
                            <Button rightIcon={<FiArrowRight />} colorScheme='teal'   px={16} py={8} variant='solid' onClick={()=>onContinue(agregados)}>
                                Continuar
                            </Button>
                        </Stack>
                </div>



            </div>
        </div>
  </Modal>






</>



  )
}
