import React, { useMemo } from 'react';
import { ApolloProvider, ApolloClient, InMemoryCache, split } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';

// import { WebSocketLink } from '@apollo/link-ws';
// import { getMainDefinition } from '@apollo/client/utilities';


let apolloClient;

function createApolloClient() {


  const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('accessToken');

    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const httpLink = authLink.concat(
    createUploadLink({
      uri: process.env.REACT_APP_API_URL_GRAPHQL,
      credentials: 'same-origin',
    })
  );

  // const wsLink = process.browser
  //   ? new WebSocketLink({
  //       uri: process.env.REACT_APP_API_URL_GRAPHQL_WS,

  //       options: {
  //         reconnect: true,
  //       },
  //     })
  //   : null;

  // const splitLink = process?.browser
  //   ? split(
  //       ({ query }) => {
  //         const definition = getMainDefinition(query);
  //         return (definition.kind === 'OperationDefinition' && definition.operation === 'subscription') || (definition?.name?.value==='testViewerCommand');
  //       },
  //       // @ts-ignore
  //       wsLink,
  //       httpLink
  //     )
  //   : httpLink;

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: httpLink, //splitLink,
    cache: new InMemoryCache(),
  });
}

export function initializeApollo(initialState = null) {
  // eslint-disable-next-line
  const _apolloClient = apolloClient;

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();
    // Restore the cache using the data passed from getStaticProps/getServerSideProps
    // combined with the existing cached data
    _apolloClient.cache.restore({ ...existingCache, ...(initialState) });
  }


  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return createApolloClient();
}

const ApolloProviderContext = ({ children }) => {
  const store = useMemo(() => initializeApollo(), []);

  return <ApolloProvider client={store}>{children}</ApolloProvider>;
};




export default ApolloProviderContext;
