import React, {useState} from 'react';

import ZonaCardModal from "../modales/ZonaCardModal"
import ZonaCard from "./ZonaCard"


const customStyles = {
  content: {
    padding: '0px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};





const pedidos = [
  { 
    id:"7", 
    canal:1,
    tiempo:"03:27", 
    items:[
      {cantidad:1, nombre: "Mega deluxe doble",  prefs:"Coca Cola, Papa grande", comments:"sin cebolla"},
      {cantidad:1, nombre: "Mega bacon",  prefs:"Sprite, Papa grande", comments:""},
    ]
  },


  { 
    id:"8", 
    canal:2,
    tiempo:"01:02", 
    items:[
      {cantidad:1, nombre: "Mega deluxe doble",  prefs:"Coca Cola, Papa grande", comments:"sin cebolla"},
      {cantidad:1, nombre: "Mega deluxe doble",  prefs:"Fanta, Papa regular", comments:"sin condimentos"},
      {cantidad:1, nombre: "Mega bacon",  prefs:"Sprite, Papa grande", comments:""},
    ]
  },

  { 
    id:"8", 
    canal:3,
    tiempo:"00:37", 
    items:[
      {cantidad:1, nombre: "Mega deluxe doble",  prefs:"Coca Cola, Papa grande", comments:""},
      {cantidad:1, nombre: "Gaseosa",  prefs:"Coca Cola", comments:""},
      {cantidad:3, nombre: "Gaseosa",  prefs:"Fanta Guarana", comments:"sin hielo"},
    ]
  },
  { 
    id:"9", 
    canal:4,
    tiempo:"00:14", 
    items:[
      {cantidad:1, nombre: "Mega bacon",  prefs:"Sprite, Papa grande", comments:""},
    ]
  },

]




const OrdersTable = ({data, confirmHandle}) => {


      const [pedidoSeleccionado, setPedidoSeleccionado] = useState(null)
      const [modalCard, setModalCard] = useState(false)


    // const pedidos = data.sort((a, b) => {
    //     const [aMin, aSeg] = a.demora.split(':').map(Number);
    //     const [bMin, bSeg] = b.demora.split(':').map(Number);
    //     const aTotal = aMin * 60 + aSeg;
    //     const bTotal = bMin * 60 + bSeg;
    //     return bTotal - aTotal;
    //   });


    const handlePedidoClick = (p)=>{

       setPedidoSeleccionado(p) 
       setModalCard(true)

    }

    // if(!data) return <p>no hay pedidos</p>

    // return <p>{JSON.stringify(data[0],0,null)  }</p>

  return (
    <>
    <div className="grid grid-cols-3 grid-rows-2   flex-1  h-full p-3 gap-3 "  >
 
         {
          data.slice(0,6).map(i=> <div className="">
 
                <ZonaCard  confirmHandle={confirmHandle}  handleClick ={ handlePedidoClick }  key={i.id} pedido={i} />
 
          </div>    )
         }
 
    </div>
        <ZonaCardModal
              isOpen={ modalCard }
              onRequestClose={ ()=>setModalCard(false)  }
              style={customStyles}
              data = {pedidoSeleccionado}
        >
          {
            pedidoSeleccionado && <ZonaCard pedido={pedidoSeleccionado} />
          }
        </ZonaCardModal>
    </>
 
 );
};

export default OrdersTable;
