import React, {useState, useEffect} from 'react'
import Modal from 'react-modal';
import { FiSearch } from "react-icons/fi";

import numeral from 'numeral';
import { useCajaContext } from "../../providers/CajaProvider";
import { gql, useMutation, useQuery, useLazyQuery} from "@apollo/client";
import NumPad from 'react-numpad';

import {
    CircularProgress
  } from '@chakra-ui/react'

import TecladoMonto from "../TecladoMonto"


import  IngredienteToggleButton from "../comanda/IngredienteToggleButton"




export default function PreferenciasModal( {isOpen,onRequestClose, style, preferencias, avoidIngredients, handleAvoid }) {









  return (


    <Modal
    isOpen={isOpen}
    onRequestClose={ ()=>onRequestClose(null)}
    style={style}
    contentLabel="Preferencias"
  >
    {/* <!-- Main modal --> */}
    <div className="   w-full  h-full md:h-auto  " style={{width:'800px', height:"600px"}}>
            {/* <!-- Modal content --> */}
            <div className="relative  p-3 shadow dark:bg-gray-700 flex flex-col h-full">
                {/* <!-- Modal header --> */}

                <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                        Preferencias
                    </h3>
                    <button type="button" 
                    onClick={  ()=>onRequestClose() }
                    className="text-gray-400  bg-gray-200
                      rounded-full text-sm p-1.5 ml-auto inline-flex items-center"
                      >
                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                </div>



                {/* <!-- Modal body --> */}
                <div className="space-y-6   p-5  flex-1 overflow-scroll "  >
                   <div className=" relative flex flex-col gap-5  h-full">

                    <p>Marcar para no incluir ingrediente:</p>

                   <div className="grid gap-3 p-3  border rounded-lg bg-slate-100">
                            {/* <p className="text-2xl ">Preferencias</p> */}       
                            <div className="flex flex-col gap-3">
                                {
                                    preferencias.map((i) => (
                                    <div key={i.id}  className="">
                                        {/* <p>nombre:{i.receta.nombre}</p>
                                        <pre>{JSON.stringify(i)}</pre>
                                        <p>{JSON.stringify(avoidIngredients)}</p> */}
                                        <div className=" gap-3 grid grid-cols-4">
                                        {
                                            i.receta.recetaDetalle.filter(f=>f.sku.tags.includes("PREFERENCIA")).map((v) => (
                                                <div className="bg-red-300 " onClick={ ()=>handleAvoid( `${i.recetaId}-${v.sku.sku}`)} >
                                            <IngredienteToggleButton  avoid={ avoidIngredients?.includes(`${i.recetaId}-${v.sku.sku}`) }   key={v.id} titulo={`${v.sku.nombre}`}>{JSON.stringify(v.sku.tags)}</IngredienteToggleButton>
                                            </div>
                                            ))
                                        }
                                        </div>
                                    </div>
                                    ))
                                }
                            </div>
                    </div>



                   </div>                    
                </div>




                {/* <!-- Modal footer --> */}
                <div className="flex  p-3  justify-end  border-t border-gray-200 dark:border-gray-600">

                <button 
                    onClick = {onRequestClose    }
                    data-modal-toggle="defaultModal" type="button"
                    className="text-neutral-800 bg-green-500    font-medium rounded-lg px-12 py-4 text-2xl text-center disabled:bg-slate-400 ">Aceptar</button>


                </div>
            </div>
        </div>
  </Modal>





  )
}
