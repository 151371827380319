import React from 'react'
import { NumericFormat } from 'react-number-format';
import {getPreferenciasString} from "../../utils/producto"
import ProductoCard from "../zona/ProductoCard";



export default function PedidoGridItem( {item,indice} ) {


    console.log("item pedido grid", item)

    const preferencias = item.producto.productoDetalle.filter((producto) => {
        // utilizamos el método some() para verificar si algún elemento en
        // receta.recetaDetalle tiene sku.tags incluido en "preferencia"
        return producto.receta.recetaDetalle.some((detalle) => {
            return detalle.sku.tags.includes("PREFERENCIA");
        });
    });

    return (
        <div  className="grid 
        grid-cols-[0.5fr_6fr_1fr_1fr]
        bg-white  border-b border-neutral-200 border-2 ">
                {/* <div className="flex flex-col justify-center place-content-center py-1 px-2 text-center border-r text-xs">
                    {indice}
                </div> */}

                <div className="flex flex-col justify-center place-content-center py-1 px-2 text-center border-r relative">
                    <div className="absolute text-[8px] text-slate-700 top-1 left-1">
                        {indice}
                    </div>
                    {item.cantidad}
                </div>

                <div className="flex flex-col justify-center place-content-center py-1 px-2 text-left text-ellipsis border-r">
                    <p className="text-center text-lg font-medium">{item.producto.nombre}</p>
                    <ProductoCard vd={item} />
                </div>

                {/* <div className="flex flex-col justify-center place-content-center py-1 px-2 text-left border-r">
                 
                    <p className="uppercase text-xs">
                        { getPreferenciasString( preferencias, item.avoidIngredients).join(", ") }
                    </p>

                </div> */}
                <div className="flex flex-col justify-start place-content-start py-1 px-2 text-right border-r">
                    <NumericFormat displayType="text" value={  item.producto.precio } thousandSeparator="," />
                </div>
                <div className="flex flex-col justify-start place-content-center py-1 px-2 text-right">
                    <NumericFormat displayType="text" value={  item.cantidad * item.producto.precio } thousandSeparator="," />
                </div>
        </div>
  )
}
