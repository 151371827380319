import React, {useState} from 'react'
import { Link } from "react-router-dom";

import {useWebSocketContext} from "../providers/WebSocketProvider";
import { useCajaContext } from "../providers/CajaProvider";


const BotonPago  =({titulo,handleTicketClick, bgColor})=>{
   return <button  type="button"  onClick={handleTicketClick}
    className={ `border rounded-md p-1 flex justify-center place-items-center  h-full w-full
    flex-col bg-gradient-to-b  ${bgColor}` }  >
        <p className="text uppercase bold text-white"> {titulo}  </p>
    </button>
}



export default function BotoneraPago( {onTicket} ) {


  const { socket } = useWebSocketContext();
  const { setConsumoInternoModal } = useCajaContext();

  const handleTicketClick = ()=>{
    console.log("Ticket Clicked", socket)
    socket.emit("hello", "12345");

     onTicket();

    socket.on("res", (data)=>{
        console.log("respuesta server", data)
    })
  }




  const handleConsumoInterno = ()=>{
    setConsumoInternoModal({
      open:true
    })
  }



  
  return (
    <div className="grid grid-cols-3 grid-rows-2  gap-3  h-full  ">
        


        <BotonPago titulo="Descuento" />
        {/* <Link to="/home">
          <BotonPago titulo="Home" bgColor="bg-green-200" />
        </Link> */}
        <BotonPago titulo="Cupones" />
        <BotonPago titulo="Consumo Interno"   handleTicketClick={handleConsumoInterno}  />

        <div className="flex gap-3  col-span-3">
          <div className="flex-1"> <BotonPago handleTicketClick={handleTicketClick}   titulo="Ticket" /> </div> 
          <div className="col-span-1"> <BotonPago handleTicketClick={()=>{}}   titulo="Consultas" /> </div> 
        </div>


    </div>
  )
}
