import React, {useState, useEffect, useRef} from 'react'
import Modal from 'react-modal';
import { FiSearch } from "react-icons/fi";

import Keyboard from 'react-simple-keyboard';
import NumPad from 'react-numpad';


import 'react-simple-keyboard/build/css/index.css';


import { useCajaContext } from "../../providers/CajaProvider";






const optionsKbd = {
    theme: "hg-theme-default hg-theme-ios",
    layout: {
      default: [
        "Q W E R T Y U I O P {bksp}",
        "A S D F G H J K L {enter}",
        "{shift} Z X C V B N M , . {shift}",
       '.com @ {space}'
      ],
      shift: [
        "Q W E R T Y U I O P {bksp}",
        "A S D F G H J K L {enter}",
        "{shiftactivated} Z X C V B N M , . {shiftactivated}",
       '.com @ {space}'
      ],
      alt: [
        "1 2 3 4 5 6 7 8 9 0 {bksp}",
        `@ # $ & * ( ) ' " {enter}`,
        "{shift} % - + = / ; : ! ? {shift}",
        "{default} {space} {space} {back} {downkeyboard}"
      ],
      smileys: [
        "😀 😊 😅 😂 🙂 😉 😍 😛 😠 😎 {bksp}",
        `😏 😬 😭 😓 😱 😪 😬 😴 😯 {enter}`,
        "😐 😇 🤣 😘 😚 😆 😡 😥 😓 🙄 {shift}",
        "{default} {space} {space} {altright} {downkeyboard}"
      ]
    },
    display: {
      "{alt}": ".?123",
      "{smileys}": "\uD83D\uDE03",
      "{shift}": "⇧",
      "{shiftactivated}": "⇧",
      "{enter}": "return",
      "{bksp}": "⌫",
      "{altright}": ".?123",
      "{downkeyboard}": "🞃",
      "{space}": " ",
      "{default}": "ABC",
      "{back}": "⇦"
    }
  }


export default function NombreLlamador( { onRequestClose, onChangeText, cliente }) {


    const [keyboardRef, setKeyboardRef] = useState(null)
    const [input, setInput] = useState('');

    const kbRef = useRef(null)
   
  const handleSubmit  = ()=>{

    onRequestClose(input);
    console.log("finish nombre")
  }



  useEffect(() => {
    console.log("nombre llamador useEffect change text input")
    onChangeText(input)
  }, [input])
  




  useEffect(() => {
    //setInput(cliente?.razon_social)
    if(cliente?.razon_social){
      const n = cliente?.razon_social;

      let v = n
      const partes = n.split(",");
      if(partes.length > 1){
        v = partes[1].trim();
      }

      console.log("keyboardRef",cliente,  keyboardRef)
      setInput(v)
      keyboardRef?.setInput(v);
    }

  }, [cliente])
  



  return (

    <div className="  w-full  h-full  ">
                <div className="p-6 space-y-6">
                        <div className="flex flex-col gap-8   " style={{ width:'700px' }}  >
                      
{/* <pre>
  {JSON.stringify(cliente)}
</pre> */}

                                <input name="nombre" placeholder="NOMBRE"  className="p-8 text-2xl border uppercase caret-black" 

                                value={input}  readOnly />

                                <Keyboard
                                    ref={kbRef}
                                    {...optionsKbd}
                                    onKeyPress={(button) => {
                                      if (button === "{enter}") {
                                          handleSubmit();
                                      }
                                  }}

                                  onInit={(keyboard) => {
                                        setKeyboardRef(keyboard)

                                  }}

                                    onChange={  (input) => {
                                      console.log("onChange input", input);
                                      setInput(input)
                                    }  }
                                    // onKeyPress={this.onKeyPress}
                                />

                        </div>
                </div>
        </div>
  )
}
