import { gql } from "@apollo/client";


export const VENTAS_QUERY = gql`
    query{
            ventas{
            id
            createdAt
            estadoActual{
                estado
            }
            ventaDetalles{
                cantidad
                avoidIngredients
                producto{
                id
                nombre
                precio
                productoDetalle{
                    id
                    cantidad
                    tags
                    receta{
                    id
                    nombre
                    recetaDetalle{
                        id
                        sku{
                            sku
                        nombre
                        shortName
                        icono
                        tags
                        }
                    }
                    }
                }
                }
            }
            }
    }
`;





export const VENTAS_ZONA_QUERY = gql`
    query{
            ventasZona{
            id
            carril
            createdAt
            estadoActual{
                estado
            }
            ventaDetalles{
                cantidad
                avoidIngredients
                variatorValues
                producto{
                id
                nombre
                precio
                variator
                productoDetalle{
                    id
                    cantidad
                    tags
                    principal
                    orden
                    receta{
                        id
                        nombre
                        variator
                        recetaDetalle{
                            id
                            sku{
                                sku
                                id
                            nombre
                            shortName
                            icono
                            tags
                            }
                    }
                    }
                }
                }
            }
            }
    }
`;






export const VENTAS_LLAMADOR_QUERY = gql`
    query{
            ventasLlamador{
            id
            carril
            createdAt
            llamador
            estadoActual{
                estado
            }
            # ventaDetalles{
            #     cantidad
            #     avoidIngredients
            #     variatorValues
            #     producto{
            #     id
            #     nombre
            #     precio
            #     variator
            #     productoDetalle{
            #         id
            #         cantidad
            #         tags
            #         principal
            #         orden
            #         receta{
            #             id
            #             nombre
            #             variator
            #             recetaDetalle{
            #                 id
            #                 sku{
            #                 nombre
            #                 shortName
            #                 icono
            #                 tags
            #                 }
            #         }
            #         }
            #     }
            #     }
            # }
            }
    }
`;