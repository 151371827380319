import React, { createContext, useContext, useState, useEffect} from 'react';





// @ts-ignore
export const AuthContext = createContext({});

export const useAuthContext = () => useContext(AuthContext);

const AuhProvider= (props) => {



    const { children, protect } = props;

  const [token, setToken] = useState( localStorage.getItem('accessToken') )
  const [authenticated, setAuthenticated] = useState(false)
  const [employee, setEmployee] = useState(null)

  


  useEffect(() => {
    if(token){
      localStorage.setItem('accessToken', token)
    }else{
      localStorage.removeItem('accessToken')
    }
  }, [token])
  

  useEffect(() => {
    const token = localStorage.getItem('accessToken')

    // console.log("token", token)

    if(token){
      // console.log("token valid");
      setAuthenticated(true)
      setToken(token)
    }else{
      console.log("token invalid");
    }
  }, [])
  



  const fastLogin = (employee) => {
        setAuthenticated(true)
        setEmployee(employee)
  }


  const loginEmployee = (token) => {
    console.log("loginEmployee", token)
    setToken(token)
    setAuthenticated(true)
    // setEmployee(employee)
}

  const fastLogout = (employee) => {
    setEmployee(null)
    setToken(null)
    setAuthenticated(false)
    document.location.reload("/login")
  }


//    useEffect(() => {
     
//     //   if(protect && !authenticated){
//     //     navigate("/login")
//     //   }

//    }, [authenticated, navigate, protect])
   



  return (
    <AuthContext.Provider
      value={{
        authenticated,
        employee,
        setEmployee,
        fastLogin,
        fastLogout,
        loginEmployee
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuhProvider;
