import React, {useState, useEffect} from 'react'
import Modal from 'react-modal';
import { FiSearch, FiArrowRight, FiArrowLeft } from "react-icons/fi";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { Stack, Button, ButtonGroup } from '@chakra-ui/react'

import {
    useDisclosure,
    CircularProgress,
    Modal as ChakraModal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
  } from '@chakra-ui/react'


import Ruc from "../../components/finishOrder/Ruc"
import NombreLlamador from "../../components/finishOrder/NombreLlamador"
import FormaPago from "../../components/finishOrder/FormaPago"
import Sugerencias from "../../components/finishOrder/Sugerencias"

import NumPad from 'react-numpad';

import PedidoCaja from "../../components/PedidoCaja"
import CajaBigTotal from "../../components/CajaBigTotal"



import { gql, useMutation, useQuery, useLazyQuery} from "@apollo/client";

import { useCajaContext } from "../../providers/CajaProvider";
import Productos from "../Productos";
import ProductosScroll from "../ProductosScroll";
import PedidoGrid from "../tables/PedidoGrid";
import PedidoGridMinimal from "../tables/PedidoGridMinimal";

const RUC_QUERY = gql`
 query($cedula: String!){
    rucByCedula(cedula: $cedula){
        ruc
        dv
        razon_social
    }
 }
`;



const EmployeeCard = ({employee, clickHandler})=>{
    return <button type="button"
    onClick={ ()=>clickHandler(employee) }
    className="flex flex-col gap-2 items-center border border-blue-100 p-3 cursor-pointer">
        <div className="w-20 h-20 rounded-full border border-blue-200" >
            <img src={employee.avatar} alt=""  />
        </div>
        <div className="flex flex-col items-center">
            <p className="text-lg font-bold text-center"> {employee.cedula}</p>
            <p className="text-xs font-semibold text-center"> {employee.firstName} {employee.lastName} </p>
        </div>
    </button>
}



export default function ConsumoInternoModal( {isOpen,onRequestClose, style, onContinue }) {


    const {  employees, setConsumoInterno } = useCajaContext();



    // console.log("sugestiva modal", productosCat)



    const employeeSelectionHandler = (employee)=>{
        console.log("employeeSelectionHandler", employee)
        setConsumoInterno(employee)
        onRequestClose();
    }


//    const { isOpen:isOpenPos, onOpen:onOpenPos, onClose:onClosePos } = useDisclosure()


  return (

    <>

    <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    style={style}
    contentLabel="Consumo interno"
  >
    {/* <!-- Main modal --> */}
    <div className="relative   w-full  h-full md:h-auto  ">
            {/* <!-- Modal content --> */}
            <div className="relative bg-white shadow dark:bg-gray-700">
                {/* <!-- Modal header --> */}
                <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                        Consumo interno
                    </h3>
                    <button type="button" 
                    onClick={onRequestClose}
                    className="text-gray-400  bg-gray-200
                      rounded-full text-sm p-1.5 ml-auto inline-flex items-center"
                      >
                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                </div>



                {/* <!-- Modal body --> */}
                <div className="  flex flex-col gap-5 justify-between "  style={{width:'900px', height:'650px'}}>
                        <div className="flex-1   overflow-scroll">

                                <div className="grid grid-cols-6 gap-3 p-3  ">
                                    {
                                        employees.map((e)=><EmployeeCard employee={e}  clickHandler={employeeSelectionHandler} key={e._id} >
                                            
                                            </EmployeeCard>)
                                    }

                                </div>

                        </div>

                        {/* <Stack direction='row' spacing={4} justify={'end'}>
                            <Button leftIcon={<FiArrowLeft />} colorScheme='teal'  px={14} py={8} variant='outline' onClick={onRequestClose} >
                                Volver
                            </Button>
                            <Button rightIcon={<FiArrowRight />} colorScheme='teal'   px={16} py={8} variant='solid' onClick={onContinue}>
                                Continuar
                            </Button>
                        </Stack> */}
                </div>



            </div>
        </div>
  </Modal>






</>



  )
}
