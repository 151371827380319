import React, {useState, useContext, useEffect} from 'react';
import Header from '../components/comanda/Header';
import Buttons from '../components/comanda/Buttons';
import OrdersTable from '../components/comanda/Pedidos';
import ProyeccionModal from '../components/comanda/modals/ProyeccionModal';
import CarrilModal from '../components/comanda/modals/CarrilModal';
import {  format } from 'date-fns'

import { gql, useQuery, useMutation} from "@apollo/client";
import { ComandaContext } from '../components/comanda/ComandaContext';

import { VENTAS_QUERY } from "../graphql/querys"






const CONFIRMAR_PRODUCCION_MUTATION = gql`
    mutation($ventaId:Int, $carril:Int) {
      confirmarProduccion( ventaId:$ventaId,carril:$carril  )
    }
`;


// const data = [
//     { descripcion: "Hamburguesa simple", preferencias: "sin lechuga", demora: "02:16" },
//     { descripcion: "Hamburguesa Deluxe", preferencias: "sin tomate", demora: "05:34" },
//     // { descripcion: "Mega Pollo", preferencias: "sin condimentos", demora: "06:12" },
//     // { descripcion: "Mega doble bacon", preferencias: "sin tomate", demora: "04:56" },
//     // { descripcion: "Bacon Imperial", preferencias: "sin lechuga", demora: "08:07" },
//     // { descripcion: "Mega vegetariana", preferencias: "sin queso", demora: "03:23" },
//     // { descripcion: "Hamburguesa clasica", preferencias: "sin cebolla", demora: "07:09" },
//     // { descripcion: "Hamburguesa especial", preferencias: "con todo", demora: "09:51" },
//   ];
  


  const customStyles = {
    content: {
      padding: '0px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };


const Comanda = () => {

  const { loading, error, data, refetch, startPolling, stopPolling } = useQuery(VENTAS_QUERY, {
    fetchPolicy:'no-cache',
   // pollInterval: 2500,
  } );
  const [proyeccionModalIsOpen, setProyeccionModalIsOpen] = useState(false)
  const [carrilModalIsOpen, setCarrilModalIsOpen] = useState(false)


  const [ confirmarProduccionMutation ] = useMutation(CONFIRMAR_PRODUCCION_MUTATION);

  const { setVentas, ventas, selectedVenta} = useContext(ComandaContext);


//  const [refetchInterval, setRefetchInterval] = useState(null);
  // useEffect(() => {
  //   if (!loading) {
  //     setRefetchInterval(setInterval(() => refetch(), 1500));
  //   }
  //   return () => clearInterval(refetchInterval);
  // }, [loading, refetch,refetchInterval]);


  // useEffect(() => {
  //   startPolling(1500)
  // }, [startPolling])
  


  const openProyeccionModal = ()=>{
    setProyeccionModalIsOpen(true)
  }

  const closeProyeccionModal = ()=>{
    setProyeccionModalIsOpen(false)
  }


  const openCarrilModal = ()=>{
    setCarrilModalIsOpen(true)
  }

  const closeCarrilModal = ()=>{
    setCarrilModalIsOpen(false)
  }

  useEffect(() => {

    startPolling(2500);

    return () => {
      stopPolling()
    }
  }, [stopPolling, startPolling])
  


  const onConfirmCarrilHandle = (carril)=>{


      console.log("carril", carril,selectedVenta );


      confirmarProduccionMutation({variables:{ventaId: selectedVenta, carril}})
      .then((res)=>{
        refetch().then(()=>{
          closeCarrilModal();
        });
        console.log(res)
      })
      .catch((err)=>{
        console.log(err)
      })
  }



  const [reloj, setReloj] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => setReloj( 
          format(new Date(), 'dd MMMM HH:mm')
       ), 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);




  useEffect(() => {
    if(data?.ventas)setVentas(data.ventas)
  }, [data, setVentas])
  


  if(loading) return <p>Loading...</p>
  if(error) return <p>Error :{error.toString()}</p>


  return (
      <div className="h-screen w-screen flex flex-col bg-yellow-700 overflow-hidden relative">


      {/* <div className="absolute bottom-1 right-20 z-50">
        <p>1.0.1</p>
      </div> */}

      <div className="text-sm font-extra    flex   place-items-center absolute right-16 bottom-0 z-50">
                <div className=" p-1">
                    <p>{reloj} - v1.0.1</p>
                </div>
            </div>



            <Header /> 

        <div className="flex flex-1 bg-slate-400">
              <div className="flex-1 bg-gray-300 overflow-auto relative ocultar-scroll ">
                          <div className="absolute w-full">
                            <OrdersTable ventas={ventas} openCarrilModal={openCarrilModal}  /> 
                          </div>
              </div>
              <div  className="" >
                  <Buttons  
                  openProyeccionModal = { openProyeccionModal }  
                  openCarrilModal = { openCarrilModal }                
                  />
              </div>
        </div>


        <ProyeccionModal
                  isOpen={proyeccionModalIsOpen}
                  onRequestClose={closeProyeccionModal}
                  style={customStyles}
          />

        <CarrilModal
                  isOpen={carrilModalIsOpen}
                  onRequestClose={closeCarrilModal}
                  onConfirm={ onConfirmCarrilHandle }
                  style={customStyles}
          />
      </div>
  );
};

export default Comanda;
