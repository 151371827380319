import React from 'react'
import { NumericFormat } from 'react-number-format';

import {useCajaContext} from "./../../providers/CajaProvider"

import { getPreferenciasString } from "../../utils/producto";
import PedidoGridItem from "./PedidoGridItem";
import PedidoGridMinimalItem from "./PedidoGridMinimalItem";


// const list  =[
//     { id:1, cantidad:12, descripcion:"Mega Deluxe Bacon", preferencias:"Sin cebolla", precio:35500, total: "544.000" },
//     { id:2, cantidad:12, descripcion:"Mega Deluxe Bacon", preferencias:"Sin cebolla", precio: 12.00, total: 144.00 },
//     { id:3, cantidad:12, descripcion:"Mega Deluxe Bacon", preferencias:"Sin cebolla", precio: 12.00, total: 144.00 },
//     { id:4, cantidad:12, descripcion:"Mega Deluxe Bacon", preferencias:"Sin cebolla", precio: 12.00, total: 144.00 },
//     { id:5, cantidad:12, descripcion:"Mega Deluxe Bacon", preferencias:"Sin cebolla", precio: 12.00, total: 144.00 },
    
//     // { id:6, cantidad:12, descripcion:"Mega Deluxe Bacon", preferencias:"Sin cebolla", precio: 12.00, total: 144.00 },
//     // { id:7, cantidad:12, descripcion:"Mega Deluxe Bacon", preferencias:"Sin cebolla", precio: 12.00, total: 144.00 },
//     // { id:8, cantidad:12, descripcion:"Mega Deluxe Bacon", preferencias:"Sin cebolla", precio: 12.00, total: 144.00 },
//     // { id:9, cantidad:12, descripcion:"Mega Deluxe Bacon", preferencias:"Sin cebolla", precio: 12.00, total: 144.00 },
//     // { id:10, cantidad:12, descripcion:"Mega Deluxe Bacon", preferencias:"Sin cebolla", precio: 12.00, total: 144.00 },
//     // { id:11, cantidad:12, descripcion:"Mega Deluxe Bacon", preferencias:"Sin cebolla", precio: 12.00, total: 144.00 },
//     // { id:12, cantidad:12, descripcion:"Mega Deluxe Bacon", preferencias:"Sin cebolla", precio: 12.00, total: 144.00 },
//     // { id:13, cantidad:12, descripcion:"Mega Deluxe Bacon", preferencias:"Sin cebolla", precio: 12.00, total: 144.00 },
//     // { id:14, cantidad:12, descripcion:"Mega Deluxe Bacon", preferencias:"Sin cebolla", precio: 12.00, total: 144.00 },
//     // { id:15, cantidad:12, descripcion:"Mega Deluxe Bacon", preferencias:"Sin cebolla", precio: 12.00, total: 144.00 },
//     // { id:16, cantidad:12, descripcion:"Mega Deluxe Bacon", preferencias:"Sin cebolla", precio: 12.00, total: 144.00 }
// ]



export default function PedidoGridMinimal( {list,offset, popItem} ) {





    
    return (
    

    <div className="flex flex-col 
    w-full h-full   ">

                <div className="bg-yellow-200 h-12 ">
                    <div className="grid h-full  
                    bg-slate-300
                    grid-cols-[1.5fr_6fr]
                    uppercase text-xs font-bold ">
                                {/* <div className=" flex flex-col justify-center place-content-center border-r ">
                                    <p className="text-center">Item</p>
                                </div> */}
                                <div className=" flex flex-col justify-center place-content-center border-r ">
                                    <p className="text-center">Cant.</p>
                                </div>
                                <div className=" flex flex-col justify-center place-content-center border-r ">
                                <p className="text-center"> Descripción</p>
                                </div>

                    </div>
                </div>


                <div className="relative overflow-auto  h-full">
                    <div className="absolute w-full">
                        {
                        list?.map((i,indice)=> <PedidoGridMinimalItem popItem={popItem} key={i.id} item={i} indice={indice+1 + offset} />        
                        )}
                    </div>
                </div>

    </div>

  )
}
