import { useState, useEffect } from "react";

import {convertirSegundosATiempo, nivelesSemaforo} from "../../utils/varios"

import ProductoCard  from "./ProductoCard";


const ZonaCard = ({pedido, handleClick, confirmHandle})=> {

  const [segundosPasados, setSegundosPasados] = useState(0)


 // console.log("convertirSegundosATiempo", convertirSegundosATiempo, nivelesSemaforo)


  useEffect(() => {
    const intervalo = setInterval(() => {
      const segundosPasados = Math.floor(
        (new Date() - new Date(pedido.createdAt)) / 1000
      );
      setSegundosPasados(segundosPasados);
    }, 1000);
    return () => clearInterval(intervalo);
  }, [pedido.createdAt, setSegundosPasados]);


  let colorSemaforo = nivelesSemaforo[0]

  if(segundosPasados>30)colorSemaforo = nivelesSemaforo[1];
  if(segundosPasados>180)colorSemaforo = nivelesSemaforo[2];
  if(segundosPasados>300)colorSemaforo = nivelesSemaforo[3];



//   const variatorSelectionSingle = (vd, productoItemId)=>{


//     try{
// //        console.log("vd", vd)
// //        console.log("vd filtrado", vd.producto.productoDetalle.map(i=>i.id)  )

//       const productoItem  = vd.producto.productoDetalle.find(i=>i.id===productoItemId);

//       console.log("vd filtrado productoItem", productoItem )


//         const variatorsDeLaRecetaDelProductoItem = vd.producto.variator?.botoneras.filter(i=>{


//               console.log("i.target", i.target, productoItem.receta.id)
//               return ( i.target.some(j=>{
//                 console.log("some", j)
//                 return j.receta===productoItem.receta.id
//               }) );

//         }   );

//         console.log("variatorsDeLaRecetaDelProductoItem", productoItem.receta.nombre,   variatorsDeLaRecetaDelProductoItem);


//         variatorsDeLaRecetaDelProductoItem.map((i,index)=>{

//                 console.log("i", index,  i)
//                 const vs=    vd.variatorValues?.items?.find(j=>j.name===i.name)
//                 console.log("vs", vs,   i.target[0]  )

//         })




//       const variator = vd.producto.variator?.botoneras.find(i=>{

//             console.log("i.target", i.target, productoItem.receta.id)
//             return ( i.target.some(j=>{
//               console.log("some", j)
//               return j.receta===productoItem.receta.id
//             }) );

//       }   );


//       // console.log("variator", variator  )
//       // console.log("variatorValues", vd.variatorValues  )


//       const variatorValue = vd.variatorValues?.items?.find(i=>i.name === variator.name );
//       console.log("variatorValue",  variatorValue  )


//       const variatorOption = productoItem.receta.variator?.items.find((i)=>{
//              console.log("variator option find", i)
//              return ( variator.target.some(j=>{
//               console.log("some", j)
//               return j.name===i.name
//              }) )

      
//       }).options[variatorValue.selected];

      
//       // console.log("variatorOption",  variatorOption  )    
//       // console.log(productoItemId)

//       return `${variatorOption.name} `
//     }catch(e){
//       console.log("error", e)
//       return "e!";
//     }




// }






  
    return <div className="flex w-full h-full   flex-col">

      <div className={`flex gap-5  text-white p-2   items-center justify-center  ${colorSemaforo} w-full `} onClick = { ()=>handleClick(pedido) }>
          <div className={`text-4xl px-4 relative rounded-full ${ pedido.canal%2?'bg-black':'bg-red-800'  } text-white flex flex-col justify-center items-center`}>
              {/* <p className="text-xs uppercase">canal</p> */}
              <p className="text-3xl">{pedido.carril}</p>
          </div>
          <p className="text-3xl font-bold flex-1">#{pedido.id}</p>
          <p className="text-4xl px-4 ">{pedido.tiempo}</p>

          <div key={segundosPasados} className=" p-1 justify-end text-3xl">
               { convertirSegundosATiempo(segundosPasados) }
          </div>
      </div>

      <div className="flex relative   flex-1 flex-col  gap-1 bg-slate-300 overflow-auto" >
          {/* { pedido.items?.map(i=><div className="bg-slate-200 flex flex-col px-3">
            <div className="flex gap-3 ">
              <p>{i.cantidad}</p>
              <p className="flex-1">{i.nombre}</p>
            </div>
           <div className="flex ">
               <p className="font-bold uppercase flex-1">{i.prefs}</p>
               <p className=" italic">{i.comments}</p>
            </div>
          </div>)} */}



{/* <pre>
  {JSON.stringify(pedido, null, 2)}
</pre> */}


          <div className="absolute flex flex-col w-full gap-3">
            {pedido.ventaDetalles
              .filter((f) => f.producto.productoDetalle.length > 0)
              .map((vd, index) => (
                <ProductoCard vd={vd} key={index} />
              ))}
          </div>


      </div>


      <div  className="bg-slate-700 p-3">
           <button  onClick={ ()=>confirmHandle(pedido.id)  } className="w-full h-12 rounded-lg bg-green-500 border text-2xl">Terminar</button>
      </div>



</div>}



export default ZonaCard;