import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { gql, useMutation, useQuery, useLazyQuery} from "@apollo/client";
import dayjs from "dayjs"
import { NumericFormat } from 'react-number-format';
import ProyeccionItem from "./ProyeccionItem";
import LabelValue from "./LabelValue";


import { ComandaContext } from './ComandaContext';



const PROYECCION_QUERY = gql`
 query($fecha: String){
  proyeccion(fecha: $fecha)
 }
`;








const FormConfigurarProyeccion = ({onRequestClose}) => {

  const [fechaReferencia, setFechaReferencia] = useState(new Date());
  const [montoHora, setMontoHora] = useState(900000)

  const [hora, setHora] = useState(10)

  const [proyeccionCalc, setProyeccionCalc] = useState(null)

  const [ proyeccionLazy,  { loading, error, data }, ] = useLazyQuery( PROYECCION_QUERY );


  const { proyeccionComanda, setProyeccionComanda } = useContext(ComandaContext);



  const sumarDia = () => {
    setFechaReferencia(prevDate => {
      return new Date(prevDate.setDate(prevDate.getDate() + 1));
    });
  }

  const restarDia = () => {
    setFechaReferencia(prevDate => {
      return new Date(prevDate.setDate(prevDate.getDate() - 1));
    });
  }



  const sumarHora = () => {
    if(hora<23)  setHora(prevHora => prevHora + 1);
  }

  const restarHora = () => {
    if(hora>10) setHora(prevHora => prevHora - 1);
  }




  useEffect(() => {
    proyeccionLazy({ variables: { fecha: dayjs(fechaReferencia).format('YYYY-MM-DD')   }}).then((res) => {
      console.log("proyeccionLazy res",res);
    });
  }, [fechaReferencia,proyeccionLazy])
  


  useEffect(() => {
    console.log("data proyeccion", data, hora, montoHora);

    try{
      const {   comanda, ventas } = data.proyeccion;

      const ventaHora = ventas.find(venta => {
        return parseInt(venta.hora) === parseInt(hora);
      })

      const comandasHora = comanda.filter(c => {
        return c.APR_HORA === `${hora}:00`;
      })


      const dif = montoHora - ventaHora.precio;


      const factor = ventaHora.precio / montoHora;
      const porcentaje = (factor * 100).toFixed(1);


      const payload = {
        ventaHora,
        comandasHora,
        factor,
        porcentaje: 1/factor
      };
      setProyeccionCalc(payload)

      console.log("ventaHora, comandasHora", payload )


    }catch(e){
      console.log("error proyeccion", e);
    }
    


  }, [data, hora, montoHora])
  



  useEffect(() => {
    console.log("cambios en proyeccionCalc", proyeccionCalc)

    setProyeccionComanda(proyeccionCalc)
  }, [proyeccionCalc])
  



  if(loading)return <div>Cargando...</div>
  if(error)return <div>error {error.toString()}...</div>

  
  return (
    <div className="bg-white p-6 rounded-lg w-full">
      {/* <h2 className="text-lg font-medium mb-4">Configurar Proyecci??n</h2> */}
     

     <div className="grid gap-1 grid-cols-2 w-full" >
      <div className="flex items-center mb-4 space-x-4">
          <label htmlFor="fecha-referencia" className="w-32 font-medium">
            Fecha de referencia
          </label>
          <input
            type="date"
            id="fecha-referencia"
            onChange={ e => setFechaReferencia(new Date(e.target.value)) }
            value={ fechaReferencia.toISOString().split('T')[0] }
            className="border border-gray-400 p-2 w-48"
          />
          <div className="flex gap-3">

            <button className="bg-slate-500 text-white p-4 rounded-lg" onClick={restarDia}>
              <FontAwesomeIcon icon={faMinus} />
            </button>

            <button className="bg-slate-500 text-white p-4 rounded-lg "  onClick={sumarDia}>
              <FontAwesomeIcon icon={faPlus} />
            </button>

          </div>
      </div>

{/* 
      <div className="flex items-center mb-4 space-x-4">
          <label htmlFor="desvio" className="w-32 font-medium">
            Desv??o
          </label>
          <input
            value={desvio}
            type="number"
            onChange={ e => setDesvio(e.target.value)}
            id="desvio"
            className="border border-gray-400 p-2 w-48"
          />
          <div className="flex">
            <button className="bg-slate-500 text-white p-2 rounded-lg mr-2" type="button" onClick={sumarDesvio}>
              <FontAwesomeIcon icon={faPlus} />
            </button>
            <button className="bg-slate-500 text-white p-2 rounded-lg"  type="button" onClick={restarDesvio}>
              <FontAwesomeIcon icon={faMinus} />
            </button>
          </div>
      </div> */}




          <div className="flex items-center mb-4 space-x-4">
              <label htmlFor="desvio" className="w-32 font-medium">
                Hora
              </label>
              <input
                value={hora}
                type="number"
                id="hora"
                className="border border-gray-400 p-2 w-48"
              />
              <div className="flex gap-3">
                <button className="bg-slate-500 text-white p-4 rounded-lg"  type="button" onClick={restarHora}>
                  <FontAwesomeIcon icon={faMinus} />
                </button>
                <button className="bg-slate-500 text-white p-4 rounded-lg " type="button" onClick={sumarHora}>
                  <FontAwesomeIcon icon={faPlus} />
                </button>


              </div>
            </div>


            <div className="flex items-center mb-4 space-x-4">
              <label htmlFor="hora" className="w-32 font-medium">
                Monto hora (simulacion)
              </label>
              <input
                value={montoHora}
                onChange={ e => setMontoHora(e.target.value) }
                type="number"
                id="hora"
                className="border border-gray-400 p-2 w-48"
              />

            </div>


       { proyeccionCalc &&    <div className="flex gap-5">
              <p className="text-3xl">Ventas:  <NumericFormat displayType="text" value={ proyeccionCalc?.ventaHora?.precio} thousandSeparator="," />  
</p>
              <p className="text-3xl">??: {proyeccionCalc.porcentaje.toFixed(1)}x </p>
            </div>   }



   { proyeccionCalc && <div className=" p-5 col-span-2 gap-3 flex flex-col">
      


      <div className="  flex gap-4" >      
     
            {
              proyeccionCalc.comandasHora.map(c => <div className="bg-slate-200 p-3">
                <div>
                    <p className="text-xl font-bold">{c.ART_DESLARGA}</p>
                    <LabelValue label="Referencia" value={c.APR_CANTIDAD}   />
                    <LabelValue label="Proyectado" value={ Math.round(c.APR_CANTIDAD*proyeccionCalc.porcentaje)  }   />
                 </div>
                {/* <p>PHU: { c.ART_UNIDCAJA>1?(Math.round(c.APR_CANTIDAD*proyeccionCalc.porcentaje)/c.ART_UNIDCAJA).toFixed(1):'---'  } </p> */}
              </div>)
            }
       </div> 


         <div className="flex gap-4" >       
         

            {
              proyeccionCalc.comandasHora.map(c => <ProyeccionItem  proyeccionCalc={proyeccionCalc} c={c}  />)
            }
       </div>  


      

       {/* <div className="bg-slate-200 p-5 flex gap-4" >       
            {
              proyeccionCalc.comandasHora.map(c => <div className="bg-green-400 p-3">
                <p>{c.ART_DESLARGA}: ({c.APR_CANTIDAD})={ Math.round(   (c.APR_CANTIDAD*(proyeccionCalc.porcentaje))*(1+(desvio/100))   )  } </p>
                <p>PHU: { c.ART_UNIDCAJA>1?(  Math.round(   (c.APR_CANTIDAD*(proyeccionCalc.porcentaje))*(1+(desvio/100))   )   /c.ART_UNIDCAJA).toFixed(1):'---'  } </p>
              </div>)
            }
       </div>  */}


    </div>}


      </div>



      <div className="flex justify-end">
        {/* <button className="bg-slate-500 text-white p-2 rounded-lg">
          Ver/Ocultar Proyecci??n
        </button> */}

        {/* <button className="bg-red-500 text-white p-2 rounded-lg">
          Cancelar
        </button> */}

        <button  onClick={()=>onRequestClose()} className="bg-green-500 text-white p-2 rounded-lg mr-2">
          Aceptar
        </button>

      </div>
    </div>
  );
};

export default FormConfigurarProyeccion;

