import React, {useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'

import LabelValue from "./LabelValue";


export default function ProyeccionItem({c, proyeccionCalc}) {


    const [desvio, setDesvio] = useState(0)

    const sumarDesvio = () => {
        setDesvio(prevDesvio => prevDesvio + 5);
      }
    
      const restarDesvio = () => {
        setDesvio(prevDesvio => prevDesvio - 5);
      }



  return (<div className="bg-green-400 p-3 flex flex-col ">
        
        
        

        <div>
                    <p className="text-xl font-bold">{c.ART_DESLARGA}</p>
                    <LabelValue label="Calculado" value={ Math.round(c.APR_CANTIDAD*(proyeccionCalc.porcentaje)) }  />
                    <LabelValue label="Con desvio" value={ Math.round(   (c.APR_CANTIDAD*(proyeccionCalc.porcentaje))*(1+(desvio/100))   )  }   />
                 </div>





        <div className="flex items-center mb-4 space-x-8 ">
          {/* <label htmlFor="desvio" className="w-32 font-medium">
            Desvío
          </label> */}
          <input
            value={desvio}
            type="number"
            onChange={ e => setDesvio(e.target.value)}
            id="desvio"
            className="border border-gray-400 p-2 text-xl w-16"
          />
          <div className="flex gap-8">

            <button className="bg-slate-500 text-white p-5 rounded-lg"  type="button" onClick={restarDesvio}>
              <FontAwesomeIcon icon={faMinus} />
            </button>
            <button className="bg-slate-500 text-white p-5 rounded-lg" type="button" onClick={sumarDesvio}>
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
      </div>



      <LabelValue horizontal label="PHU" value={ c.ART_UNIDCAJA>1?(  Math.round(   (c.APR_CANTIDAD*(proyeccionCalc.porcentaje))*(1+(desvio/100))   )   /c.ART_UNIDCAJA).toFixed(1):'---'  }   />

      {/* <p>PHU: { c.ART_UNIDCAJA>1?(  Math.round(   (c.APR_CANTIDAD*(proyeccionCalc.porcentaje))*(1+(desvio/100))   )   /c.ART_UNIDCAJA).toFixed(1):'---'  } </p> */}


      </div>

  )
}
