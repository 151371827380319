import React from 'react';
import Proyeccion from '../components/comanda/Proyeccion';




const App = () => {
  return (
    <div className="h-screen w-screen flex flex-col bg-yellow-700">

            <Proyeccion />

    </div>
  );
};

export default App;
