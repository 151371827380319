import React from 'react'
import { useNavigate } from "react-router-dom";
import  {useAuthContext}  from '../providers/AuthProvider'


import { gql, useMutation, useQuery, useLazyQuery} from "@apollo/client";
import TecladoNumerico from "../components/TecladoNumerico";



const EMPLOYEES_QUERY = gql`
 query{
    employees{
        id
        cedula
        firstName
        lastName
        avatar
    }
 }
`;





const BigBtn  =({titulo, subtitulo,  handleTicketClick, darkmode})=>{

   

    return <button  type="button"  onClick={handleTicketClick}
     className={ `border   ${darkmode ? 'border-slate-400 text-slate-200' : 'border-slate-400 bg-gradient-to-b text-slate-700'}      
     rounded-md  flex justify-center place-items-center  h-full w-full
     flex-col  px-12 py-8 text-3xl  ` }  >
         <p className="text uppercase bold "> {titulo}  </p>
        { subtitulo && <p className="text uppercase text-sm "> {subtitulo}  </p> }
     </button>
 }


 const MedBtn  =({titulo, subtitulo,  handleTicketClick, darkmode})=>{

   

    return <button  type="button"  onClick={handleTicketClick}
     className={ `border   ${darkmode ? 'border-slate-400 text-slate-200' : 'border-slate-400 bg-gradient-to-b text-slate-700'}      
     rounded-md  flex justify-center place-items-center  h-full w-full
     flex-col  px-6 py-4 text-xl  ` }  >
         <p className="text uppercase bold "> {titulo}  </p>
        { subtitulo && <p className="text uppercase text-sm "> {subtitulo}  </p> }
     </button>
 }



export default function Landing() {

    const { employee, authenticated, fastLogin  } = useAuthContext();

    const navigate = useNavigate();
    const  { loading, error, data }  = useQuery(EMPLOYEES_QUERY );



    const handleLogin = (employee)=>{

        fastLogin(employee)
        navigate("/home")


    }





    if(loading)return <p>loading...</p>;
    if(error)return  <pre>{JSON.stringify(error, null, 2)}</pre>;

  return (
    <div className="    flex  flex-col justify-center items-center p-3 flex-1 bg-cyan-300 gap-10  font-extralight">
        


        {/* <div className="grid grid-cols-3 grid-rows-2  gap-5  border border-neutral-500 rounded-xl p-5 bg-slate-800 "> 
            {
                data.employees.map((employee)=> <BigBtn darkmode titulo={employee.firstName} subtitulo={employee.cedula} handleTicketClick={()=>handleLogin(employee) } /> )
            }
        </div> */}

        <div className="grid grid-cols-2   gap-5  border border-neutral-500 rounded-xl p-5 bg-slate-800 "> 
            <MedBtn darkmode titulo="comanda"  handleTicketClick={ ()=> navigate("/comanda")  } />  
            <MedBtn darkmode titulo="zona"  handleTicketClick={ ()=> navigate("/zona")  } />  
            <MedBtn darkmode titulo="llamador"  handleTicketClick={ ()=> navigate("/llamador")  } />  
            <MedBtn darkmode titulo="reload"  handleTicketClick={ ()=> window.location.reload(false)  } />  
        </div>

    </div>
  )
}
