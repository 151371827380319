import React, { useEffect, useState, useContext }  from "react";
import {  format } from 'date-fns'

import { ComandaContext } from './ComandaContext';

import ProyeccionMiniItem from "./ProyeccionMiniItem";
import { useAuthContext } from "../../providers/AuthProvider";

const Header = () => {


  const { employee } = useAuthContext();


  const { proyeccionComanda } = useContext(ComandaContext);

  const [desvio, setDesvio] = useState(0)

    const [value, setValue] = useState(null);

    useEffect(() => {
      const interval = setInterval(() => setValue( 
            format(new Date(), 'dd MMMM HH:mm')
         ), 1000);
  
      return () => {
        clearInterval(interval);
      };
    }, []);

  return (
    <div className="flex w-full  justify-between  bg-gray-800 h-16 text-white gap-5  ">





      {/* <div className="absolute bottom-12 left-12 p-3 bg-slate-200 text-slate-900 w-48 opacity-80">
        <p className="">Consideraciones:</p>
        <p> Las flechas arriba y abajo hacen scroll, por default queda seleccionado el primer item, siempre hay un item seleccionado </p>
      </div> */}


      {/* <div className="text-2xl font-bold flex items-center">
        <p>PREPARACION DE PEDIDOS</p>
      </div> */}


      <div className=" flex  bg-neutral-500 justify-between w-full ">
            <div className="flex   gap-2 h-full  p-2 ">
                <div className=" bg-yellow-500 flex flex-col justify-center">
                      <div className="text-xs text-center bg-black">P-HAMB</div>
                      <div className="text-2xl font-bold  w-20 flex justify-center h-full place-items-center">
                        <p className="text-3xl text-neutral-800">000</p>
                      </div>
                </div>


                <div className=" bg-yellow-500 flex flex-col justify-center">
                      <div className="text-xs text-center bg-black">P-ENS</div>
                      <div className="text-2xl font-bold  w-20 flex justify-center h-full place-items-center">
                        <p className="text-3xl text-neutral-800">000</p>
                      </div>
                </div>


                <div className=" bg-yellow-500 flex flex-col justify-center place-self-end">
                      <div className="text-xs text-center bg-black">NOT-CO</div>
                      <div className="text-2xl font-bold  w-20 flex justify-center h-full place-items-center">
                        <p className="text-3xl text-neutral-800">000</p>
                      </div>
                </div>  
            </div>

          <div className="flex justify-center items-center">
            <p className="text-xl">
            { `${employee?.firstName} ${employee?.lastName} (${employee?.cedula})` }
            </p>
          </div>


            <div className="flex   gap-2 h-full  p-2   justify-end">

              <div className=" bg-green-500 flex flex-col justify-center">
                    <div className="text-xs text-center bg-black">MEGA</div>
                    <div className="text-2xl font-bold  w-20 flex justify-center h-full place-items-center">
                      <p className="text-3xl text-neutral-800">000</p>
                    </div>
              </div>


              <div className=" bg-green-500 flex flex-col justify-center ">
                    <div className="text-xs text-center bg-black">JR</div>
                    <div className="text-2xl font-bold  w-20 flex justify-center h-full place-items-center">
                      <p className="text-3xl text-neutral-800">000</p>
                    </div>
              </div>

              <div className=" bg-green-500 flex flex-col justify-center ">
                    <div className="text-xs text-center bg-black">NUGGETS</div>
                    <div className="text-2xl font-bold  w-20 flex justify-center h-full place-items-center">
                      <p className="text-3xl text-neutral-800">000</p>
                    </div>
              </div>


              {/* {
                  proyeccionComanda &&   proyeccionComanda.comandasHora.filter(i=>i.ART_UNIDCAJA>0).map(c =>
                    
                    <div className="text-2xl font-bold  bg-green-500 w-20 flex justify-center h-full place-items-center">
                      <p className="text-3xl text-neutral-800">{ c.ART_UNIDCAJA>1?(  Math.round(   (c.APR_CANTIDAD*(proyeccionComanda.porcentaje))*(1+(desvio/100))   )   /c.ART_UNIDCAJA).toFixed(1):'---'  }</p>
                    </div>)
                } */}

                {/* <div className="text-2xl font-bold  bg-green-500 w-12 flex justify-center h-full place-items-center">
                    <p className="text-4xl">0</p>
                </div> */}            
            </div>





      </div>




    </div>
  );
};

export default Header;
