import React, {useState, useEffect, useRef} from 'react'

import Keyboard from 'react-simple-keyboard';
import { NumericFormat } from 'react-number-format';


import 'react-simple-keyboard/build/css/index.css';
import '../styles/main.css'


const optionsKbdRuc = {
    theme: "hg-theme-default hg-theme-ios teclado-custom-ruc",
    layout: {
      default: [
        "7 8 9",
        "4 5 6",
        "1 2 3",
        "{bksp} 0 {enter}",
      ]
    },
    display: {
      "{enter}": "→",  // ⏎
      "{bksp}": "⌫"
    }
  }
//       "{enter}": "⏎",


export default function TecladoMonto({onConfirm, onChangeHandler, message, mask=false, defaultAmount=""}) {

    // const [keyboardRef, setKeyboardRef] = useState(null)
    const kbRef = useRef(null)
    const [ruc, setRuc] = useState(`${defaultAmount}`)


    const handleSubmit = (event)=>{
        console.log("handleSubmit", ruc)
        onConfirm(ruc)
        // setRuc("")
        // keyboardRef.clearInput();
    }

  return (
    <div className="flex flex-col gap-2 justify-end items-center">


        { message && <p>
           {message}
        </p>}



        <div className="  flex flex-col gap-2">

            <div className=" bg-slate-300 rounded-lg h-16 flex justify-center items-center  relative overflow-hidden">
                <p className="absolute p-2 text-2xl"> <NumericFormat displayType="text" value={ruc} thousandSeparator="," />  </p>
            </div>

<div className="text-4xl font-light">

        <Keyboard
        
        // ref={kbRef}
        {...optionsKbdRuc}
        onKeyPress={(button) => {
          if (button === "{enter}") {
            handleSubmit();
          }
        }}
        
        onInit={(keyboard) => {
          console.log("on init keyboard", keyboard)
          // setKeyboardRef(keyboard)
        }}
        
        onChange={  (input) => {
          console.log("onChange input", input);
          setRuc(input)
          if(onChangeHandler)onChangeHandler(input)
        }  }
      // onKeyPress={this.onKeyPress}
      />
      </div>
        </div>

     

    </div>
  )
}
