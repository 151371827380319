import dayjs from "dayjs";


function convertirSegundosATiempo(segundos) {
    const minutos = Math.floor(segundos / 60);
    const segundosRestantes = segundos % 60;
    const tiempo = dayjs()
      .set("minute", minutos)
      .set("second", segundosRestantes)
      .format("mm:ss");
    return tiempo;
  }


const nivelesSemaforo=[
  'bg-white text-black',
  'bg-green-300 text-black',
  'bg-yellow-100 text-black',
  'bg-red-400 text-black',
]

export  {convertirSegundosATiempo, nivelesSemaforo};