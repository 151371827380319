import React from 'react'
import { NumericFormat } from 'react-number-format';
import {getPreferenciasString} from "../../utils/producto"
import ProductoCard from "../zona/ProductoCard";



export default function PedidoGridMinimalItem( {item,indice, popItem} ) {




    return (
        <div  className="grid 
        grid-cols-[1.5fr_6fr]
        bg-white  border-b border-neutral-200 border-2 ">
                {/* <div className="flex flex-col justify-center place-content-center py-1 px-2 text-center border-r text-xs">
                    {indice}
                </div> */}

                <button type="button"
                onClick={()=>{
                    if(popItem)popItem(item)
                }}
                className="flex flex-col justify-center place-content-center py-1 px-2 text-center border-r relative">
                    {item.cantidad}
                </button>

                <div className="flex flex-col justify-center place-content-center py-1 px-2 text-left text-ellipsis border-r">
                    <p className=" text-lg font-medium">{item.producto.nombre}</p>
                </div>
        </div>
  )
}
