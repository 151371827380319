import React, {useState} from 'react'
import Modal from 'react-modal';
import { FiSearch } from "react-icons/fi";
import { useCajaContext } from "../../providers/CajaProvider";


const customStyles = {
    content: {
      padding: '0px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

export default function ProductoModalidadModal( {isOpen,onRequestClose, style }) {



  return (


    <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    style={style || customStyles}
    contentLabel="Ruc"
  >
    {/* <!-- Main modal --> */}
    <div className="relative     max-w-2xl h-full md:h-auto  w-64 ">
            {/* <!-- Modal content --> */}
            <div className="relative bg-white shadow dark:bg-gray-700">
                {/* <!-- Modal header --> */}
                <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                       
                    </h3>
                    <button type="button" 
                    onClick={onRequestClose}
                    className="text-gray-400  bg-gray-200
                      rounded-full text-sm p-1.5 ml-auto inline-flex items-center"
                      >
                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                </div>



                {/* <!-- Modal body --> */}
                <div className="p-6 space-y-6 ">
                   <div className="flex flex-col gap-5">
                        <button type="button" 
                        onClick={ ()=>onRequestClose('solo') }
                        class="text-white bg-blue-700  font-medium rounded-lg text-base px-6 py-3.5 text-center ">Solo</button>

                        <button type="button"
                        onClick={ ()=>onRequestClose('menu') } 
                        class="text-white bg-blue-700  font-medium rounded-lg text-base px-6 py-3.5 text-center ">Menu</button>
                   </div>
                </div>


                {/* <!-- Modal footer --> */}
                {/* <div className="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                    <button data-modal-toggle="defaultModal" type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ">Aceptar</button>
                    <button data-modal-toggle="defaultModal" type="button" 
                    onClick={onRequestClose}
                    className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">Cancelar</button>
                </div> */}
            </div>
        </div>
  </Modal>





  )
}
