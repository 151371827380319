import React from 'react'
import { HStack,  Button, Input, useNumberInput } from "@chakra-ui/react";

export default function InputCantidad({ cantidad, setCantidad, handleClick}) {
    const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 1,
      defaultValue: cantidad,
      min: 1,
      max: 1000,
      onChange: (valueAsString, valueAsNumber) => {
        setCantidad( valueAsNumber );
      }
   })

  const inc = getIncrementButtonProps()
  const dec = getDecrementButtonProps()
  const input = getInputProps()

  return (
    <HStack >
      <Button {...dec}>-</Button>
      {/* <Input {...input} /> */}
           <button className="flex justify-center items-center" onClick={handleClick}>
               <p className="text-3xl w-[3em] text-center">{cantidad}</p>
           </button>
      <Button {...inc}>+</Button>
    </HStack>
  )
}
