import React, {useState} from 'react'
import { useNavigate } from "react-router-dom";
import  {useAuthContext}  from '../providers/AuthProvider'
import { Progress, useToast, Stack, Button } from '@chakra-ui/react'

import { gql, useMutation, useQuery, useLazyQuery} from "@apollo/client";
import TecladoNumerico from "../components/TecladoNumerico";



const EMPLOYEES_QUERY = gql`
 query{
    employees{
        id
        cedula
        firstName
        lastName
        avatar
    }
 }
`;


const LOGIN_EMPLOYEE_MUTATION = gql`
    mutation($username:String, $password:String) {
        loginEmployee( username:$username, password:$password ){
            token
            status
            message
            }
    }
`




const BigBtn  =({titulo, subtitulo,  handleTicketClick, darkmode})=>{

   

    return <button  type="button"  onClick={handleTicketClick}
     className={ `border   ${darkmode ? 'border-slate-400 text-slate-200' : 'border-slate-400 bg-gradient-to-b text-slate-700'}      
     rounded-md  flex justify-center place-items-center  h-full w-full
     flex-col  px-12 py-8 text-3xl  ` }  >
         <p className="text uppercase bold "> {titulo}  </p>
        { subtitulo && <p className="text uppercase text-sm "> {subtitulo}  </p> }
     </button>
 }


 const MedBtn  =({titulo, subtitulo,  handleTicketClick, darkmode})=>{

   

    return <button  type="button"  onClick={handleTicketClick}
     className={ `border   ${darkmode ? 'border-slate-400 text-slate-200' : 'border-slate-400 bg-gradient-to-b text-slate-700'}      
     rounded-md  flex justify-center place-items-center  h-full w-full
     flex-col  px-6 py-4 text-xl  ` }  >
         <p className="text uppercase bold "> {titulo}  </p>
        { subtitulo && <p className="text uppercase text-sm "> {subtitulo}  </p> }
     </button>
 }



export default function Login() {

    const toast = useToast()
    const { employee, authenticated, loginEmployee  } = useAuthContext();

    const navigate = useNavigate();
    const  { loading, error, data }  = useQuery(EMPLOYEES_QUERY );

    const [ loginEmployeeMutation, {loading:loadingLogin, error:errorLoading} ]  = useMutation(LOGIN_EMPLOYEE_MUTATION)

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [step, setStep] = useState(0)


    // const handleLogin = (employee)=>{
    //     fastLogin(employee)
    //     navigate("/home")
    // }

    const handleLogin = (password)=>{
        console.log("attemp login", username, password)
        //        fastLogin(employee)
        // navigate("/home")

        setStep(2)

        loginEmployeeMutation({variables:{username, password}}).then((response)=>{
            console.log("login employee data", response)
            const {status, message, token} = response.data.loginEmployee
            if(status===200){
                loginEmployee(token)
                navigate("/launcher")
            }else{
                setStep(0)
                toast({
                    title: 'Error',
                    description: message,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                  })
            }

        }).catch((error)=>{
            console.log("error on login", error)
        })

    }

    const handleUsername = (username)=>{
        setUsername(username)
        setStep(1)
    }



    if(loading)return <p>loading...</p>;
    if(error)return  <pre>{JSON.stringify(error, null, 2)}</pre>;

  return (
    <div className="    flex  flex-col justify-center items-center p-3 flex-1 bg-neutral-800 gap-10  font-extralight ">
        

        <div className="bg-neutral-200 p-5 rounded-lg shadow-md flex flex-col gap-3 px-12 ">
                <div className="text-4xl">
                        <p className="text-xs">{  localStorage.getItem('deviceId') }</p>
                        <p className="text-center">LOGIN</p>

                        {  step===0 && <TecladoNumerico message={"Ingrese su identificador:"} onConfirm={handleUsername} />  }
                        {   step===1 && <TecladoNumerico message={"Ingrese su password:"} onConfirm={handleLogin} mask={true} />  }
                        {   loadingLogin && <Progress size="xs" isIndeterminate />  }
                </div>

                {/* <div className="absolute right-5 bottom-5">
                    <Stack direction={"row"} gap={5}>
                        <Button onClick={()=>navigate("/comanda") }>COMANDA</Button>
                        <Button onClick={()=>navigate("/zona") }>ZONA</Button>
                        <Button onClick={()=>navigate("/llamador") }>LLAMADOR</Button>
                    </Stack>
                </div> */}
        </div>



        {/* <div className="grid grid-cols-3 grid-rows-2  gap-5  border border-neutral-500 rounded-xl p-5 bg-slate-800 "> 
            {
                data.employees.map((employee)=> <BigBtn darkmode titulo={employee.firstName} subtitulo={employee.cedula} handleTicketClick={()=>handleLogin(employee) } /> )
            }
        </div> */}

        {/* <div className="grid grid-cols-2   gap-5  border border-neutral-500 rounded-xl p-5 bg-slate-800 "> 
            <MedBtn darkmode titulo="comanda"  handleTicketClick={ ()=> navigate("/comanda")  } />  
            <MedBtn darkmode titulo="zona"  handleTicketClick={ ()=> navigate("/zona")  } />  
            <MedBtn darkmode titulo="llamador"  handleTicketClick={ ()=> navigate("/llamador")  } />  
            <MedBtn darkmode titulo="reload"  handleTicketClick={ ()=> window.location.reload(false)  } />  
        </div> */}

    </div>
  )
}
