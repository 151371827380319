import React from 'react'
import { NumericFormat } from 'react-number-format';

import {useCajaContext} from "../providers/CajaProvider"


export default function CajaBigTotal() {


    const { getTotal, pedido, descuento} = useCajaContext();

  return (
    <div className="grid 
    bg-slate-200
    grid-cols-[4fr_1fr]
    grid-rows-[1fr_1fr]

    ">
            
            
            
            <div className="row-span-2    bg-slate-400 flex place-items-center justify-center">
                <p className=" text-6xl font-bold ">
                <NumericFormat displayType="text" value= {  getTotal() } thousandSeparator="," />
                   
                </p>
            </div>
        
            <div className=" bg-green-600 flex flex-col place-items-center justify-center">
                  <p className="text-xs uppercase">Dto. %</p> 
                  <p className=" text-xl font-bold ">
                  <NumericFormat displayType="text" value={  descuento.porcentaje } thousandSeparator="," />
                  </p>
            </div>

            <div className=" bg-green-500 flex flex-col place-items-center justify-center">
                   <p className="text-xs uppercase">Dto.</p> 
                  <p className=" text-xl font-bold ">
                  <NumericFormat displayType="text" value={  descuento.absoluto } thousandSeparator="," />
                  </p>
            </div>



        
    </div>
  )
}
