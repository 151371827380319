import React, {useContext} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faCog, faArrowUp, faArrowDown, faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";

import { ComandaContext } from "./ComandaContext";
import { useAuthContext } from "../../providers/AuthProvider";
import { IoHome } from "react-icons/io5";

const Botones = (props) => {


  const {  fastLogout  } = useAuthContext();

  const navigate = useNavigate();
  const { nextVenta, prevVenta }  = useContext(ComandaContext)

    const {openProyeccionModal, openCarrilModal} = props;

  return (
    <div className="p-1 h-full  bg-neutral-600 flex flex-col justify-between">




    <div className="flex flex-col items-center justify-center gap-5  ">
        <button className="w-12 h-12 rounded-full bg-green-500 border = " type="button"   onClick={ openCarrilModal }>
                <FontAwesomeIcon icon={faCheck} size="2x" />
            </button>
            {/* <p className="text-xs font-medium">Terminar</p> */}
      </div>




      <div className="flex flex-col items-center justify-center gap-5  ">
        <div className="flex flex-col items-center justify-center">
            <button className="w-12 h-12 rounded-full bg-blue-200   "  onClick={prevVenta} >
                <FontAwesomeIcon icon={faArrowUp} size="2x" />
            </button>
            {/* <p className="text-xs font-medium">UP</p> */}
         </div>

        <div className="flex flex-col items-center justify-center">
            <button className="w-12 h-12 rounded-full bg-blue-200  " onClick={nextVenta} >
                <FontAwesomeIcon icon={faArrowDown} size="2x" />
            </button>
            {/* <p className="text-xs font-medium">DOWN</p> */}
         </div>
      </div>






      <div className="flex flex-col   justify-center gap-5  ">
       <div className="flex flex-col items-center justify-center ">
            <button className="w-12 h-12 rounded-full bg-orange-300  flex justify-center items-center   " onClick={ openProyeccionModal }>
            <FontAwesomeIcon icon={faCog} size="2x" />
            </button>
            {/* <p className="text-xs font-medium">cfg</p> */}
        </div>


        <div className="flex flex-col items-center justify-center">
            <button className="w-12 h-12 rounded-full bg-green-500  flex justify-center items-center    "   onClick={()=>navigate('/launcher') }  >
             <IoHome icon={faTimes} size="28" />
            </button>
            {/* <p className="text-sm font-medium">Salir</p> */}
         </div>

       
        <div className="flex flex-col items-center justify-center">
            <button className="w-12 h-12 rounded-full bg-red-500 flex justify-center items-center     "   onClick={()=>fastLogout() }  >
             <FontAwesomeIcon icon={faTimes} size="2x" />
            </button>
            {/* <p className="text-sm font-medium">Salir</p> */}
         </div>
      </div>





    </div>
  );
};

export default Botones;
