import React, { createContext, useContext, useState, useEffect} from 'react';

import dayjs from 'dayjs';
const numLet = require('numero-a-letras');


// @ts-ignore
export const CajaContext = createContext({});

export const useCajaContext = () => useContext(CajaContext);

const CajaProvider= ({ children }) => {

  console.log("render CajaProvider CONTEXT")

  const [employees, setEmployees] = useState([])
  const [formasDePago, setFormasDePago] = useState([])

  const [ventaInicio, setVentaInicio] = useState( dayjs() )

  const [nombreLlamador, setNombreLlamador] = useState("")

  const [increible, setIncreible] = useState(0)

  const [consumoInterno, setConsumoInterno] = useState(null)

  const [consumoInternoModal, setConsumoInternoModal] = useState({
    open:false
  })


  const [efectivo, setEfectivo] = useState(0)
  const [formaPago, setFormaPago] = useState(1)

  const [sucursal, setSucursal] = useState({
    id: 2,
  })
  const [employee, setEmployee] = useState({
    id: 2,
  })
  const [caja, setCaja] = useState(null)
  const [cliente, setCliente] = useState(null)  
  const [pedido, setPedido] = useState([])
  const [tipoFactura, setTipoFactura] = useState({
    tipo: "Ticket",
    label: "T"
  })

  const [productoSeleccionado, setProductoSeleccionado] = useState(null)
  const [productVariator, setProductVariator] = useState(null)
  const [avoidIngredients, setAvoidIngredients] = useState([])
  const [variatorValues, setVariatorValues] = useState([])


  const [descuento, setDescuento] = useState({
    porcentaje: 0,
    absoluto: 0
  })


  const clearCaja = ()=>{
      console.log("clear caja"); 
      setEfectivo(0)
       setFormaPago(1)
       setCliente(null)
       setPedido([])
       setNombreLlamador("")
       setConsumoInterno(null)
  }



  useEffect(() => {
    if(  pedido.length===0  ) setVentaInicio( dayjs() );
  }, [pedido])
  



  useEffect(() => {
    console.log("use effect in productVariator CajaContext", productVariator)
  }, [productVariator])
  


  const  establecerFP = (fp)=>{
    setFormaPago(fp)
  }


  const getTotal = ()=>(pedido.reduce((acc, item) => acc + item.producto.precio*item.cantidad, 0))


  const ventaPayload = ()=>{

    console.log("NumeroALetras", numLet.NumerosALetras(77500)   )
    console.log("formaPago", formaPago   )


      const now = dayjs();

      const payload ={
        "observaciones":"",
          "cajaId":caja.caja?.id,
          "total":  getTotal(),
          "facturaVenta":cliente ? {
            "ruc":`${cliente.ruc}-${cliente.dv}`,
            "nombre": cliente.razon_social,
          }:{"ruc":"00000000000","nombre":"Consumidor Final"},
          "productos": pedido.map( i=>({ 
            cantidad: parseInt(i.cantidad), 
            total: i.total, 
            avoidIngredients:  i.avoidIngredients,   //avoidIngredients:{items:i.avoidIngredients},
            variatorValues:{items:i.variatorValues}, 
            productoId: i.producto.id, observaciones: i.observaciones})),
          
          // [
          //   { "cantidad":1, "total":7000, "productoId":1, "observaciones":"sin sal" },
          //   { "cantidad":1, "total":7000, "productoId":4, "observaciones":"bien caliente" }
          // ],
            tiempo: now.diff( ventaInicio , 's'),
          nombreLlamador,
          formaPago,
          pagoDetalle:[
            { 
              formaPagoId: 
              formaPago , 
              monto: parseInt(efectivo),
              vuelto: parseInt(efectivo) - getTotal()
            }
          ]

      }

      console.log("payload", payload)

      return payload;

  }



  return (
    <CajaContext.Provider
      value={{
        caja, setCaja,
        cliente,
        setCliente,
        pedido,
        setPedido,
        tipoFactura,
        setTipoFactura,
        descuento,
        setDescuento,
        productoSeleccionado,
        setProductoSeleccionado,
        ventaPayload,
        efectivo, setEfectivo,
        getTotal,
        ventaInicio, setVentaInicio,
        productVariator, setProductVariator,
        avoidIngredients, setAvoidIngredients,
        variatorValues, setVariatorValues,
        increible, setIncreible,
        establecerFP,
        formaPago, setFormaPago,
        nombreLlamador, setNombreLlamador,
        clearCaja,
        formasDePago, setFormasDePago,
        employees, setEmployees,
        consumoInternoModal, setConsumoInternoModal,
        consumoInterno, setConsumoInterno

      }}
    >
      {children}
    </CajaContext.Provider>
  );
};

export default CajaProvider;
