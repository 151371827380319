import React from 'react'

import {   IconButton    } from '@chakra-ui/react'

import { FaSignOutAlt  } from 'react-icons/fa';
import { IoHome, IoReload } from "react-icons/io5";
import { useAuthContext } from "../providers/AuthProvider";
import { useNavigate } from "react-router-dom";

export default function Footer() {

    const {  fastLogout  } = useAuthContext();
    const navigate = useNavigate();

  return (
<div className="flex gap-5 justify-end items-center bg-neutral-800 p-2">
              {/* <Button size={"sm"} onClick={()=>navigate("/comanda") }>COMANDA</Button>
              <Button size={"sm"} onClick={()=>navigate("/zona") }>ZONA</Button>
              <Button size={"sm"} onClick={()=>navigate("/llamador") }>LLAMADOR</Button> */}


            <IconButton
                    aria-label="Launcher"
                    icon={<IoHome />}
                    size="sm"
                    colorScheme="green" // Aquí se establece el color rojo
                    onClick={ ()=> navigate('/launcher')   }
                    />


              <IconButton
              aria-label="Cerrar sesión"
              icon={<IoReload />}
              size="sm"
              colorScheme="blue" // Aquí se establece el color rojo
              onClick={ ()=> window.location.reload(false)    }
              />
              <IconButton
              aria-label="Cerrar sesión"
              icon={<FaSignOutAlt />}
              size="sm"
              colorScheme="red" // Aquí se establece el color rojo
              onClick={ ()=>fastLogout()   }
          />
      </div>
  )
}
