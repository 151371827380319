import React, {useState, useEffect} from 'react'
import { FiArrowUp, FiArrowDown, FiTrash2 } from "react-icons/fi";

import {useCajaContext} from "../providers/CajaProvider"

import PedidoGrid from "./tables/PedidoGrid";




export default function PedidoCaja() {

    const {pedido} = useCajaContext();

    const items = pedido;
 
     const totalItems = items.length;
    const itemsPerPage = 6;
    const totalPages = Math.ceil(totalItems / itemsPerPage);


    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);


    // useEffect(() => {
    //     // Fetch items from another resources.
    //     const endOffset = itemOffset + itemsPerPage;
    //     console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    //     setCurrentItems(items.slice(itemOffset, endOffset));
    //     setPageCount(Math.ceil(items.length / itemsPerPage));
    //   }, [itemOffset, itemsPerPage, items]);
    

    useEffect(() => {
        setCurrentItems(items);
      }, [itemOffset, itemsPerPage, items]);


      const nextPage = ()=>{
        console.log("ites",{
            items:items.length,
            itemsPerPage,
            itemOffset

        })
        if( (itemOffset+itemsPerPage) < items.length+1 ) setItemOffset(itemOffset + itemsPerPage);
        //else setItemOffset(itemOffset+itemsPerPage);
      }


      const prevPage = ()=>{
        if( itemOffset>=itemsPerPage ) setItemOffset(itemOffset - itemsPerPage);
        //else setItemOffset(0);
      }      


  return (
    <div className=" h-full  flex   w-full  gap-2">
        
        {/* <div className="min-h-0  h-full    bg-slate-800">
             <PedidoGrid  list = {currentItems} /> 
        </div> */}


        <div className="min-h-0  h-full  relative   w-full bg-white">
            <PedidoGrid  list = {currentItems} offset={itemOffset} />  
        </div>

        {/* <div className="min-h-0  h-full overflow-scroll  bg-slate-400">
            <div className=" relative ">
                <div className=" absolute  top-0 left-0 right-0 bottom-0">
                    <PedidoTable />   
                </div>
            </div >
        </div> */}


        {/* <div className="flex flex-col text-xs     justify-between">
            
            <div className="flex flex-col space-y-3 ">

                <button
                type="button" 
                onClick={prevPage}
                className="h-12 rounded-full  bg-green-100 flex  place-items-center justify-center">
                  <FiArrowUp size={24} />
                </button>

                <button
                type="button" 
                onClick={nextPage} 
                className="h-12 rounded-full bg-green-100 flex  place-items-center justify-center">
                  <FiArrowDown size={24} />
                </button>
            </div>

            <p className="bg-white text-center">
                {  `${ Math.ceil(itemOffset/(itemsPerPage)+1) }/${totalPages}` }
            </p>
        </div> */}

  

    </div> 
  )
}
