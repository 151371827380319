import React, {useState, useEffect} from 'react'
import { useNavigate } from "react-router-dom";
import { Progress, Button,  useToast, IconButton, Box, Stack,
Table,
Thead,
Tbody,
Tr,
Th,
Td,
} from '@chakra-ui/react'

import AperturaModal from "../components/caja/Apertura"
import CierreModal from "../components/caja/Cierre"
import CajaDetalleModal from "../components/caja/DetalleModal"
import ArqueoModal from "../components/caja/ArqueoModal"
import CheckAuthModal from "../components/auth/CheckAuth"


import QRCode from "react-qr-code";


import dayjs from "dayjs"

import  {useAuthContext } from '../providers/AuthProvider'
import  { useCajaContext }    from '../providers/CajaProvider'
import { gql, useMutation, useQuery, useLazyQuery} from "@apollo/client";
import { NumericFormat } from 'react-number-format';

import { FaSignOutAlt, FaCashRegister } from 'react-icons/fa';
import { IoReload } from "react-icons/io5";


import {
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,
    StatArrow,
    StatGroup,
  } from '@chakra-ui/react'
import TablaCajas from "../components/TablaCajas";
import Footer from "../components/Footer";





const ME_QUERY = gql`
    query{
        meEmployee{
            id
            cedula
            firstName
            lastName
            avatar
            roles{
                nombre
            }
        }
    }
`;



const getPathByRole = (role) => {
    switch (role) {
        case "CAJERO":
            return "/home"
        case "COMANDA":
            return "/comanda"
        case "ZONA":
            return "/zona"
        case "LLAMADOR":
            return "/llamador"
        default:
            return "/"
    }
}


const divStyle = {
  backgroundImage: 'url("/images/fastHorizontal.svg")',
  // backgroundImage: 'url("/images/mostazalogo.svg")',
  backgroundSize: 'auto 140px', // Ajusta el tamaño de fondo automáticamente con un alto máximo de 200px
  backgroundRepeat: 'no-repeat', // Evita que el fondo se repita
  backgroundPosition: 'center',
  height: '100%', // Fija el alto del contenedor
  width: '100%', // Puedes ajustar esto según tus necesidades
};


export default function Launcher() {

    const [roles, setRoles] = useState([])
    const toast = useToast();
    const [mounted, setMounted] = useState(false)
    const {  fastLogout, setEmployee, employee } = useAuthContext();



    const {data:dataMe, loading, error} = useQuery(ME_QUERY);




    const { caja, setCaja   }   = useCajaContext();
    const navigate = useNavigate();



    useEffect(() => {
      if(dataMe){
        console.log("dataMe", dataMe)
        setEmployee(dataMe.meEmployee)
        // navigate("/home")

      }
    }, [dataMe,setEmployee])
    


    useEffect(() => {
        console.log("useEffect employee", employee)
     if(employee){
         const {roles} = employee

         if(roles){
            if(roles.length>1){
              setRoles(roles.map(i=>i.nombre))
          }else{
              const firstRole = roles[0]
              console.log("firstRole", firstRole)
              navigate( getPathByRole(firstRole.nombre) )
          }
         }



     }
    }, [employee, navigate])
    



    useEffect(() => {
        // Marca la página como montada cuando se monta el componente
        setMounted(true);

        // Retorna una función de limpieza para desmontar la página
        return () => {

          setMounted(false);
        };
      }, []);




    // useEffect(() => {
    //   if(mounted)refetch()
    // }, [mounted,refetch])
    











    if(loading)return <p>loading...</p>
  //  if(error)return <pre>{JSON.stringify(error, null, 2)} </pre>


  return (
    <div className="flex flex-col   items-center  w-full bg-gray-800 flex-1"
      style={ divStyle }
    >

      <div className="  flex justify-center  gap-5 p-5   w-full">
{/* 
         <div className="flex justify-center items-center  ">
             <img src="/images/mostazalogoIso.svg" className="h-12"  />
         </div> */}


            {
                  roles.map( (rol,index)=>
                    <button 
                  key={index} 
                  onClick={()=>navigate( getPathByRole(rol) )}   
                  className="bg-blue-300  text-black font-bold py-4 px-8 rounded h-16 w-52  selection:none  focus:outline-none " >{rol}</button>
                  )


            }

      </div>


          {/* <div className="flex-1 flex justify-end items-end pb-24">
            <img src="/images/mostazalogo.svg"   width={200} />
          </div> */}

    <div className="absolute bottom-0 left-0 right-0">

            <Footer />
      
    </div>

{/* <pre>{JSON.stringify(employee, null, 2)}</pre> */}


</div>
  )
}
