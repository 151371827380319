import React,{useEffect, useRef, useState} from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    CircularProgress,
    Stack,
    Box,
    Input,
    Stat,
    StatLabel,
    StatNumber,
    StatGroup
  } from '@chakra-ui/react'
  import { NumericFormat } from 'react-number-format';

  import {  useMutation, useQuery, gql } from "@apollo/client";

  import numeral from 'numeral';

  import ArqueoModal from "./ArqueoModal"


  import TecladoMonto from "../TecladoMonto"
  import Resumen from "./Resumen"

import QRCode from "react-qr-code";




const BALANCE_GENERAL_QUERY = gql`
    query($id:Int!){
      balanceGeneralPorCaja(id:$id)
    }
`;






export default function CierreModal({ isOpen, caja, onOpen, onClose, handleCerrarCaja, handleAbrirGaveta, handlePrintResumenCaja, loading, loadingCierre, dataCajaDetalle }) {

  const qrCodeCanvasRef = useRef(null);
  const [qrCode, setQrCode] = useState(null);
  const [modalArqueoCierre, setModalArqueoCierre] = useState({open:false})
  const [montoCierre, setMontoCierre] = useState(0)
  const [arqueoRes, setArqueoRes] = useState(null)
  const [balance, setBalance] = useState(0)

  const { loading:loadingBalance, error, data, refetch } = useQuery(BALANCE_GENERAL_QUERY, { variables:{id:Number( dataCajaDetalle?.caja?.id  )},})
  const balanceGeneralPorCaja  = data?.balanceGeneralPorCaja

    useEffect(() => {
      const qrPayload = {
        c:'authCaja',
        v:42
      }

      // 

       const url = `${process.env.REACT_APP_FV_URL}/dashboard/cajas/${dataCajaDetalle?.caja?.id}`

     setQrCode( JSON.stringify(url))
    }, [])
    

    useEffect(() => {
      refetch()
      setMontoCierre(0)
      setArqueoRes(null)
    }, [isOpen,refetch,setMontoCierre])
    


    useEffect(() => {
      // console.log("use effect,balanceGeneralPorCaja ", balanceGeneralPorCaja,  dataCajaDetalle?.caja )
      if( balanceGeneralPorCaja && dataCajaDetalle?.caja ) setBalance( balanceGeneralPorCaja.totalVentasEfectivo+dataCajaDetalle.caja.monto-balanceGeneralPorCaja.totalAlivios-montoCierre )
    }, [balanceGeneralPorCaja, dataCajaDetalle, montoCierre])
    



    // console.log("dataCajaDetalle", dataCajaDetalle)

    const openArqueModalCierre = ()=>{
      setModalArqueoCierre({open:true, title:'Arqueo de cierre de caja'})
    }

    const handleArqueo = (v)=>{
      console.log("handle arqueo", v)
      setArqueoRes(v)
      setMontoCierre(   v.reduce( (acc,i)=>acc+(i.valor*i.cantidad) ,0 )   )
      setModalArqueoCierre( p=>({...p, open:false}) )
    }




    const confirmCierre = ()=>{


      
      // Math.abs(balance)
      // let msg = 'Confirmas el cierre de caja?'
      // const montoStr = numeral( Math.abs(balance) ).format('0,0')
      // if(balance>0)msg = `Confirmas el cierre de caja con un faltante de ${montoStr}?`
      // if(balance<0)msg = `Confirmas el cierre de caja con un sobrante de ${montoStr}?`
      

      const montoStr = numeral( Math.abs(montoCierre) ).format('0,0')
      let msg = `Confirmas el cierre de caja con un monto de ${montoStr}?`
      // if(balance>0)msg = `Confirmas el cierre de caja con un faltante de ${montoStr}?`
      // if(balance<0)msg = `Confirmas el cierre de caja con un sobrante de ${montoStr}?`

      const prompt = window.confirm(msg)

      if(prompt) handleCerrarCaja(
        {
          montoCierre,
          arqueo:arqueoRes,
          balance
        }
      )
    }




    if(!caja)return <></>
    if(!dataCajaDetalle)return <></>

    if(loadingBalance)return <div>loading...</div>

    
    // tickets: 21,
    // total: 845000,
    // totalEfectivo: 506000,
    // totalOtrosMedios: 339000,
    // contadorAgrandado: 15,
    // contadorNoAgrandado: 2

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'4xl'} >
          <ModalOverlay />
          <ModalContent  maxH="800px"  >
            <ModalHeader>Cierre de caja</ModalHeader>
            <ModalCloseButton />



            <ModalBody  >

            <ArqueoModal  title={modalArqueoCierre.title}  isOpen={modalArqueoCierre.open}
            initialItems={arqueoRes}
            onClose={()=>setModalArqueoCierre({open:false})} 
                handleArqueo={ handleArqueo }  />

             { !loading ? <div className="flex gap-5 w-full "  >
                  {/* <div className="flex justify-center items-center" >
                     <TecladoMonto  message={"Ingrese efectivo a rendir"}  onConfirm={ (v)=>handleCerrarCaja(v) } />
                  </div> */}

                  <div className=" flex-1 flex flex-col justify-between">
                      <Stack spacing={2}>

                      <Button variant={"solid"} colorScheme="blue"  paddingX={16} paddingY={8} size={"lg"}  onClick={ handleAbrirGaveta }>ABRIR GAVETA</Button>


                      <Button py={8} colorScheme="blue" onClick={ openArqueModalCierre }>Ingresar monto de cierre</Button>

                      <Box>
                        <Resumen minimal data={dataCajaDetalle} general={balanceGeneralPorCaja} montoCierre={montoCierre} balance={balance} />
                      </Box>



                      <Button py={8} colorScheme="blue" onClick={ ()=>handlePrintResumenCaja() }>Imprimir resumen de caja (Z)</Button>
                      </Stack>


                      <Button mt={5}  py={12} colorScheme="red" onClick={ confirmCierre  } disabled={loadingCierre}>Confirmar cierre</Button>

                        {/* <div className="border border-blue-200 p-3 flex flex-col gap-3">
                              { (balance>0) && <p>Faltante: <NumericFormat displayType="text" value= { Math.abs(balance)} thousandSeparator="," /> </p>}
                              { (balance<0) && <p>Sobrante: <NumericFormat displayType="text" value= { Math.abs(balance) } thousandSeparator="," /> </p>}


                              { ( (balance>0) || (balance<0) ) &&
                                <div className="text-xs text-red-700 max-w-[180px]">
                                Adjuntar documento de consentimiento de faltante/sobrante
                              </div>
                              }


                              <Button py={8} colorScheme="red" onClick={ confirmCierre  } disabled={loadingCierre}>Confirmar cierre</Button>
                      </div> */}


                  </div>



                  <div className=" p-1 flex flex-col gap-3">
                        { qrCode && <div className="  bg-white flex justify-center">
                              <QRCode  size={120} value={qrCode} />                        
                              </div>}
                      </div>


             </div>:
             <div className="w-full h-full flex justify-center items-center">
               <CircularProgress isIndeterminate color='green.300' />
             </div>
             
             }
            </ModalBody>
  

            {/* <ModalFooter>
              <Button variant='ghost'>Cancelar</Button>
              <Button colorScheme='blue' mr={3} onClick={onClose}>
                Aceptar
              </Button>
            </ModalFooter> */}
          </ModalContent>
        </Modal>
    )
  }
