import React from "react";

const IngredienteToggleButton = ({ titulo, avoid, handleClick}) => {
  const backgroundColor = avoid ? "bg-red-500" : "bg-blue-500";
  const textColor = avoid ? "text-gray-800" : "text-white";
  const textDecoration =  avoid ? "line-through" : "none";
  const textSuffix = avoid ? "" : "";

  return (
    <button
    type="button"
        onClick={handleClick}
      className={`px-4 py-3 text-base uppercase ${backgroundColor} ${textColor} ${textDecoration} rounded-md w-full h-full`}
    >
      {textSuffix}{titulo}
    </button>
  );
};

export default IngredienteToggleButton;
