import React, {useState, useEffect} from 'react'
import { FiFacebook } from "react-icons/fi";


import { Card, CardHeader, Image,Stack, Text, Heading, Divider,  CardBody, CardFooter, Button, ButtonGroup } from '@chakra-ui/react'

import { useCajaContext } from "../../providers/CajaProvider";








export default function SugerenciaCard( {data }) {

 


  return (

    <Card maxW='sm'>
  <CardBody>
    <Image
    objectFit="cover"
      src={data.img}
      alt={data.descripcion}
      borderRadius='lg'
    />
    <Stack mt='3' spacing='2'>
      <Heading size='md'>{data.nombre}</Heading>
      <Text>
        {data.descripcion}
      </Text>
      <Text color='blue.600' fontSize='xl'>
        {data.precio}
      </Text>
    </Stack>
  </CardBody>
  <Divider />
  <CardFooter>
    <ButtonGroup spacing='1'>
      <Button variant='solid' colorScheme='blue'>
        Agregar
      </Button>
    </ButtonGroup>
  </CardFooter>
</Card>
  )
}
