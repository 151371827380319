import React, {useState, useEffect, useRef} from 'react'

import Keyboard from 'react-simple-keyboard';


import 'react-simple-keyboard/build/css/index.css';
import '../styles/main.css'


const optionsKbdRuc = {
    theme: "hg-theme-default hg-theme-ios teclado-custom-ruc",
    layout: {
      default: [
        "7 8 9",
        "4 5 6",
        "1 2 3",
        "{bksp} 0 {enter}",
      ]
    },
    display: {
      "{enter}": "→",
      "{bksp}": "⌫"
    }
  }
//       "{enter}": "⏎",


export default function TecladoNumerico({onConfirm, message, mask=false}) {

    const [keyboardRef, setKeyboardRef] = useState(null)
    const kbRef = useRef(null)
    const [ruc, setRuc] = useState("")


    const handleSubmit = (event)=>{
        onConfirm(ruc)
        setRuc("")
        keyboardRef.clearInput();
    }

  return (
    <div className="flex flex-col gap-5 justify-center items-center">


        <p className="text-2xl">
           {message}
        </p>



        <div className="  flex flex-col gap-2">

        <div className=" p-2 bg-slate-300 rounded-lg h-16 flex justify-center items-center  relative overflow-hidden">
             <p className="absolute p-2 text-4xl">{  mask ? '*'.repeat(ruc.length)   :  ruc  }</p>
        </div>

 <div className="text-4xl font-light">
        <Keyboard
        
        // ref={kbRef}
        {...optionsKbdRuc}
        onKeyPress={(button) => {
            if (button === "{enter}") {
                handleSubmit();
            }
        }}
        
        onInit={(keyboard) => {
            setKeyboardRef(keyboard)
        }}
        
        onChange={  (input) => {
            // console.log("onChange input", input);
            setRuc(input)
        }  }
        // onKeyPress={this.onKeyPress}
        />
</div>

        </div>

     

    </div>
  )
}
