import React, {useState, useEffect} from 'react'
import { useNavigate } from "react-router-dom";
import { Progress, Button,  useToast, IconButton, Box, Stack,
Table,
Thead,
Tbody,
Tr,
Th,
Td,
} from '@chakra-ui/react'

import AperturaModal from "../components/caja/Apertura"
import CierreModal from "../components/caja/Cierre"
import CajaDetalleModal from "../components/caja/DetalleModal"
import ArqueoModal from "../components/caja/ArqueoModal"
import CheckAuthModal from "../components/auth/CheckAuth"


import QRCode from "react-qr-code";


import dayjs from "dayjs"

import  {useAuthContext } from '../providers/AuthProvider'
import  { useCajaContext }    from '../providers/CajaProvider'
import { gql, useMutation, useQuery, useLazyQuery} from "@apollo/client";
import { NumericFormat } from 'react-number-format';

import { FaSignOutAlt, FaCashRegister } from 'react-icons/fa';
import { IoHome, IoReload } from "react-icons/io5";


import {
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,
    StatArrow,
    StatGroup,
  } from '@chakra-ui/react'
import TablaCajas from "../components/TablaCajas";
import Footer from "../components/Footer";


const ABRIR_CAJA_MUTATION = gql`
    mutation($fullData:JSON!) {
        abrirCaja( fullData:$fullData){
            id
            estado
            monto
            createdAt
        }  
    }
`;

const CERRAR_CAJA_MUTATION = gql`
    mutation($fullData:JSON!) {
        cerrarCaja( fullData:$fullData){
            id
            estado
            monto
            createdAt
        }  
    }
`;



const ARQUEO_CAJA_MUTATION = gql`
    mutation($fullData:JSON!){
        arqueoCaja(fullData:$fullData)
    }
`;


const ABRIR_GAVETA_MUTATION = gql`
    mutation($autorizadoPor:Int!){
        abrirGaveta(autorizadoPor:$autorizadoPor)
    }
`;

const PRINT_RESUMEN_CAJA_MUTATION = gql`
    mutation($cajaId:Int!){
        printResumenCaja(cajaId:$cajaId)
    }
`;

const ME_QUERY = gql`
    query{
        meEmployee{
            id
            cedula
            firstName
            lastName
            avatar
        }
    }
`;

const CAJA_QUERY = gql`
    query($fullData:JSON!) {


        meEmployee{
            logs{
                message
                tags
                metadata
                createdAt
            }
        }

        cajas{
                id
                estado
                monto
                createdAt
                autorizadoPor{
                    firstName
                    lastName
                    cedula
                    avatar
                }
            }

        caja( fullData:$fullData){
            caja{
                id
                estado
                monto
                createdAt
                arqueos{
                    monto
                }
            }
            sumario{
                sum
                avg
                count
            }
            aditional
        }  
    }
`;



const CAJA_DETALLE_QUERY = gql`
    query($id:Int!) {
        cajaDetalleById( id:$id)
    }
`;




const BigBtn  =({titulo,handleTicketClick, darkmode, bgColor})=>{

   

    return <button  type="button"  onClick={handleTicketClick}
     className={ `border ${bgColor}  ${darkmode ? 'border-slate-400 text-slate-200' : 'border-slate-400 bg-gradient-to-b text-slate-700  selection:none  focus:none '}      
     rounded-md  flex justify-center place-items-center  h-full w-full
     flex-col  px-8 py-4 text-3xl  ` }  >
         <p className="text uppercase bold "> {titulo}  </p>
     </button>
 }


export default function Home() {

    const toast = useToast();
    const [modalApertura, setModalApertura] = useState({open:false})
    const [modalCierre, setModalCierre] = useState({open:false})
    const [modalCajaDetalle, setModalCajaDetalle] = useState({open:false})

    const [modalArqueo, setModalArqueo] = useState({open:false})

    const [modalCheckAuth, setModalCheckAuth] = useState({open:false})


    
    const {  fastLogout, setEmployee, employee } = useAuthContext();
    const [mounted, setMounted] = useState(false)

    const [dataCajaDetalle, setDataCajaDetalle] = useState(null)


    const {data:dataMe, loading:loadingMe, error:errorMe} = useQuery(ME_QUERY);

    const {data, loading, error, refetch, startPolling, stopPolling} = useQuery(CAJA_QUERY, {
        variables:{
            fullData:{
                sucursalId:2,
                employeeId: employee?.id
            }
        },
        fetchPolicy: "network-only"
    });

    const [ cajaDetalleQuery, {loading:loadingCajaDetalle, refetch:refetchCajaDetalle  } ]   = useLazyQuery(CAJA_DETALLE_QUERY);
    


    const { caja, setCaja   }   = useCajaContext();
    const navigate = useNavigate();

    const [ abrirCajaMutation, {loading:loadingApertura} ] = useMutation(ABRIR_CAJA_MUTATION);
    const [ cerrarCajaMutation, {loading:loadingCierre} ] = useMutation(CERRAR_CAJA_MUTATION);
    const [ abrirGavetaMutation  ] = useMutation(ABRIR_GAVETA_MUTATION);
    const [ arqueoMutation  ] = useMutation(ARQUEO_CAJA_MUTATION);
    const [ printResumenCajaMutation  ] = useMutation(PRINT_RESUMEN_CAJA_MUTATION);




    useEffect(() => {
      if(dataMe){
        setEmployee(dataMe.meEmployee)
      }
    }, [dataMe,setEmployee])
    


    useEffect(() => {
        // Marca la página como montada cuando se monta el componente
        setMounted(true);
        startPolling(15000);

        // Retorna una función de limpieza para desmontar la página
        return () => {
            stopPolling()
          // Marca la página como desmontada cuando se desmonta el componente
          setMounted(false);
        };
      }, []);


    useEffect(() => {
      if(data ){
        setCaja(data.caja)
      }
    }, [data])
    

    useEffect(() => {
      if(mounted)refetch()
    }, [mounted,refetch])
    



    const handleAbrirCaja = async (value)=>{

        if(!value)return;

        setTimeout(()=> secureApertura(value), 200)

        // const fullData = {
        //     // employeeId: employee.id,
        //     // sucursalId: 2,
        //     monto:value
        // }

        // abrirCajaMutation({variables:{fullData}}).then( ({data})=>{
        //     setCaja(data.abrirCaja);
        //     refetch();
        //     setModalApertura({open:false})
        // } )        
    }



    const handleCerrarCaja = async (value)=>{

        if(value){

            setModalCheckAuth({
                open:true,
                message:`Autenticación de cajero ${employee.firstName}`,
                initialUsername:employee.cedula,
                initialStep:1,
                handleAuth:()=>{
                    console.log("auth first step cierre caja")
                    setModalCheckAuth({
                        open:true,
                        message:`Autenticación de gerente`,
                        initialUsername:"",
                        initialStep:0,
                        bgColor:"bg-red-300",
                        handleAuth:(employee)=>{
                            // todo, chequear si rol es SUPERVISOR
                            console.log("handle auth roles", employee.roles)
    
                            if( employee.roles.map(i=>i.nombre).includes('SUPERVISOR') ){
                                console.log("si incluye rol de gerente")

                                ////////////////////////
                                const fullData = {
                                    cajaId: caja.caja.id,
                                    monto:value.montoCierre,
                                    arqueo:value.arqueo,
                                    balance:value.balance,
                                    autorizadoPorId:employee.id
                                }
                                    
                                cerrarCajaMutation({variables:{fullData}}).then( ({data})=>{
                                    setModalCheckAuth({open:false})
                                    setModalCierre({ open:false }) 
                                    setCaja(data.cerrarCaja);
                                    refetch();
                                } )
                                ////////////////////////        


                            }else{
                                console.log("no tiene rol de gerente")
                                setModalCheckAuth({open:false})
                                // setModalCheckAuth( p=>({...p, initialStep:0}) )
                                toast({
                                    position:'top-right',
                                    title: 'Error',
                                    description: "El usuario no tiene rol de gerente",
                                    status: 'error',
                                    duration: 3000,
                                    isClosable: true,
                                  })
                            }
    
    
                        }
                    })
    
                }
            })


        
        
        
        
        
        
        
        
        }
    }


    const cerrarCaja =()=>{
         cajaDetalleQuery({variables:{id:caja.caja.id}, fetchPolicy: "network-only" }).then( ({data})=>{
            setDataCajaDetalle(data.cajaDetalleById)
            setModalCierre({ open:true }) 
         }).catch( (err)=>{
                console.log(err);

            })
       
    }


    const viewCajaHandler = (idCaja)=>{
        cajaDetalleQuery({variables:{id:idCaja}, fetchPolicy: "network-only" }).then( ({data})=>{
            setDataCajaDetalle(data.cajaDetalleById)
         }).catch( (err)=>{
                console.log(err);

            })
        setModalCajaDetalle({ open:true }) 
    }




    const secureAperturaGaveta = ()=>{


        console.log("secure apertura gaveta", employee )

        setModalCheckAuth({
            open:true,
            message:`Autenticación de cajero ${employee.firstName}`,
            initialUsername:employee.cedula,
            initialStep:1,
            handleAuth:()=>{
                console.log("auth first step secureGaveta")
                setModalCheckAuth({
                    open:true,
                    message:`Autenticación de gerente`,
                    initialUsername:"",
                    initialStep:0,
                    bgColor:"bg-red-300",
                    handleAuth:(employee)=>{
                        // todo, chequear si rol es SUPERVISOR
                        console.log("handle auth roles", employee.roles)

                        if( employee.roles.map(i=>i.nombre).includes('SUPERVISOR') ){
                            console.log("si incluye rol de gerente")
                            abrirGavetaMutation({
                                variables:{
                                    autorizadoPor:employee.id
                                }
                            });
                            setModalCheckAuth({open:false})
                        }else{
                            console.log("no tiene rol de gerente")
                            setModalCheckAuth({open:false})
                            // setModalCheckAuth( p=>({...p, initialStep:0}) )
                            toast({
                                position:'top-right',
                                title: 'Error',
                                description: "El usuario no tiene rol de gerente",
                                status: 'error',
                                duration: 3000,
                                isClosable: true,
                              })
                        }


                    }
                })

            }
        })
    }





    const secureArqueo = (arqueo)=>{
        setModalCheckAuth({
            open:true,
            message:`Autenticación de cajero ${employee.firstName}`,
            initialUsername:employee.cedula,
            initialStep:1,
            handleAuth:()=>{
                console.log("auth first step secureGaveta")
                setModalCheckAuth({
                    open:true,
                    message:`Autenticación de gerente`,
                    initialUsername:"",
                    initialStep:0,
                    bgColor:"bg-red-300",
                    handleAuth:(employee)=>{
                        // todo, chequear si rol es SUPERVISOR
                        console.log("handle auth roles", employee.roles)

                        if( employee.roles.map(i=>i.nombre).includes('SUPERVISOR') ){
                            console.log("si incluye rol de gerente")

                            arqueoMutation({
                                variables:{
                                   fullData:{
                                        cajaId:caja?.caja?.id,
                                        arqueo,
                                        tipo: modalArqueo.tipo,
                                        autorizadoPorId:employee.id
                                   }
                                }
                            }).then(i=>{
                                refetch()
                                setModalArqueo({open:false})
                                toast({
                                    position:'top-right',
                                    title: 'Información',
                                    description: modalArqueo.tipo==="ALIVIO"?'Alivio de caja ingresado':'Arqueo de caja ingresado',
                                    status: 'success',
                                    duration: 3000,
                                    isClosable: true,
                                  })                                
                            });

                            setModalCheckAuth({open:false})
                        }else{
                            console.log("no tiene rol de gerente")
                            setModalCheckAuth({open:false})
                            // setModalCheckAuth( p=>({...p, initialStep:0}) )
                            toast({
                                position:'top-right',
                                title: 'Error',
                                description: "El usuario no tiene rol de gerente",
                                status: 'error',
                                duration: 3000,
                                isClosable: true,
                              })
                        }


                    }
                })

            }
        })
    }








    const secureApertura = (montoApertura)=>{
        setModalCheckAuth({
            open:true,
            message:`Autenticación de cajero ${employee.firstName}`,
            initialUsername:employee.cedula,
            initialStep:1,
            handleAuth:()=>{
                console.log("auth first step secureApertura")
                setModalCheckAuth({
                    open:true,
                    message:`Autenticación de gerente`,
                    initialUsername:"",
                    initialStep:0,
                    bgColor:"bg-red-300",
                    handleAuth:(employee)=>{
                        // todo, chequear si rol es SUPERVISOR
                        console.log("handle auth roles", employee.roles)

                        if( employee.roles.map(i=>i.nombre).includes('SUPERVISOR') ){
                            console.log("si incluye rol de gerente")



                            const fullData = {
                                // employeeId: employee.id,
                                // sucursalId: 2,
                                monto:montoApertura,
                                autorizadoPorId:employee.id
                            }
                    
                            abrirCajaMutation({variables:{fullData}}).then( ({data})=>{
                                setCaja(data.abrirCaja);
                                refetch().then(()=>{
                                    console.log("then refetch");
                                    navigate('/caja');
                                });
                                setModalApertura({open:false})

                                toast({
                                    position:'top-right',
                                    title: 'Información',
                                    description: 'Apertura de caja exitosa',
                                    status: 'success',
                                    duration: 1500,
                                    isClosable: true,
                                  })
                            } )   


                            // arqueoMutation({
                            //     variables:{
                            //        fullData:{
                            //             cajaId:caja?.caja?.id,
                            //             arqueo,
                            //             tipo: modalArqueo.tipo,
                            //             autorizadoPorId:employee.id
                            //        }
                            //     }
                            // }).then(i=>{
                            //     refetch()
                            //     setModalArqueo({open:false})
                            //     toast({
                            //         position:'top-right',
                            //         title: 'Información',
                            //         description: modalArqueo.tipo==="ALIVIO"?'Alivio de caja ingresado':'Arqueo de caja ingresado',
                            //         status: 'success',
                            //         duration: 3000,
                            //         isClosable: true,
                            //       })                                
                            // });

                            setModalCheckAuth({open:false})
                        }else{
                            console.log("no tiene rol de gerente")
                            setModalCheckAuth({open:false})
                            // setModalCheckAuth( p=>({...p, initialStep:0}) )
                            toast({
                                position:'top-right',
                                title: 'Error',
                                description: "El usuario no tiene rol de gerente",
                                status: 'error',
                                duration: 3000,
                                isClosable: true,
                              })
                        }


                    }
                })

            }
        })
    }









    if(loading)return <p>loading...</p>
  //  if(error)return <pre>{JSON.stringify(error, null, 2)} </pre>


  return (
    <div className="flex flex-col   w-full bg-neutral-700">

        {
            employee && <div className="flex flex-col  justify-center items-center bg-neutral-800 p-2">
                <p className="text-xl text-slate-100">{employee.firstName} {employee.lastName} ({employee.cedula})</p>
            </div>
        }

    <div className="flex justify-center   flex-1  gap-5  p-5 font-extralight  ">
        
        <AperturaModal isOpen={modalApertura.open} onClose={()=>setModalApertura({open:false})} 
        handleAbrirCaja={handleAbrirCaja}   />

        <CierreModal caja={caja} isOpen={modalCierre.open} onClose={()=>setModalCierre({open:false})} 
        dataCajaDetalle={dataCajaDetalle}
        loading={loadingCierre || loadingCajaDetalle}
        handleAbrirGaveta = { secureAperturaGaveta  }
        handlePrintResumenCaja = { ()=>printResumenCajaMutation({variables:{cajaId: caja?.caja.id }}) }        
        handleCerrarCaja={handleCerrarCaja}     />

        <CajaDetalleModal isOpen={modalCajaDetalle.open} onClose={()=>setModalCajaDetalle({open:false})} 
                dataCajaDetalle={dataCajaDetalle}
                loading = {loadingCajaDetalle}
                handleAbrirGaveta = { abrirGavetaMutation  }
                handlePrintResumenCaja = { (cajaId)=>printResumenCajaMutation({variables:{cajaId: cajaId }}) }    />


        <ArqueoModal  title={modalArqueo.title}  isOpen={modalArqueo.open} onClose={()=>setModalArqueo({open:false})} 
                        handleArqueo={ secureArqueo }  />


        <CheckAuthModal isOpen={modalCheckAuth.open} onClose={()=>setModalCheckAuth({open:false})} 
                    message={modalCheckAuth.message}
                    bgColor={modalCheckAuth.bgColor}
                    initialUsername={modalCheckAuth.initialUsername}
                    initialStep={modalCheckAuth.initialStep}
                    handleAuth={modalCheckAuth.handleAuth}
            />



        {/* <div className="grid grid-cols-2 grid-rows-2  gap-5  border border-neutral-500 rounded-xl p-5  bg-slate-300 h-full ">
            
            {
             caja && caja.caja?.estado==='ABIERTA' && <BigBtn titulo="Vender" handleTicketClick={()=>navigate("/caja") } />
            }


            <BigBtn titulo="Informes" handleTicketClick={ ()=>window.open("https://fastview.cloud/")  } />
        </div> */}




       <div className="flex gap-5 justify-center  border border-neutral-100 bg-neutral-200 rounded-xl p-5  w-2/3  ">

            <div className="border border-neutral-400 p-5 flex">
                {
                    caja && caja.caja?.estado==='ABIERTA' ?
                    <div className="flex  flex-col  w-full justify-between ">
                        {/*  <BigBtn titulo="Alivio" handleTicketClick={ ()=>setModalArqueo({open:true, tipo: "ALIVIO" }) } bgColor={'bg-green-200'}  />
                        <BigBtn titulo="Arqueo" handleTicketClick={ ()=>setModalArqueo({open:true, tipo: "CONTROL" }) } bgColor={'bg-blue-200'}  />
                    <BigBtn  bgColor={'bg-orange-300'}   titulo="Cierre" handleTicketClick={ cerrarCaja }  /> */}

                        <Button variant={"solid"} colorScheme="green"  paddingX={16} paddingY={16} size={"lg"}  disabled={caja?.caja?.estado!=='ABIERTA'} onClick={ ()=>navigate("/caja") }>VENDER</Button>

                        <div className="flex  flex-col gap-5  w-full justify-evenly">
                            <Button variant={"solid"} colorScheme="blue"  paddingX={16} paddingY={8} size={"lg"}  onClick={ secureAperturaGaveta }>ABRIR GAVETA</Button>
                            <Button variant={"solid"} colorScheme="blue"  paddingX={16} paddingY={8} size={"lg"}  onClick={ ()=>setModalArqueo({open:true, tipo: "ALIVIO", title:'Alivio de caja' }) }>ALIVIO</Button>
                            <Button  variant={"solid"} colorScheme="blue"  paddingX={16} paddingY={8} size={"lg"}   onClick={ ()=>setModalArqueo({open:true, tipo: "CONTROL" })  }>ARQUEO</Button>
                            <Button   variant={"solid"} colorScheme="red"  paddingX={16} paddingY={8} size={"lg"} isLoading={ loadingCajaDetalle }  onClick={ cerrarCaja }>CIERRE</Button>
                        </div>
                    </div>
                    :
                    <div> 
                        <Button variant={"solid"} colorScheme="green"  paddingX={16} paddingY={8} size={"lg"}  onClick={ ()=> setModalApertura({ open:true })  }>Apertura de Caja</Button>
                        {/* <BigBtn titulo="Apertura de caja" handleTicketClick={ ()=> setModalApertura({ open:true })  } /> */}
                    </div>
                }
            </div>


            <div className="border border-neutral-400 p-5 flex-1 flex flex-col gap-5 w-full justify-between">
                {
                    caja && caja.caja?.estado==='ABIERTA' ? <div className="flex flex-col gap-5">
                        <div className="flex  justify-center items-center gap-8"   >
                            <h1 className="uppercase text-2xl"> {`Caja #${caja?.caja?.id}`} </h1>
                            <IconButton
                                        aria-label="Cerrar sesión"
                                        icon={<IoReload />}
                                        size="md"
                                        colorScheme="blue" // Aquí se establece el color rojo
                                        onClick={()=>refetch()}
                            />   
                            <QRCode  size={100} value={  `${process.env.REACT_APP_FV_URL}/dashboard/cajas/${caja.caja?.id}` } />
                        </div>

                        <p>{ dayjs(caja.caja.createdAt).format('DD/MMMM/YYYY HH:mm')   }</p>
                        <p>Estado: {caja.caja.estado}</p>


                        <Box display={'flex'} gap={5}   p='3' borderWidth='1px' borderRadius='lg' overflow='hidden' color={'gray.100'} bgColor={'blue.800'} borderColor={'gray.100'} >
                       
                            {/* <Stat  >
                                <StatLabel textAlign="center">Monto</StatLabel>
                                <StatNumber textAlign="center"> <NumericFormat displayType="text" value= {caja.sumario.sum} thousandSeparator="," />  </StatNumber>
                            </Stat> */}

                            <Stat    >
                                <StatLabel textAlign="center">Tickets</StatLabel>
                                <StatNumber textAlign="center"> <NumericFormat displayType="text" value= {caja.sumario.count} thousandSeparator="," />  </StatNumber>
                            </Stat>

                            <Stat  >
                                <StatLabel textAlign="center">Agrandado</StatLabel>
                                <StatNumber textAlign="center"> <NumericFormat displayType="text" value= {caja.aditional.contadorAgrandado} thousandSeparator="," />/<NumericFormat displayType="text" value= {caja.aditional.contadorAgrandado+caja.aditional.contadorNoAgrandado} thousandSeparator="," /> </StatNumber>
                               <StatHelpText textAlign={'center'}>
                                {/* <StatArrow type='increase' /> */}
                                <NumericFormat displayType="text" value= {caja.aditional.contadorAgrandado/(caja.aditional.contadorAgrandado+caja.aditional.contadorNoAgrandado)*100} decimalScale={1} thousandSeparator="," />%
                                </StatHelpText>
                            </Stat>

                            {/* <Stat    >
                                <StatLabel textAlign="center">Alivios</StatLabel>
                                <StatNumber textAlign="center"> <NumericFormat displayType="text" value= { caja.caja?.arqueos.reduce( (acc,arqueo)=>acc+arqueo.monto, 0) }  thousandSeparator="," />  </StatNumber>
                            </Stat> */}
                         
                        </Box>
                        <Button onClick={()=>navigate(`/cajaDetalle/${caja.caja?.id}`)}>Ver detalles</Button>  

                    </div>:<div className="flex flex-col  flex-1">
                            <p>Cajas anteriores</p>
                            <div className="overflow-auto relative    flex-1">
                                <div className="absolute   w-full">
                                    {  data?.cajas && <TablaCajas viewCajaHandler={viewCajaHandler} data={data.cajas}  /> }
                                 </div>
                            </div>
                        </div>
                }
                </div>
       </div>



       <div className="flex flex-col justify-end  w-1/3 rounded-xl overflow-hidden">


                    <div className="flex flex-col gap-5 h-full justify-between">

                    {/* <div className="flex flex-col gap-5 justify-center items-center">
                    {
             caja && caja.caja?.estado==='ABIERTA' && <BigBtn disabled={ caja?.caja?.estado!=='ABIERTA' }  titulo="Vender" bgColor={'bg-green-500'}  handleTicketClick={()=>navigate("/caja") } />
            }
            <BigBtn titulo="Abrir gaveta" bgColor={'bg-blue-300'}  handleTicketClick={ secureAperturaGaveta  } />
                    </div> */}

                        <div className="bg-slate-300 flex-1  relative overflow-auto">
                            <p className="text-center my-2">Eventos</p>
                            <div className="absolute">

                                <Table size={'sm'}>
                                    <Thead>
                                        <Tr>
                                            <Th>Fecha</Th>
                                            <Th>Evento</Th>
                                            <Th>Tags</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {
                                        data.meEmployee?.logs.map((log)=> <Tr>
                                                <Td>{dayjs(log.createdAt).format('DD/MMM HH:mm')}</Td>
                                                <Td>{log.message}</Td>
                                                <Td>
                                                    {
                                                        log.tags.map((tag)=> <span className="bg-slate-500 text-slate-100 px-2 rounded-full mx-1">{tag}</span> )
                                                    }
                                                </Td>
                                            </Tr> )
                                        }
                                    </Tbody>
                                </Table>


                            </div>
                        </div>
                    </div>
       </div>
    </div>

    <Footer />

</div>
  )
}
