import React, { useEffect, useRef, useContext } from "react";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

import { ComandaContext } from "./ComandaContext";
import IngredientePill from "./IngredientePill";
import VentaDetalle from "./VentaDetalle";




dayjs.extend(duration);





const OrdersTable = ({ ventas, openCarrilModal }) => {

  const containerRef = useRef();

  const {  ventaCursor } = useContext(ComandaContext);


  const handleRowClick = (index) => {
    const ventaElements = containerRef.current.querySelectorAll('.venta-detalle');
    const venta = ventaElements[index];
    if (venta) {
      venta.scrollIntoView({ behavior: 'instant', block: 'start' });
    }
  };


  // const handleRowClick = (index) => {
  //   const ventaElements = containerRef.current.querySelectorAll('.venta-detalle');
  //   const venta = ventaElements[index];
  //   if (venta) {
  //     const marginTop = 50; // Define el margen adicional en píxeles
  //     const spacer = document.createElement('div');
  //     spacer.style.height = `${marginTop}px`;
  //     venta.parentElement.insertBefore(spacer, venta);
  //     venta.scrollIntoView({ behavior: 'smooth', block: 'start' });
  //   }
  // };
  


  useEffect(() => {
    handleRowClick(ventaCursor)
  }, [ventaCursor])
  
  

  return (
    <div className="grid grid-cols-1  p-3   "  ref={containerRef}>
      {
        ventas.map((venta, index) => <div key={venta.id} className="p-3 venta-detalle">
          <VentaDetalle  openCarrilModal={openCarrilModal} venta={venta} /></div>
        )
      }
    </div>
  );
};

export default OrdersTable;
