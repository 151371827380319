import React from 'react'

import IngredientePill from "../comanda/IngredientePill";

const variatorSelection = (vd, productoItemId)=>{


  console.log(`
-----------.>>>>>>>>>>>>>>>>>  
  `, vd)


    try{
        //        console.log("vd", vd)
        //        console.log("vd filtrado", vd.producto.productoDetalle.map(i=>i.id)  )
        const productoItem  = vd.producto.productoDetalle.find(i=>i.id===productoItemId);
        // console.log("vd filtrado productoItem", productoItem )
        // console.log("------->>>>   productoItem.receta.variator.items", productoItem.receta.variator.items )

        const variatorsDeLaRecetaDelProductoItem = vd.producto.variator?.botoneras.filter(i=>{
             // console.log("i.target", i.target, productoItem.receta.id)
              return ( i.target.some(j=>{
               // console.log("some", j)
                return j.receta===productoItem.receta.id
              }) );
        }   );
      console.log("variatorsDeLaRecetaDelProductoItem", productoItem.receta.nombre,   variatorsDeLaRecetaDelProductoItem);


        const variatorsReceta = productoItem.receta?.variator?.items;
         console.log("------->>>>  VARIATORS DE LA RECETA", variatorsReceta)

        const customizations = [];

        variatorsDeLaRecetaDelProductoItem?.map((i)=>{
                //console.log("VARIATOR DEL PRODUCTO",  i)
                let itemsVs = vd.variatorValues?.items;
                if(!itemsVs) itemsVs=vd.variatorValues;

                const vs=  itemsVs.find(j=>j.name===i.name)
               console.log("----------- ---------->   vs", vs  )

               const valorSeleccionado = variatorsReceta.find( vrI=>  vrI.name===i.target[0].name    ).options[vs.selected];

               customizations.push({
                name: i.target[0].name,  
                valor: valorSeleccionado.name
               })
        })

        return customizations;
    }catch(e){
      console.log("error", e)
      return [];
    }
}


export default function ProductoCard({vd}) {



  return (
    <div className="flex flex-col  ">
          <div className="flex flex-col flex-1  ">
            {vd.producto.productoDetalle.filter(i=>i.principal).map((item, index) => (
              <div className="flex  flex-1  border-b border-neutral-200 p-1" key={index}>
                <div className="flex-1">
                <div className="flex justify-between">
                      <div className="   ">
                        <span className="">{item.receta.nombre}</span> {vd.cantidad > 1 && <span> (*{vd.cantidad})</span>}
                      </div>

                      <div className="text-base font-bold">
                        {  variatorSelection( vd, item.id ).map(i=>i.valor).join(", ")   }
                      </div>
                  </div>

                  <div className="space-x-1">



                    {item.receta.recetaDetalle.filter(i=> vd?.avoidIngredients?.items?.includes(`${item.receta.id}-${i.sku.sku}`)   ).map((item2, index) => (




                      <IngredientePill
                        key={index}
                        titulo={`${item2.sku.shortName || item2.sku.nombre}`}
                        avoid={ vd?.avoidIngredients?.items.includes(`${item.receta.id}-${item2.sku.sku}`) }
                        // avoid={Math.random() < 0.3}
                      />


                    ))}
                  </div>
                </div>
              </div>
            ))}                    
          </div>



        <div className="flex flex-col flex-1 ">
          {vd.producto.productoDetalle.filter(i=>!i.principal).map((item, index) => (
            <div className="flex  flex-1 p-1 border-b border-neutral-200 " key={index}>
              <div className="flex-1">


                <div className="flex justify-between ">
                    <div className="  flex-1">
                        <span className="">{item.receta.nombre}</span> {vd.cantidad > 1 && <span> (*{vd.cantidad})</span>}
                    </div>

{/* <pre>
  {JSON.stringify(vd)}
</pre> */}
                    <div className="text-base font-bold">
                      {  variatorSelection( vd, item.id ).map(i=>i.valor).join(", ")   }
                    </div>
                </div>



                <div className="space-x-1">
                  {item.receta.recetaDetalle.filter(i=> vd?.avoidIngredients?.items?.includes(`${item.receta.id}-${i.sku.sku}`)   ).map((item2, index) => (


                    <IngredientePill
                      key={index}
                      titulo={`${item2.sku.shortName || item2.sku.nombre }`}
                      avoid={ vd?.avoidIngredients?.items.includes(`${item.receta.id}-${item2.sku.sku}`) }
                      // avoid={Math.random() < 0.3}
                    />
    
                  ))}
                </div>
              </div>
            </div>
          ))}                    
        </div>  
</div>
  )
}
