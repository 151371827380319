import React, {useEffect,useState} from 'react';
import ReactPlayer from 'react-player'

import {  useQuery} from "@apollo/client";

import { VENTAS_LLAMADOR_QUERY } from "../graphql/querys"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faCog, faArrowUp, faArrowDown, faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';


import { useNavigate } from "react-router-dom";





const Llamador = () => {
    const navigate = useNavigate();

  const [ventas, setVentas] = useState([])
  
  const { loading, error, data, refetch, startPolling, stopPolling } = useQuery(VENTAS_LLAMADOR_QUERY, {
    fetchPolicy:'no-cache',
   // pollInterval: 2500,
  } );









  useEffect(() => {

    startPolling(2500);

    return () => {
      stopPolling()
    }
  }, [stopPolling, startPolling])




  useEffect(() => {
    if(data?.ventasLlamador)setVentas(data.ventasLlamador)
  }, [data, setVentas])


  if(loading) return <p>Loading...</p>
  if(error) return <p>Error :{error.toString()}</p>


  if(data?.ventasLlamador.length===0) return   <div className="w-full h-full bg-yellow-500" onClick={()=>navigate("/home") }>
    <div className="absolute top-5 right-5">PRE</div>
    <ReactPlayer muted width={'100%'} height={'100%'} loop  playing url='https://mostaza-assets.s3.sa-east-1.amazonaws.com/totem/mtz-video1-low.mp4' />
  </div>

  return (
    <div className="h-screen w-screen flex bg-neutral-900 overflow-hidden">


<div className="absolute bottom-10 right-10">
            <button className="w-12 h-12 rounded-full bg-red-500    "   onClick={()=>navigate("/home") }  >
             <FontAwesomeIcon icon={faTimes} size="2x" />
            </button>
            {/* <p className="text-sm font-medium">Salir</p> */}
         </div>


            <div className="bg-slate-800 p-5">
                    
                    <p className="p-5 text-4xl bg-slate-300 text-center">
                        <p>Estamos preparando </p>
                        <p>tu pedido...</p> 
                    </p>

                    <div className="flex flex-col gap-5 justify-center p-5">
                    {
                        ventas.filter(i=>['zona', 'comanda'].includes(i.estadoActual.estado)).map((v)=>
                        <div  className="text-white text-4xl font-bold text-center p-3 border border-gray-400"  >
                          <div>#{v.id}</div>
                          <div className="text-4xl">{v.llamador}</div>
                        </div>
                        )
                    }
                    </div>

            </div>

            <div className="bg-neutral-500 flex-1 p-5">
                      <div className="p-5 text-4xl bg-slate-300 flex flex-col justify-center text-center">
                        <p>Tu pedido esta listo!</p>
                        <p>Vení a retirarlo</p>
                    </div>

                    <div className="flex flex-col gap-5 justify-center p-5">
                    {
                        ventas.filter(i=>i.estadoActual.estado==='llamador').map((v)=><div  className="text-white text-4xl font-bold text-center p-3 border border-gray-400 "  >
                          <div>#{v.id}</div>
                          <div className="text-4xl">{v.llamador}</div>
                        </div>)
                    }
                    </div>              
            </div>



    </div>
  );
};

export default Llamador;
