import React, { useState, createContext, useEffect } from 'react';



export const ComandaContext = createContext();

export const ComandaProvider = ({ children }) => {
  const [comandas, setComandas] = useState([]);
  const [comandaSeleccionada, setComandaSeleccionada] = useState(null);

  const [ventas, setVentas] = useState([])
  const [selectedVenta, setSelectedVenta] = useState(0)
  const [ventaCursor, setVentaCursor] = useState(0)


  const [proyeccionComanda, setProyeccionComanda] = useState(null)



  const actualizarComandaSeleccionada = (id) => {
    const comanda = comandas.find((comanda) => comanda.id === id);
    setComandaSeleccionada(comanda);
  };


  useEffect(() => {
        setSelectedVenta(ventas[ventaCursor]?.id)
  }, [ventaCursor,setSelectedVenta, ventas])
  


    const changeCursorById = (id) => {
        const index = ventas.findIndex((venta) => venta.id === id)
        setVentaCursor(index)
    }


 //  nextVenta and prevVenta are used to navigate between the different ventas id's, use a ventaCursor and then set selectedVenta with the .id pointed by ventaCursor
    const nextVenta = () => {
        if (ventaCursor < ventas.length - 1) {
            setVentaCursor(ventaCursor + 1)
        }else{
            setVentaCursor(0)
        }
    }

    const prevVenta = () => {
        if (ventaCursor > 0) {
            setVentaCursor(ventaCursor - 1)
        }else{
            setVentaCursor(ventas.length - 1)
        }
    }

    



  return (
    <ComandaContext.Provider value={{ 
        selectedVenta,setSelectedVenta,
        ventas, setVentas,
        comandas, setComandas, comandaSeleccionada, actualizarComandaSeleccionada,
        nextVenta, prevVenta, changeCursorById,
        ventaCursor,
        proyeccionComanda, setProyeccionComanda
        }}>
      {children}
    </ComandaContext.Provider>
  );
};
