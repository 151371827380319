import React, {useState, useEffect} from 'react'
import MenuCategoria from "./MenuCategoria"



// const categorias = [
//     {id: 1, titulo: "Megas", img:"/images/3521-removebg.png",
//     list: [ 


//       {id: 11, precio:42000,  titulo: "PREMIUM BAC DOBLE"},
//       {id: 12,precio:34000,  titulo: "PREMIUM BAC"},
//       {id: 13, precio:45000, titulo: "BOOM B&C DOBLE"},
//       {id: 14, precio:37000,  titulo: "BOOM B&C"},

//       {id: 111, titulo: "Deluxe", precio:31000},
//       {id: 112, titulo: "Deluxe Bac", precio:35000},
//       {id: 113, titulo: "Deluxe Bac Doble", precio:43000},
//       {id: 114, titulo: "Imperial Doble", precio:39000},  

//       {id: 211, titulo: "Imperial", precio:23000},
//       {id: 212, titulo: "Deluxe Doble", precio:39000},
//       {id: 213, titulo: "CUARTO", precio:29000},
//       {id: 214, titulo: "CUARTO Doble", precio:37000},  

//       {id: 214, titulo: "Imperial", precio:31000},  



//   ]
//   },
//     {id: 2, titulo: "Promo",   img:"/images/1617839538606_1617839538604.jpg"    ,  list: [

//       {id: 21, titulo: "Olimpiadas Asu2022"},
//       {id: 22, titulo: "Deluxe JR"},


//     ]  },
//     {id: 3, titulo: "Pollo",  list: []  },
//     {id: 4, titulo: "Light",  list: []  },
//     {id: 5, titulo: "Cajitas",  list: []  },
//     {id: 6, titulo: "Papas",  list: []  },
//     {id: 7, titulo: "Bebidas",  list: []  },
//     {id: 8, titulo: "Postres",  list: []  },
//     {id: 9, titulo: "BR - CAFE",  list: []  },
//     {id: 10, titulo: "BR - TOST/PANI",  list: []  },
//     {id: 11, titulo: "BR - DESAY/MERI",  list: []  },
// ]


export default function MenuCategorias( props ) {

  const {setCurrentCategoryList, categorias} = props;

  const [currentCategory, setCurrentCategory] = useState(null)


  useEffect(() => {

      const defaultCategory = categorias?.find(i=>i.name.toLowerCase()==="megas");
      console.log("defaultCategory", defaultCategory)
      if(defaultCategory){
        setCurrentCategory(defaultCategory);
        setCurrentCategoryList(defaultCategory.sellCategoryProducto);
      }

  }, [categorias, setCurrentCategoryList])
  



  const handleClick = (i)=>{

    console.log("handle click categoria", i )

    setCurrentCategory(i);
    setCurrentCategoryList(i.sellCategoryProducto);
  }

  
  return (
    <div className="grid grid-cols-3  grid-rows-4  gap-3  h-full">
        
        {
            categorias.map( i=> <MenuCategoria active={ i.id===currentCategory?.id}  handleClick={()=>handleClick(i)}    key={i.id} titulo={i.name} img={i.img} />)
        }


    </div>
  )
}
