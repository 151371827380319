import React, { useEffect, useState }  from "react";
import {  format } from 'date-fns'
import Buttons from "./Buttons"
import { useAuthContext } from "../../providers/AuthProvider";

const Header = () => {

    const [value, setValue] = useState(null);

    const { employee } = useAuthContext();


    useEffect(() => {
      const interval = setInterval(() => setValue( 
            format(new Date(), 'dd MMMM HH:mm')
         ), 1000);
  
      return () => {
        clearInterval(interval);
      };
    }, []);

  return (
    <div className="flex w-full bg-gray-800 h-16 text-white gap-5 ">


      {/* <div className="absolute bottom-1 left-1">
        <p>1.0.1</p>
      </div> */}


      {/* <div className="absolute bottom-12 left-12 p-3 bg-slate-200 text-slate-900 w-48 opacity-80">
        <p className="">Consideraciones:</p>
        <p> Las flechas arriba y abajo hacen scroll, por default queda seleccionado el primer item, siempre hay un item seleccionado </p>
      </div> */}


      <div className="text-2xl font-bold flex items-center">
        <p> </p>
      </div>


      <div className="flex-1 flex  bg-neutral-800 justify-between ">
            <div className="flex items-center justify-self-start  gap-2 h-full ">

                <div className="text-2xl font-bold  bg-green-500 w-20 flex justify-center h-full place-items-center relative">
                  <p className="absolute top-0 text-xs uppercase">LISTOS</p>
                  <p className="text-4xl">0</p>
                </div>

                <div className="flex justify-center  text-2xl font-bold  bg-blue-400  w-20 h-full place-items-center ">
                  <p className="absolute top-0 text-xs uppercase">HELADOS</p>
                    <p className=" text-4xl">0</p>
                </div>
            
            </div>

            <div className="text-sm font-light    flex   place-items-center px-4 ">
              <div className="p-2 text-xl ">
                  <p>{value}</p>
              </div>
            </div>

            <div className="flex justify-center items-center  flex-1">
                  <p className="text-xl">
                  { `${employee?.firstName} ${employee?.lastName} (${employee?.cedula})` }
                  </p>
            </div>


            <div  className="" >
                <Buttons      />
            </div>

      </div>

    </div>
  );
};

export default Header;
