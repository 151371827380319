import React, {useEffect,useState} from 'react';
import Header from '../components/zona/Header';
// import Buttons from '../components/zona/Buttons';
import OrdersTable from '../components/zona/Pedidos';
import { gql, useQuery, useMutation} from "@apollo/client";

import { VENTAS_ZONA_QUERY } from "../graphql/querys"


// const data = [
//     { descripcion: "Hamburguesa simple", preferencias: "sin lechuga", demora: "02:16" },
//     { descripcion: "Hamburguesa Deluxe", preferencias: "sin tomate", demora: "05:34" },
//     // { descripcion: "Mega Pollo", preferencias: "sin condimentos", demora: "06:12" },
//     // { descripcion: "Mega doble bacon", preferencias: "sin tomate", demora: "04:56" },
//     // { descripcion: "Bacon Imperial", preferencias: "sin lechuga", demora: "08:07" },
//     // { descripcion: "Mega vegetariana", preferencias: "sin queso", demora: "03:23" },
//     // { descripcion: "Hamburguesa clasica", preferencias: "sin cebolla", demora: "07:09" },
//     // { descripcion: "Hamburguesa especial", preferencias: "con todo", demora: "09:51" },
//   ];
  


  const customStyles = {
    content: {
      padding: '0px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };




  const CONFIRMAR_ZONA_MUTATION = gql`
  mutation($ventaId:Int) {
    confirmarZona( ventaId:$ventaId)
  }
`;


const PRINT_TICKET_COMANDA_MUTATION = gql`

    mutation($ticketId:Int!) {
      printComanda( ticketId:$ticketId)
    }

`;




const Zona = () => {



  const [ printTicketComandaMutation  ] = useMutation( PRINT_TICKET_COMANDA_MUTATION );
  const [ confirmarZonaMutation ] = useMutation(CONFIRMAR_ZONA_MUTATION);
  const [ventas, setVentas] = useState([])
  
  const { loading, error, data, refetch, startPolling, stopPolling } = useQuery(VENTAS_ZONA_QUERY, {
    fetchPolicy:'no-cache',
   // pollInterval: 2500,
  } );







  const confirmZona = (ventaId) => {

    confirmarZonaMutation({variables:{ ventaId }})
    .then((res)=>{

      printTicketComandaMutation({variables:{ ticketId: ventaId}}).catch((err)=>{
        console.log("err printTicketComandaMutation",err)
      });


      refetch().then(()=>{
      });
      console.log(res)
    })
    .catch((err)=>{
      console.log(err)
    })

  }




  useEffect(() => {

    startPolling(2500);

    return () => {
      stopPolling()
    }
  }, [stopPolling, startPolling])




  useEffect(() => {
    if(data?.ventasZona)setVentas(data.ventasZona)
  }, [data, setVentas])


  if(loading) return <p>Loading...</p>
  if(error) return <p>Error :{error.toString()}</p>

  return (
    <div className="h-screen w-screen flex flex-col">
      <Header /> 

      <div className="flex flex-1 bg-slate-400">

           <OrdersTable data={ventas}  confirmHandle = {confirmZona}  /> 

      {/* <div className="flex-1 bg-gray-800 overflow-auto relative ocultar-scroll ">
                          <div className="absolute w-full">
                               <OrdersTable data={ventas}  confirmHandle = {confirmZona}  /> 
                          </div>
              </div>
      </div> */}
      </div>


    </div>
  );
};

export default Zona;
