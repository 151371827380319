import React, {useState, useEffect} from 'react'
import Modal from 'react-modal';
import { FiSearch } from "react-icons/fi";

import numeral from 'numeral';
import { useCajaContext } from "../../providers/CajaProvider";
import { gql, useMutation, useQuery, useLazyQuery} from "@apollo/client";
import NumPad from 'react-numpad';

import {
    CircularProgress
  } from '@chakra-ui/react'

import TecladoMonto from "../TecladoMonto"


import dayjs from 'dayjs'



const GENERATE_VENTA_MUTATION = gql`

    mutation($fullData:JSON!) {
        generateVenta( fullData:$fullData){
            id
        }  
    }

`;


const PRINT_TICKET_MUTATION = gql`

    mutation($ticketId:Int!) {
        printTicket( ticketId:$ticketId)
    }

`;





const WaitingPos = ({total})=>{
   return  <div className="flex flex-col justify-between w-full gap-5">
    <div className="bg-blue-200 p-2 rounded-xl">
        <p>TOTAL:</p>
        <p className="font-bold text-2xl text-right">{numeral( total  ).format('0,0')}</p>
    </div>



    <div className="bg-blue-200 p-2 rounded-xl flex-1 items-center justify-center flex">
         <div className="flex flex-col gap-3  justify-center items-center">
            <CircularProgress  isIndeterminate   size='80px' />
            <p>Aguardando transacción electrónica...</p>
         </div>
    </div>


</div>
}




export default function TicketModal( {isOpen,onRequestClose, style, handleGenerate, waiting }) {


    const contextoCaja = useCajaContext();
    const {efectivo, setEfectivo, getTotal, ventaInicio, clearCaja, formaPago } =contextoCaja;

    const [ generateVentaMutation, {loading:loadingMutation} ] = useMutation(GENERATE_VENTA_MUTATION);
    const [ printTicketMutation  ] = useMutation( PRINT_TICKET_MUTATION );



    // const handleGenerate = async ()=>{

    //     generateVentaMutation({
    //         variables:{
    //             fullData: contextoCaja.ventaPayload()
    //         }
    //     })
    //     .then(res=>{
            
    //         console.log("Ticket.jsx  venta generada", res)

    //         if(res.data.generateVenta.id){
    //             printTicketMutation({
    //                 variables:{
    //                     ticketId: res.data.generateVenta.id
    //                 }
    //             })
    //             const now = dayjs();
    //             onRequestClose( {
    //                 id: res.data.generateVenta.id,
    //                 vuelto: efectivo-getTotal(),
    //                 tiempo: now.diff( ventaInicio , 's')
    //             });
    //             clearCaja();
    //         }else alert("error, no ventaId")
    //     })
    //     .catch(e=>console.log("error en venta", e))
    // }






  return (


    <Modal
    isOpen={isOpen}
    onRequestClose={ ()=>onRequestClose(null)}
    style={style}
    contentLabel="Ruc"
  >
    {/* <!-- Main modal --> */}
    <div className="   w-full  h-full md:h-auto  " style={{width:'800px', height:"600px"}}>
            {/* <!-- Modal content --> */}
            <div className="relative  p-3 shadow dark:bg-gray-700 flex flex-col h-full">
                {/* <!-- Modal header --> */}

                <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                        TICKET
                    </h3>
                    <button type="button" 
                    onClick={  ()=>onRequestClose() }
                    className="text-gray-400  bg-gray-200
                      rounded-full text-sm p-1.5 ml-auto inline-flex items-center"
                      >
                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                </div>



                {/* <!-- Modal body --> */}
                <div className="space-y-6   p-5  flex-1 overflow-scroll "  >

                    {  !waiting ?
                   <div className=" relative flex flex-col gap-5  h-full">

                    {  formaPago===4 && <div className="text-2xl text-center">Pago con efectivo y POS</div>}

                   <div className="flex gap-5 h-full">


                   { (formaPago===1 || formaPago===4)  && 
                  <div className="  flex justify-end">
                     <TecladoMonto defaultAmount={0} message={null}
                   onChangeHandler={ (value)=>{ setEfectivo(value) } }
                   onConfirm={ (value)=>{
                    setEfectivo(value)
                   } } /></div>
                }

    

                    { (formaPago===1 || formaPago===4) ?
                            <div className="flex flex-col justify-between w-full">
                                    {
                                        formaPago===1 ?
                                        <>
                                                <div className="flex flex-col gap-1 flex-1 ">
                                                        <div className="bg-blue-200 p-2 rounded-xl">
                                                            <p>TOTAL:</p>
                                                            <p className="font-bold text-2xl text-right">{numeral( getTotal()  ).format('0,0')}</p>
                                                        </div>

                                                        <div className="bg-blue-200 p-2 rounded-xl">
                                                            <p>EFECTIVO:</p>
                                                            <p className="font-bold text-2xl text-right">{numeral( efectivo  ).format('0,0')}</p>
                                                        </div>
                                                        <div className="bg-green-200 p-2 rounded-xl">
                                                            <p>VUELTO:</p>
                                                            <p className="font-bold text-3xl text-right">{numeral( efectivo-getTotal()>0 ? efectivo-getTotal() : 0 ).format('0,0')}</p>
                                                        </div>

                                                        <div className=" mt-5 justify-end flex ">

                                                             <button 
                                                                className="bg-green-200 text-black font-bold py-5 px-8 rounded text-2xl"
                                                                onClick={ ()=>setEfectivo( getTotal() )  } >MONTO EXACTO</button>
                                                        </div>

                                                </div>
                                            
                                                    { contextoCaja.pedido.length >0 &&  <div className="flex justify-center items-center"> <button disabled={loadingMutation || (getTotal()>efectivo) } 
                                                        className="bg-blue-500 disabled:bg-gray-400 text-white font-bold py-5 px-8 rounded text-2xl"
                                                        onClick={ handleGenerate } >Generar ticket</button></div>}
                                    </>
                                    :
                                    <>
                                    <div className="flex flex-col gap-1 flex-1 ">
                                            <div className="bg-blue-200 p-2 rounded-xl">
                                                <p>TOTAL:</p>
                                                <p className="font-bold text-2xl text-right">{numeral( getTotal()  ).format('0,0')}</p>
                                            </div>
                                            <div className="bg-blue-200 p-2 rounded-xl">
                                                <p>EFECTIVO:</p>
                                                <p className="font-bold text-2xl text-right">{numeral( efectivo  ).format('0,0')}</p>
                                            </div>
                                            <div className="bg-blue-400 p-2 rounded-xl">
                                                <p>A PAGAR CON POS:</p>
                                                <p className="font-bold text-3xl text-right">{numeral( (getTotal()-efectivo)<0?0:getTotal()-efectivo ).format('0,0')}</p>
                                            </div>

                                            <div className="  flex-1 flex flex-col   justify-end gap-3">
                                            {   
                                                (  (getTotal()-efectivo)===getTotal()   )  &&
                                                <div className="bg-red-400 p-2 rounded-xl animate-[pulse_1s_ease-in-out_infinite]">
                                                    <p className="font-bold text-xl text-center"> El monto en efectivo debe ser mayor a 0 </p>
                                                </div>
                                            }

                                            {   
                                                (  (getTotal()-efectivo)===getTotal()   )  &&
                                                <div className="bg-blue-200 p-2 rounded-xl  animate-[pulse_1s_ease-in-out_infinite]">
                                                    <p className="font-bold text-xl text-center">⬅️ Ingrese el monto a pagar en efectivo </p>
                                                </div>
                                            }


                                            {   
                                                (  (getTotal()-efectivo)<=0  )  &&
                                                <div className="bg-red-400 p-2 rounded-xl animate-[pulse_1s_ease-in-out_infinite]">
                                                    <p className="font-bold text-xl text-center"> El pago con POS debe ser mayor a 0 </p>
                                                </div>
                                            }
                                            </div>


                                    </div>
                                
                                        { (contextoCaja.pedido.length >0 && getTotal()-efectivo>0  && efectivo>0 ) &&  <div className="flex justify-center items-center"> 
                                        <button disabled={loadingMutation  } 
                                            className="bg-blue-500 disabled:bg-gray-400 text-white font-bold py-5 px-8 rounded text-2xl"
                                            onClick={ handleGenerate } >Generar ticket</button></div>}
                                     </>
                                    
                                    
                                    }
                            </div>:
                           <WaitingPos  total={getTotal()} />
                            
                            
                            
                            }



                   </div>
                   </div>:
                       <div className=" relative flex flex-col gap-5  h-full">
                                <WaitingPos  total={getTotal()-efectivo} />
                        </div>
                    }                   
                </div>   {/* end modal body */}




                {/* <!-- Modal footer --> */}
                {/* <div className="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                    <button data-modal-toggle="defaultModal" type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ">Aceptar</button>
                    <button data-modal-toggle="defaultModal" type="button" 
                    onClick={onRequestClose}
                    className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">Cancelar</button>
                </div> */}
            </div>
        </div>
  </Modal>





  )
}
