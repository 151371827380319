import React, {useState, useEffect, useRef} from 'react'
import { FiFacebook } from "react-icons/fi";
import QRCode from "react-qr-code";
import { nanoid } from 'nanoid'

import { HStack, Button, ButtonGroup } from '@chakra-ui/react'
import numeral from 'numeral';



import { useCajaContext } from "../../providers/CajaProvider";









export default function FormaPago() {



    const qrCodeCanvasRef = useRef(null);
    const [qrCode, setQrCode] = useState(null);

    const { getTotal, setFormaPago, formaPago, establecerFP, formasDePago, cliente } = useCajaContext();




  return (

    <div className="  w-full  h-full  ">
                <div className="p-6 space-y-6">
                        <div className="flex flex-col gap-8   "  >


                        <div className="flex flex-col gap-3">
                           

                           <div>
                                <p className="uppercase font-bold text-xs">  Ruc:</p>
                                { cliente ? <div>
                                  <p className="font-bold text-2xl text-right">{ cliente.razon_social  }</p>
                                  <p className="font-bold text-xl text-right">{ `${cliente.ruc}-${cliente.dv}` }</p>
                                </div>:<p>---</p>
                                
                              }


                            </div>

                            <div>
                                <p className="uppercase font-bold text-xs"> TOTAL:</p>
                                <p className="font-bold text-2xl text-right">{numeral( getTotal()  ).format('0,0')}</p>
                            </div>


                        </div>




                        <HStack spacing='24px'>


                        {
                            formasDePago.map( (fp, index)=><Button 
                            key={fp.id} 
                            onClick={()=>setFormaPago(fp.id)}
                            size='md'
                            height='90px'
                            width='200px'
                            border='2px'
                            sx={{ _hover: { bg: formaPago===fp.id? 'green.300' : 'gray.300' }} }
                            backgroundColor={ formaPago===fp.id? 'green.300' : 'gray.300' }
                           
                            >
                        {fp.nombre}
                    </Button>)
                        }

                       
                        </HStack>

                        {/* { qrCode && <div className="p-5 bg-white flex justify-center">
                         <QRCode value={qrCode} />                        
                        </div>} */}


                        </div>
                </div>
        </div>
  )
}
