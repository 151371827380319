import React,{useEffect, useRef, useState} from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    CircularProgress,
    Stack,
    Box,
    Stat,
    StatLabel,
    StatNumber,
    StatGroup,
    IconButton,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td
  } from '@chakra-ui/react'
  import { NumericFormat } from 'react-number-format';
  import numeral from 'numeral';
  import dayjs from "dayjs";

  import { IoArrowBack } from "react-icons/io5";

  import {
    useParams, useNavigate
  } from "react-router-dom";


  import ProductoCard  from "../components/zona/ProductoCard";



import { gql, useMutation, useQuery, useLazyQuery} from "@apollo/client";

  const VENTA_DETALLE_QUERY = gql`
  query($id:Id!) {
    ventaById( id:$id)
  }
`;  




const calculateDifference = (startDate, endDate) => {
    const dEnd = endDate ? dayjs(endDate) : dayjs()
    const diff = dEnd.diff(dayjs(startDate), 'minute'); // Calcula la diferencia en minutos
    const hours = Math.floor(diff / 60); // Calcula las horas
    const minutes = diff % 60; // Calcula los minutos restantes

    return `${hours} horas, ${minutes} minutos`;
  };

const StatBox = ({bg, etiqueta, valor})=>          <Box
w="100%"
p={3}
borderWidth="1px"
borderRadius="lg"
bg={bg || 'green.200' }
>
<Stat>
  <StatLabel fontSize="sm">{etiqueta}</StatLabel>
  <StatNumber fontSize="2xl">{valor}</StatNumber>
</Stat>
</Box>



const MiniBox = ({bg, etiqueta, valor})=> <Box
w="100%"
p={3}
borderWidth="1px"
borderRadius="lg"
bg={bg || 'green.200' }
>
<Stat>
  <StatLabel fontSize="sm" className="uppercase">{etiqueta}</StatLabel>
  <StatNumber fontSize="xl">{valor}</StatNumber>
</Stat>
</Box>




const DetalleTable = ({productos})=>     <div className="grid grid-cols-1  p-3  bg-yellow-700  " >
{
  productos.map((producto, index) => <div key={producto.id} className="p-3 venta-detalle border">
      <div className="overflow-hidden">
        <p>assssdsd</p>
        <p>assssdsd</p>
        <p>assssdsd</p>
        <p>assssdsd</p>
        <p>assssdsd</p>
        <p>assssdsd</p>
      </div>
    </div>
  )
}
</div>



export default function FacturaDetalle() {


    const navigate = useNavigate();
    let { id:facturaId } = useParams();


    const {data, loading, error} = useQuery(VENTA_DETALLE_QUERY, {variables:{id:parseInt(facturaId)}})
    const ventaById = data?.ventaById


    if(!facturaId)return <></>


    // tickets: 21,
    // total: 845000,
    // totalEfectivo: 506000,
    // totalOtrosMedios: 339000,
    // contadorAgrandado: 15,
    // contadorNoAgrandado: 2

    return (
         !loading ? <div className="flex flex-col gap-5 w-full   p-5  bg-slate-200 relative  "  >


            <div className="p-3 flex justify-between">
                <IconButton
                            aria-label="Cerrar sesión"
                            icon={<IoArrowBack />}
                            size="lg"
                            colorScheme="blue" // Aquí se establece el color rojo
                            onClick={ ()=> navigate(-1)   }
                            />
                            <p>Detalle de venta #{facturaId}</p>
            </div>

             <div  className="flex flex-col gap-3 ">
                <div className="flex gap-3 ">
                    <MiniBox etiqueta="Total" valor={numeral( ventaById.venta.total).format('0,0')} />
                    <MiniBox etiqueta="Forma de pago" valor={ ventaById.venta.pagoDetalles.map(i=>i.formaPago.nombre).join() } />
                </div>

                <div className="flex gap-3">
                    <MiniBox etiqueta="factura" valor={`${ventaById.venta.facturaVenta.timbradoPuntoExpedicion}-${ventaById.venta.facturaVenta.timbradoNumero}`} />
                    <MiniBox etiqueta="Nombre" valor={`${ventaById.venta.facturaVenta.nombre}`} />
                    <MiniBox etiqueta="Ruc" valor={`${ventaById.venta.facturaVenta.ruc}`} />
                </div>
              </div>



               <div className=" flex-1  relative overflow-auto  ">
                    <div className="absolute  w-full ">
                    <Table variant="simple"  >
                        <Thead>
                            <Tr>
                            <Th>Cant</Th>
                            <Th>Producto</Th>
                            <Th>Precio</Th>
                            <Th>Subtotal</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {ventaById.venta.ventaDetalles.map((item) => (
                            <Tr key={item.id}>
                                <Td>    {numeral(item.cantidad ).format('0,0')} </Td>
                                <Td>
                                <ProductoCard vd={item} />
                                </Td>
                                <Td> {numeral( item.producto.precio).format('0,0')}   </Td>
                                <Td> {numeral( item.producto.precio*item.cantidad ).format('0,0')}    </Td>
                                

                            </Tr>
                            ))}
                        </Tbody>
                        </Table>
                    </div>
               </div>
             </div>:
             <div className="w-full h-full flex justify-center items-center py-12">
               <CircularProgress isIndeterminate color='green.300' />
             </div>
             
    )
  }
