import React, {useState, useEffect} from 'react'
import Modal from 'react-modal';
import { NumericFormat } from 'react-number-format';
import { Stack, Image, Button} from "@chakra-ui/react";
import { useSpring, animated } from 'react-spring';



import { FiSearch } from "react-icons/fi";
import { useCajaContext } from "../../providers/CajaProvider";

import { getPreferenciasString } from "../../utils/producto";

import  IngredienteToggleButton from "../comanda/IngredienteToggleButton"
import BotoneraProductVariator from "../BotoneraProductVariator"
import InputCantidad from "../InputCantidad"
import PreferenciasModal from "./PreferenciasModal";

import { v4 as uuidv4 } from 'uuid';
import InputNumericoModal from "../caja/InputNumericoModal";
import AgregaModal from "./AgregaModal";
import numeral from "numeral";


// const customStyles = {
//     content: {
//       padding: '0px',
//       top: '80px',
//       left: '80px',
//       right: '80px',
//       bottom: '80px',

//     },
//   };

const customStyles = {
    content: {
      padding: '0px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };
  


export default function AgregaAgrandaModal( {isOpen,onRequestClose, style, onConfirm, product, productosAgrega}) {


    const { setProductVariator, productVariator, avoidIngredients, setAvoidIngredients, variatorValues, setPedido } = useCajaContext();
    const [cantidad, setCantidad] = useState(1)
    const [modalNumerico, setModalNumerico] = useState({
        open:false
    })

    const [productoPrevio, setProductoPrevio] = useState(null)

    const [agregaModal, setAgregaModal] = useState({
        open:false
      })
    
    const [preferenciasModal, setPreferenciasModal] = useState({open:false, data:null})

    const modalAnimation = useSpring({
        transform: isOpen ? 'translateX(0%)' : 'translateX(100%)',

      });

      

    const confirmarProducto = (payload, agregados)=>{

     
        // const {producto} = product;
        console.log("confirmarProducto, producto", payload, agregados)
  
  
        //   const payload={
        //     avoidIngredients:{
        //         items:[...avoidIngredients]
        //     },
        //     variatorValues:  productVariator?.botoneras?.map( i=>({selected:i.selected, name:i.name}))  ,
        //     observaciones:"",
        //     producto,
        //     cantidad,
        //     id: uuidv4()
        //   }
  
        //   const payload = productoPrevio;

          console.log("payload", payload)
  
          setPedido(
              (p)=>([...p,  payload, ...agregados  ])
          )          
  
          onConfirm();


    }



    const changeCantidadByKeypad = (v)=>{

        console.log("changeCantidadByKeypad", v)
        if(v>0){
            setCantidad(v)
            setModalNumerico({open:false})    
        }
    }

    const openCantidadModal = ()=>{
        setModalNumerico({open:true})
    }



    const handleAvoid = (path)=>{


        if(avoidIngredients.includes(path)){
            setAvoidIngredients(avoidIngredients.filter(avoid=>avoid!==path))
        }else{
            setAvoidIngredients([...avoidIngredients, path])
        }

        console.log("avoidIngredients", path, avoidIngredients)


    }



    const preferencias = product?.producto?.productoDetalle.filter((producto) => {
        // utilizamos el método some() para verificar si algún elemento en
        // receta.recetaDetalle tiene sku.tags incluido en "preferencia"
        return producto.receta.recetaDetalle.some((detalle) => {
            return detalle.sku.tags.includes("PREFERENCIA");
        });
    });


    useEffect(() => {
      setCantidad(1)
      setProductVariator( product?.producto?.variator )
    }, [product,setProductVariator])
    



    const openAgregaModal = (previo)=>{

        const payload={
            avoidIngredients:{
                items:[...avoidIngredients]
            },
            variatorValues:  productVariator?.botoneras?.map( i=>({selected:i.selected, name:i.name}))  ,
            observaciones:"",
            producto:product.producto,
            cantidad,
            id: uuidv4()
          }

          console.log("openAgregaModal", payload, productosAgrega)


          if(previo){
            setProductoPrevio( payload )
            setAgregaModal({  open:true })    
          }else{
            confirmarProducto( payload, [] )
          }


      }

    const closeAgregaModal = (agregados)=>{
        setAgregaModal({
          open:false
        })

        confirmarProducto( productoPrevio, agregados || [] )
    }


      const continueAgrega = (agregados)=>{
        closeAgregaModal(agregados || [] )

        // setStepTicket(2)
      }      



      useEffect(() => {
        console.log("productVariator", productVariator)
      }, [productVariator])
      






    if(!product)return <></>



  return (


    <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    style={style || customStyles}
    contentLabel="Agregar y agrandar"
  >
    {/* <animated.div style={modalAnimation}> */}
    <div>

    {/* <!-- Main modal --> */}
    <div className="relative      h-full pt-8   ">
            {/* <!-- Modal content --> */}
            <div className="relative bg-white shadow dark:bg-gray-700">


                {/* <!-- Modal header --> */}
                {/* <div className="flex justify-between items-start p-2 rounded-t border-b dark:border-gray-600">
                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                       
                    </h3>
                    <button type="button" 
                    onClick={onRequestClose}
                    className="text-gray-400  bg-gray-200
                      rounded-full text-sm p-3 ml-auto inline-flex items-center"
                      >
                        <svg aria-hidden="true" className="w-8 h-8" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                </div> */}



                {/* <!-- Modal body --> */}
        <div className="flex gap-5 px-6  w-[1200px]">


        <PreferenciasModal
                isOpen={preferenciasModal.open}
                onRequestClose={ ()=>setPreferenciasModal({open:false, data:null}) }
                style={customStyles}
                data = {preferenciasModal.data}
                preferencias={preferencias}
                avoidIngredients={avoidIngredients}
                handleAvoid={handleAvoid}
        />     


    <div className="w-1/4"> 

            <Stack direction='column' spacing={2}   align={'center'}  >
                {   
                        product.producto.productoDetalle.map( i=><Image
                            boxSize='160px'
                            objectFit='scale-down'
                            src={ i.receta.imagen.url  }
                            alt={ i.receta.nombre }
                        />)
                    }
            </Stack>

            {/* <div className="grid grid-cols-2 gap-3">
                {   
                    product.producto.productoDetalle.map( i=><div className="h-24 overflow-hidden">
                        <img className="w-full  h-full" src={ i.receta.imagen.url  }  />
                        </div>)
                }
            </div> */}
    </div>

                <div className="flex flex-col gap-3   h-full  w-full">




    <div className="flex gap-2 justify-between  ">

                <div className="w-full">
                    {/* <NumericFormat displayType="text" value= {product.producto.precio} thousandSeparator="," /> */}
                    <p className="text-4xl"> {product.producto.nombre} </p>
                </div>

                <div className="grid gap-3 border">
                    <InputCantidad cantidad={cantidad} setCantidad={setCantidad}  handleClick={openCantidadModal}  />
                </div>

                {/* <div className="flex-1 overflow-scroll p-3 bg-slate-500 relative">
                            <div className="absolute">
                                <pre>
                                    {JSON.stringify(  product.producto.productoDetalle.map( i=> ({...i.receta.variator}))   ,null,2)}
                                </pre>
                            </div> 
                </div> */}

    </div>








    <div className="flex flex-col">
            {/* <p className="text-2xl">Product's Variators</p> */}
            { productVariator && <div className="grid  gap-1">
                {
                    productVariator.botoneras.map( i=> <BotoneraProductVariator  data={i}  producto={product.producto}  /> )                                 
                }
            </div>}
    </div>

{/* <div>
    <pre className="text-xs">
        { JSON.stringify( productVariator?.botoneras, null, 2) }
    </pre>
</div> */}

{/* <div className="flex flex-col">
            <div className="text-2xl">Variators</div>
                            <div className="grid grid-cols-2 gap-3">
                                {
                                    product.producto.productoDetalle.map( i=>
                                        <div className="p-2 bg-slate-400">
                                            <p>nombre:{i.receta.nombre}</p>
                                            <ul className="list-disc bg-slate-400 p-3">
                                                {
                                                    i.receta.variator.items.map( v=><li> {v.name}:{v.options.map(i=>i.name).join()} </li>)
                                                }
                                            </ul>
                                        
                                        </div>
                                    )                                        
                                }
                            </div>
 </div> */}


{/* <div>
    variatorValues: {JSON.stringify(  productVariator?.botoneras?.map( i=>({selected:i.selected, name:i.name}))   ,0,null)}
</div> */}

        <Stack direction={"row"} spacing={3}>

            { preferencias.length>0 && <Button 
                py={8}
                size={"lg"} 
                onClick={()=>setPreferenciasModal({open:true, data:{preferencias, avoidIngredients, handleAvoid}})}
            >Preferencias</Button> }

            <div className="border border-blue-300 w-full p-3">
             <p className="uppercase text-xs">
                { getPreferenciasString(preferencias, {items:avoidIngredients}).join(", ") }
                </p>
            </div>

            {/* <Button 
                py={8}
                size={"lg"} 
                onClick={()=>setPreferenciasModal({open:true, data:{preferencias, avoidIngredients, handleAvoid}})}
            >Agregados</Button> */}


        </Stack>




 {/* { preferencias.length>0 &&  <div className="grid gap-3 p-3  border rounded-lg bg-slate-100">
            <div className="flex flex-col gap-3">
                {
                    preferencias.map((i) => (
                    <div key={i.id}  className="">
                        <div className=" gap-3 grid grid-cols-6">
                        {
                            i.receta.recetaDetalle.filter(f=>f.sku.tags.includes("PREFERENCIA")).map((v) => (
                                <div onClick={ ()=>handleAvoid( `${i.id}-${v.id}`)} >
                            <IngredienteToggleButton  avoid={ avoidIngredients?.includes(`${i.id}-${v.id}`) }   key={v.id} titulo={`${v.sku.nombre}`}>{JSON.stringify(v.sku.tags)}</IngredienteToggleButton>
                            </div>
                            ))
                        }
                        </div>
                    </div>
                    ))
                }
            </div>
    </div>
 } */}




            {/* <div className="grid gap-3 p-3 bg-slate-200">
                            <p className="text-xs uppercase">observaciones</p>
                            <input placeholder="observaciones" className="p-2 border rounded-lg"/>
            </div> */}




                    {/* <div className="flex flex-col gap-3 p-3 bg-slate-300 rounded-lg">
                        <div className="text-2xl">Agrandate</div>
                        <div>
                            <div className="flex flex-col gap-5">
                                <button type="button" 
                                
                                class="text-neutral-800 bg-green-200  font-medium rounded-lg text-base px-10 py-8 text-center ">
                                    <div className="grid gap-3">
                                        <p className="text-4xl uppercase">Agranda</p>
                                        <p> Regular a Grande</p>
                                        <p className="text-xl"> Gs. 3.500</p>
                                    </div>
                                </button>

                                <button type="button"
                                class="text-neutral-800 bg-red-300  font-medium rounded-lg text-base px-6 py-3.5 text-center ">NO AGRANDA</button>
                            </div>
                        </div>
                    </div> */}


                    {/* <div className="flex flex-col gap-3 p-3 bg-slate-300 rounded-lg">
                        <div className="text-2xl">Agregale</div>
                        <div>
                            <div className="grid gap-5 grid-cols-2">
                               
                                <button type="button" 
                                    
                                    class="text-neutral-800 bg-green-200  font-medium rounded-lg text-base px-10 py-2 text-center col-span-2">
                                    <div className="grid gap-1">
                                        <p className="text-4xl uppercase">Agrega</p>
                                        <p>Nuggets x5</p>
                                        <p className="text-xl"> Gs. 6.000</p>
                                    </div>
                                </button>


                                <button type="button" 
                                    
                                    class="text-neutral-800 bg-green-200  font-medium rounded-lg text-base px-10 py-2 text-center col-span-2 ">
                                    <div className="grid gap-1">
                                        <p className="text-4xl uppercase">Agrega</p>
                                        <p>Nuggets x10</p>
                                        <p className="text-xl"> Gs. 12.000</p>
                                    </div>
                                </button>


                                <button type="button" 
                                    
                                    class="text-neutral-800 bg-green-200  font-medium rounded-lg text-base px-10 py-2 text-center ">
                                    <div className="grid gap-1">
                                        <p className="text-4xl uppercase">Agrega</p>
                                        <p>Grande Bacon y Cheddar</p>
                                        <p className="text-xl"> Gs. 8.000</p>
                                    </div>
                                </button>


                                <button type="button" 
                                    
                                    class="text-neutral-800 bg-green-200  font-medium rounded-lg text-base px-10 py-2 text-center ">
                                    <div className="grid gap-1">
                                        <p className="text-4xl uppercase">Agrega</p>
                                        <p>Grande Bacon y Cheddar</p>
                                        <p className="text-xl"> Gs. 6.000</p>
                                    </div>
                                </button>


                            </div>
                        </div>
                    </div> */}



                </div>
       </div>

                {/* <!-- Modal footer --> */}
                <div className="flex justify-between items-center px-6 py-2 space-x-2 rounded-b border-t border-gray-200">

                        <p className="text-3xl font-bold">

                            Gs. { 
                                                        numeral( product.producto.precio * cantidad  ).format('0,0')
                            }
                        </p>

                        <div className="flex justify-end items-center gap-5 ">
                            <button 
                            onClick = { onRequestClose    }
                            data-modal-toggle="defaultModal" type="button"
                            className="text-neutral-800 bg-red-200    font-medium rounded-lg px-6 py-4 text-2xl text-center self-start  ">Cancelar</button>
                            

                            <button 
                            // onClick = {confirmarProducto    }
                            onClick = { ()=>{
                                openAgregaModal(  productosAgrega )

                            }
                        }
                            disabled={ productVariator?.botoneras && productVariator?.botoneras.filter(i=>i.selected<0).length!==0 }
                            data-modal-toggle="defaultModal" type="button"
                            className="text-neutral-800 bg-green-500    font-medium rounded-lg px-12 py-4 text-2xl text-center disabled:bg-slate-400 ">Aceptar</button>
                        </div>
                </div>


            </div>
        </div>

        <InputNumericoModal   isOpen={modalNumerico.open} onClose={()=>setModalNumerico({open:false})} 
              defaultAmount = { cantidad || '' }
              message={`Ingrese cantidad`}  
             onConfirm={changeCantidadByKeypad}  
        />

     </div>


     <AgregaModal
                isOpen={ agregaModal.open }
                onRequestClose={  ()=>closeAgregaModal([])  }
                style={customStyles}
                // handleGenerateVenta={handleGenerateVenta}
                onContinue ={  continueAgrega    } 
                productosAgrega = { productosAgrega }

        />

     {/* </animated.div> */}
  </Modal>





  )
}
