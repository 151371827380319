import React, { createContext, useContext} from 'react';
import { io } from "socket.io-client";



// @ts-ignore
export const WebSocketContext = createContext({});

export const useWebSocketContext = () => useContext(WebSocketContext);

const WebSocketProvider= ({ children }) => {
// https://www.piesocket.com/socketio-tester

//  const url = "https://ws.dev.fastview.cloud"
  const url =     process.env.REACT_APP_WS_URL  //"https://gql.fastview.cloud"

    const socket = io( url, {
      withCredentials:false,
      extraHeaders: {
        "Device-id": localStorage.getItem('deviceId'),
        "Device-type": "webapp"
      }
    });

    console.log("socket on WebSocketProvider", socket)


  return (
    <WebSocketContext.Provider
      value={{
        socket
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};

export default WebSocketProvider;
