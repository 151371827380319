import React from 'react'

export default function MenuCategoria(props) {
    const {titulo, img, handleClick, active} = props;

    


    // `border rounded-md p-1 flex justify-center place-items-center 
    // ${active ? 'from-green-600 to-green-500' : 'from-slate-500 to-indigo-500'}
    // flex-col bg-gradient-to-b `



  return (
    <button  onClick={handleClick}  type="button" 
    // style={{
    //     backgroundImage: `url(${img})`,
    //     backgroundSize: 'contain',
    //     backgroundRepeat: 'no-repeat',
    //     backgroundPosition: 'center',
    // }}
    className={ `border rounded-md p-1 flex justify-center place-items-center 
    ${active ? 'bg-green-400' : 'bg-slate-400'}
    flex-col bg-gradient-to-b ` }  >

        {/* <div className="box-content h-12 w-12 bg-white ">
                <img src={img} alt="" width="100%" height="100%" />
        </div> */}

        <p className="text-xl uppercase bold text-white">{titulo} </p>
    </button>
  )
}
