import React  from 'react';
import Reloj from '../components/Reloj';



const RelojPage = () => {


  return (
    <div className="h-screen w-screen flex flex-col overflow-hidden justify-center place-items-center">
      <Reloj /> 


    </div>
  );
};

export default RelojPage;
