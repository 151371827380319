import React, {useState, useEffect} from 'react'
import Modal from 'react-modal';
import { FiSearch, FiArrowRight, FiArrowLeft } from "react-icons/fi";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { Stack, Button, ButtonGroup } from '@chakra-ui/react'

import {
    useDisclosure,
    CircularProgress,
    Modal as ChakraModal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
  } from '@chakra-ui/react'


import Ruc from "../../components/finishOrder/Ruc"
import NombreLlamador from "../../components/finishOrder/NombreLlamador"
import FormaPago from "../../components/finishOrder/FormaPago"
import Sugerencias from "../../components/finishOrder/Sugerencias"

import NumPad from 'react-numpad';

import PedidoCaja from "../../components/PedidoCaja"
import CajaBigTotal from "../../components/CajaBigTotal"



import { gql, useMutation, useQuery, useLazyQuery} from "@apollo/client";

import { useCajaContext } from "../../providers/CajaProvider";

const RUC_QUERY = gql`
 query($cedula: String!){
    rucByCedula(cedula: $cedula){
        ruc
        dv
        razon_social
    }
 }
`;





export default function FinishOrderModal( {isOpen,onRequestClose, style, onTicket, handleGenerateVenta }) {


    const { isOpen:isOpenPos, onOpen:onOpenPos, onClose:onClosePos } = useDisclosure()


const [activeTab, setActiveTab] = useState(0)
    const [step, setStep] = useState(0)

    const [ruc, setRuc] = useState("")
    const { setCliente, cliente, formaPago, setFormaPago, setNombreLlamador} = useCajaContext();

    const [input, setInput] = useState('');

    const [ searchRucByCedula,  { loading, error, data }, ] = useLazyQuery(RUC_QUERY );
    
    const   rucByCedula    = data?.rucByCedula;




    const nextStep = ()=>{

        if(activeTab===3 && formaPago)onTicket();
        else if(activeTab<4)setActiveTab((p)=>(p+1))
        // else if(activeTab<5)setActiveTab((p)=>(p+1))

    }

    const prevStep = ()=>{

       if(activeTab>0)setActiveTab((p)=>(p-1))

    }


    const handleSubmit = (event)=>{

        try{
            searchRucByCedula({variables:{ cedula: ruc }}).then( res=>{
                console.log(res);
            });
    
        }catch(ex){
            console.log("error on search ruc by cedula", ex)
        }

        event.preventDefault();
      }



      const confirmRuc = ()=>{

        setCliente(rucByCedula)
        onRequestClose();
        
      }


      useEffect(() => {
        console.log("ruc effect", ruc)
        if(ruc){

            try{
                searchRucByCedula({variables:{ cedula: ruc }}).then( res=>{
                    console.log(res);
                });
        
            }catch(ex){
                console.log("error on search ruc by cedula", ex)
            }


        }
      }, [ruc, searchRucByCedula])
      

      const handleFinishPos = ()=>{

        handleGenerateVenta( ()=>{
            onClosePos();
        });

      }





      

  return (

    <>

    <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    style={style}
    contentLabel="Ruc"
  >
    {/* <!-- Main modal --> */}
    <div className="relative   w-full  h-full md:h-auto  ">
            {/* <!-- Modal content --> */}
            <div className="relative bg-white shadow dark:bg-gray-700">
                {/* <!-- Modal header --> */}
                <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                        Finalizar orden
                    </h3>
                    <button type="button" 
                    onClick={onRequestClose}
                    className="text-gray-400  bg-gray-200
                      rounded-full text-sm p-1.5 ml-auto inline-flex items-center"
                      >
                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                </div>



                {/* <!-- Modal body --> */}
                <div className="  flex flex-col gap-5 justify-between "  style={{width:'800px', height:'650px'}}>
                        <div className="flex gap-2 flex-1  ">

                            {/* <button onClick={()=>setFormaPago(2) }>FP</button> */}

                                <Tabs    index={activeTab} onChange={(index) => setActiveTab(index)}  className=" h-full w-full"  >
                                    <TabList className="text-xl uppercase">
                                        <Tab fontSize={'1em'} className="uppercase" >Ruc / Club</Tab>
                                        <Tab fontSize={'1em'} className="uppercase"  >Llamado</Tab>
                                        <Tab fontSize={'1em'} className="uppercase" >Resumen</Tab>
                                        {/* <Tab fontSize={'1em'} >Sugerencias</Tab> */}
                                        <Tab fontSize={'1em'} className="uppercase"  >Forma de Pago</Tab>
                                    </TabList>

                                
                                    <TabPanels className="   " height={'90%'} >

                                        <TabPanel height={'100%'}>
                                            <Ruc  onRequestClose={ ()=>setActiveTab(1) }  />
                                        </TabPanel>

                                        <TabPanel height={'100%'} className=""  >
                                            <NombreLlamador cliente={cliente} 
                                                onChangeText={
                                                    (i)=>setNombreLlamador(i)
                                                }
                                            onRequestClose={ (i)=>{
                                                console.log("set nombre llamador", i)
                                                setNombreLlamador(i)
                                                setActiveTab(2)
                                                } } />
                                        </TabPanel>


                                        <TabPanel height={'100%'} className=""  >
                                              <div className="flex flex-col gap-3  h-full  ">
                                                    <div className="flex-1 pb-1  ">
                                                        <PedidoCaja />
                                                    </div>
                                                    <CajaBigTotal />
                                              </div>
                                        </TabPanel>
                                        
                                        
                                        {/* <TabPanel height={'100%'}>
                                           <Sugerencias />
                                        </TabPanel> */}

                                        <TabPanel height={'100%'}>
                                            <FormaPago 
                                            onRequestClose={()=>onTicket()}  
                                            onOpenPos={onOpenPos}  
                                            finish={handleFinishPos} />
                                        </TabPanel>


                                        <TabPanel>
                                                                          
                                       
                                        </TabPanel>
                                    </TabPanels>
                                   
                                </Tabs> 

                        </div>

                        <Stack direction='row' spacing={4} justify={'end'}>
                            <Button leftIcon={<FiArrowLeft />} colorScheme='teal'  px={16} py={8} variant='outline' onClick={prevStep} >
                                Atras
                            </Button>
                            <Button rightIcon={<FiArrowRight />} colorScheme='teal'   px={16} py={8} variant='solid' onClick={nextStep}>
                            Continuar
                            </Button>
                        </Stack>

                </div>



            </div>
        </div>
  </Modal>


  <ChakraModal isOpen={isOpenPos} onClose={onClosePos} >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>OPERACIÓN BANCARD</ModalHeader>
          <ModalCloseButton />

          <ModalBody >
            <div className="w-full flex justify-center p-5">
                    <CircularProgress  isIndeterminate   size='80px' />
            </div>
          </ModalBody>

          <ModalFooter>
                <Button colorScheme='blue' mr={3} onClick={ handleFinishPos }>
                Continuar
                </Button>
  
          </ModalFooter>
        </ModalContent>
      </ChakraModal>




</>



  )
}
