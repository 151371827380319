export const getPreferenciasString = (preferencias, avoidIngredients)=>{

    const prefs = [];

console.log("getPreferenciasString", preferencias, avoidIngredients)

    preferencias.forEach( i=>i.receta.recetaDetalle.forEach( (v) =>{
        if(avoidIngredients.items?.includes(`${i.recetaId}-${v.sku.sku}`)){
            prefs.push(`SIN ${v.sku.nombre}`)
        }
    }))

    return prefs;

}