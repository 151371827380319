import React, {useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'

import LabelValue from "./LabelValue";


export default function ProyeccionMiniItem({c, proyeccionCalc}) {


    const [desvio, setDesvio] = useState(0)

    const sumarDesvio = () => {
        setDesvio(prevDesvio => prevDesvio + 5);
      }
    
      const restarDesvio = () => {
        setDesvio(prevDesvio => prevDesvio - 5);
      }



  return (<div className="text-3xl font-bold text-black bg-green-400">
        { c.ART_UNIDCAJA>1?(  Math.round(   (c.APR_CANTIDAD*(proyeccionCalc.porcentaje))*(1+(desvio/100))   )   /c.ART_UNIDCAJA).toFixed(1):'---'  }
        </div>


  )
}
