import React from 'react'

import {
    Box,
    Stat,
    StatLabel,
    StatNumber,
    StatGroup
  } from '@chakra-ui/react'
  import { NumericFormat } from 'react-number-format';


  import { FaExclamationTriangle } from "react-icons/fa";


export default function Resumen({data, general, montoCierre,balance, minimal}) {


  // const balance = general.totalVentasEfectivo+data.caja.monto-general.totalAlivios-montoCierre;

  let bgBalance = 'green.200';

  if( balance !== 0 )bgBalance='yellow.300';


  if( balance>10000 || balance < -10000 )bgBalance='red.300';

  const fullDetails = minimal ? false : true;



  if(!fullDetails)return  <Box gap={3} padding={3} display={'flex'} flexDir={'column'} justifyContent={'space-between'}>
  <p className="text-2xl text-center">Resumen de caja #{data.caja.id}</p>




  <StatGroup>
    <Stat>
      <StatLabel>Monto cierre</StatLabel>
      <StatNumber><NumericFormat displayType="text" value= {montoCierre} thousandSeparator="," /> </StatNumber>
    </Stat>

    <Stat>
      <StatLabel>Fondo Apertura</StatLabel>
      <StatNumber> <NumericFormat displayType="text" value= {data.caja.monto} thousandSeparator="," />  </StatNumber>
    </Stat>

    <Stat>
      <StatLabel>Alivios</StatLabel>
      <StatNumber> <NumericFormat displayType="text" value= {general?.totalAlivios} thousandSeparator="," />  </StatNumber>
    </Stat>


  </StatGroup>


</Box>


  return (
    <Box gap={3} padding={3} display={'flex'} flexDir={'column'} justifyContent={'space-between'}>
    <p className="text-2xl text-center">Resumen de caja #{data.caja.id}</p>

    <StatGroup>
      <Stat>
        <StatLabel>Tickets</StatLabel>
        <StatNumber> <NumericFormat displayType="text" value= {data.tickets} thousandSeparator="," /> </StatNumber>
      </Stat>

      <Stat>
        <StatLabel>Agrandados</StatLabel>
        <StatNumber> <NumericFormat displayType="text" value= {data.contadorAgrandado}  thousandSeparator="," /> </StatNumber>
      </Stat>

      <Stat>
        <StatLabel>Fondo Apertura</StatLabel>
        <StatNumber> <NumericFormat displayType="text" value= {data.caja.monto} thousandSeparator="," />  </StatNumber>
      </Stat>
    </StatGroup>

    <StatGroup>
      <Stat>
        <StatLabel>Venta</StatLabel>
        <StatNumber><NumericFormat displayType="text" value= {general?.totalVentas} thousandSeparator="," /> </StatNumber>
      </Stat>

      <Stat>
        <StatLabel>Efectivo</StatLabel>
        <StatNumber> <NumericFormat displayType="text" value= {general?.totalVentasEfectivo} thousandSeparator="," />  </StatNumber>
      </Stat>

      <Stat>
        <StatLabel>Otros Medios</StatLabel>
        <StatNumber> <NumericFormat displayType="text" value= {general?.totalVentas-general?.totalVentasEfectivo} thousandSeparator="," />  </StatNumber>
      </Stat>
    </StatGroup>


    <StatGroup>
      <Stat>
        <StatLabel>Monto cierre</StatLabel>
        <StatNumber><NumericFormat displayType="text" value= {montoCierre} thousandSeparator="," /> </StatNumber>
      </Stat>

      <Stat>
        <StatLabel>Alivios</StatLabel>
        <StatNumber> <NumericFormat displayType="text" value= {general?.totalAlivios} thousandSeparator="," />  </StatNumber>
      </Stat>

      <Stat bg={bgBalance}>
        <StatLabel>Balance</StatLabel>
        <StatNumber> 
          <div className="flex gap-2 items-center">
          <NumericFormat displayType="text" value= {balance*-1} thousandSeparator="," /> { balance!==0 && <FaExclamationTriangle />   }
          </div>
            </StatNumber>
      </Stat>
    </StatGroup>

    {/* <StatGroup>
      <Stat>
        <StatLabel>Efectivo a rendir</StatLabel>
        <StatNumber><NumericFormat displayType="text" value= {data.totalEfectivo+data.caja.monto} thousandSeparator="," /> </StatNumber>
      </Stat>
    </StatGroup> */}

  </Box>
  )
}
