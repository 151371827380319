import React from 'react'

export default function LabelValue ({label,value, horizontal}) {
  return (

    
   <div className={  `flex justify-between ${horizontal?'':'flex-col'}` }  >
    <p className="text-xs uppercase">{label}</p>
    <p className="text-2xl">{value}</p>
  </div>

  )
}

