import React, {useState, useEffect} from 'react'
import Modal from 'react-modal';
import { FiSearch } from "react-icons/fi";

//import Keyboard from 'react-simple-keyboard';
import NumPad from 'react-numpad';

import numeral from 'numeral';




import { useCajaContext } from "../../providers/CajaProvider";




export default function MensajeTicketModal( {isOpen,onRequestClose, style, data }) {




  return (


    <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    style={style}
    contentLabel="Gracias"
  >
    {/* <!-- Main modal --> */}
    <div className="relative   w-full  h-full md:h-auto  ">
            {/* <!-- Modal content --> */}
            <div className="relative bg-white shadow dark:bg-gray-700">
                {/* <!-- Modal header --> */}
                <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                    <p className="text-4xl font-semibold text-gray-900 dark:text-white">
                        Gracias
                    </p>
                    <button type="button" 
                    onClick={onRequestClose}
                    className="text-gray-400  bg-gray-200
                      rounded-full text-sm p-1.5 ml-auto inline-flex items-center"
                      >
                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                </div>



                {/* <!-- Modal body --> */}
               { data &&  <div className="flex flex-col gap-3 px-16 py-5">

             
                     <div className="flex  flex-col">
                        <p className="text-xl uppercase">pedido #</p>
                        <p className="text-6xl font-bold">{data.id}</p>
                    </div>

                    { 
                    data.vuelto >= 0 &&  <div className="flex  flex-col" >
                        <p className="text-xl uppercase">vuelto</p>
                        <p className="text-6xl font-bold" style={{fontSize:'5rem'}}>{numeral(data.vuelto).format('0,0')}</p>
                    </div>
                    }

                    <div className="flex  flex-col  text-right">
                        <p className="text-xs">{numeral(data.tiempo).format('0,0')}</p>
                    </div>



                </div>}

                {/* <!-- Modal footer --> */}
                {/* <div className="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                    <button data-modal-toggle="defaultModal" type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ">Aceptar</button>
                    <button data-modal-toggle="defaultModal" type="button" 
                    onClick={onRequestClose}
                    className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">Cancelar</button>
                </div> */}
            </div>
        </div>
  </Modal>





  )
}
