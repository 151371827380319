import React, {useState, useEffect} from 'react'
import { FiFacebook } from "react-icons/fi";

import { HStack, Box,  Button, ButtonGroup } from '@chakra-ui/react'



import { useCajaContext } from "../../providers/CajaProvider";
import SugerenciaCard from "./SugerenciaCard";





const data = [

    {id:1, nombre:"Nuggets x10", precio:16000, descripcion:"Sobre de 10 nuggets con salsa a elección", img:"/images/nuggets.jpg"},
    {id:2, nombre:"2x1 de cono", precio:12000, descripcion:"2 conos, sabores a elección", img:"/images/conos.jpg"},
    {id:3, nombre:"Doble queso", precio:15000, descripcion:"Nuestro tradicional hamburguesa", img:"/images/doble.jpg"},


]


export default function FormaPago( {isOpen,onRequestClose, style }) {

 


  return (

    <div className="  w-full  h-full  ">



                        <HStack spacing='20px' >
                           
                         {
                            data.map( (i)=>(
                                <Box key={i.id} width="300px"  margin="10px">
                                <SugerenciaCard  data={i} />
                              </Box>
                            
                            ))
                         }

                        </HStack>


    

        </div>
  )
}
