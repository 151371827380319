import React, {useState, useEffect} from 'react'
import Modal from 'react-modal';
import { FiSearch } from "react-icons/fi";


import NumPad from 'react-numpad';




import { gql, useMutation, useQuery, useLazyQuery} from "@apollo/client";

import { useCajaContext } from "../../providers/CajaProvider";

const RUC_QUERY = gql`
 query($cedula: String!){
    rucByCedula(cedula: $cedula){
        ruc
        dv
        razon_social
    }
 }
`;





export default function RucModal( {isOpen,onRequestClose, style }) {


    const [ruc, setRuc] = useState("")
    const { setCliente } = useCajaContext();

    const [input, setInput] = useState('');

    const [ searchRucByCedula,  { loading, error, data }, ] = useLazyQuery(RUC_QUERY );
    
    const   rucByCedula    = data?.rucByCedula;


    const handleSubmit = (event)=>{

        try{
            searchRucByCedula({variables:{ cedula: ruc }}).then( res=>{
                console.log(res);
            });
    
        }catch(ex){
            console.log("error on search ruc by cedula", ex)
        }

        event.preventDefault();
      }



      const confirmRuc = ()=>{

        setCliente(rucByCedula)
        onRequestClose();
        
      }


      useEffect(() => {
        console.log("ruc effect", ruc)
        if(ruc){

            try{
                searchRucByCedula({variables:{ cedula: ruc }}).then( res=>{
                    console.log(res);
                });
        
            }catch(ex){
                console.log("error on search ruc by cedula", ex)
            }


        }
      }, [ruc, searchRucByCedula])
      


  return (


    <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    style={style}
    contentLabel="Ruc"
  >
    {/* <!-- Main modal --> */}
    <div className="relative   w-full  h-full md:h-auto  ">
            {/* <!-- Modal content --> */}
            <div className="relative bg-white shadow dark:bg-gray-700">
                {/* <!-- Modal header --> */}
                <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                        Seleccionar RUC
                    </h3>
                    <button type="button" 
                    onClick={onRequestClose}
                    className="text-gray-400  bg-gray-200
                      rounded-full text-sm p-1.5 ml-auto inline-flex items-center"
                      >
                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                </div>



                {/* <!-- Modal body --> */}
                <div className="p-6 space-y-6">
                    <form onSubmit={handleSubmit}>
                        <div className="flex gap-2">
                            <div className="">


<div 
   className="block p-4 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
>

<NumPad.Number
    // onChange={(value) => { console.log('value', value)}}
    // value={100}
    value = {ruc}
    onChange = {(value)=>
        {
            setRuc(value);

        }
    }   
    label={''}
    placeholder={'Cédula o RUC'}
    decimal={0}
/>


</div>

                                {/* <input 
                                placeholder="Cédula o RUC"
                                type="number"
                                value = {ruc}
                                onChange = {(e)=>setRuc(e.target.value)}
                                className="block p-4 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" /> */}
                          
                            </div>

                            {/* <div className=" flex justify-center flex-col">
                                <button type="submit" className="text-white bg-blue-700 font-medium rounded-full text-sm w-full sm:w-auto p-3 text-center ">
                                    <FiSearch size={24} />
                                </button>
                            </div> */}
                        </div>



                    </form>
                </div>

                {
                  rucByCedula &&   
                    <div className="p-5">
                        <button
                        type="button" 
                        onClick={ confirmRuc }  
                        className="p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
                            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{rucByCedula.razon_social}</h5>
                            <p className="mb-3 text-4xl font-normal text-gray-700 dark:text-gray-400">{`${rucByCedula.ruc}-${rucByCedula.dv}`}</p>
                       
                        {/* <button type="button" className="inline-flex items-center py-5 px-6 text-xl font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            Seleccionar
                            <svg aria-hidden="true" className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                        </button> */}
                        </button>
                    </div>

                }


                {/* <!-- Modal footer --> */}
                {/* <div className="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                    <button data-modal-toggle="defaultModal" type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ">Aceptar</button>
                    <button data-modal-toggle="defaultModal" type="button" 
                    onClick={onRequestClose}
                    className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">Cancelar</button>
                </div> */}
            </div>
        </div>
  </Modal>





  )
}
